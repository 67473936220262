import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { capitalize } from 'lodash'
import './dropdown.scss'

const DropDown = ({ options, selected, setSelected, capitalized, className, fullWidth }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`${fullWidth ? ' w-full' : 'w-[140px]'} dropdownwrapper ${className}`}>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <div
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className={`w-full flex items-center h-[42px] md:h-[48px] border border-[#ffffff33] rounded-[10px] px-4 py-1 cursor-pointer`}
        >
          <div className={`w-full h-10 relative rounded-[10px] text-white flex items-center justify-between`}>
            <p className='text-white font-medium'>{capitalized ? capitalize(selected?.toLowerCase()) : selected}</p>
            <img
              className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
              alt=''
              src='/images/swap/dropdown-arrow.png'
            />
            {isOpen && (
              <div
                className={`${fullWidth ? ' w-full' : 'w-[160px]'} z-[500] dropdown-blur border border-[#ffffff33] -left-[16px] rounded-[10px] top-10 absolute`}
              >
                {options.map((item, idx) => {
                  return (
                    <div
                      onClick={() => {
                        setSelected(item?.type ?? item)
                        setIsOpen(false)
                      }}
                      key={idx}
                      className='z-[600] px-4 py-2 hover:bg-opacity-30'
                    >
                      <p className='text-white text-base md:text-lg leading-7 tracking-wide'>
                        {capitalized ? capitalize(item.display ?? item.type?.toLowerCase() ?? item.toLowerCase()) : item.display ?? item.type ?? item}
                      </p>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default DropDown
