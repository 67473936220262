import { ChainId } from './misc'

export const V2Exchanges = {
  Lynex: 'Lynex',
}

export const V3_CORE_FACTORY_ADDRESSES = {
  [ChainId.LINEA_MAINNET]: '0x622b2c98123D303ae067DB4925CD6282B3A08D0F',
  [ChainId.LINEA_TESTNET]: '0x6AD6A4f233F1E33613e996CCc17409B93fF8bf5f',
}

export const POOL_DEPLOYER_ADDRESS = {
  [ChainId.LINEA_MAINNET]: '0x9A89490F1056A7BC607EC53F93b921fE666A2C48',
  [ChainId.LINEA_TESTNET]: '0xD637cbc214Bc3dD354aBb309f4fE717ffdD0B28C',
  [ChainId.BSC_TESTNET]: '0xa584Fe0B5fBF5394D453f106e00112432fb1ee15',
}

export const QUOTER_ADDRESSES = {
  [ChainId.LINEA_MAINNET]: '0x851d97Fd7823E44193d227682e32234ef8CaC83e',
  [ChainId.LINEA_TESTNET]: '0xB4F9b6b019E75CBe51af4425b2Fc12797e2Ee2a1',
}

export const FUSION_ROUTER_ADDRESSES = {
  [ChainId.LINEA_MAINNET]: '0x3921e8cb45B17fC029A0a6dE958330ca4e583390',
  [ChainId.LINEA_TESTNET]: '0x50FCbF85d23aF7C91f94842FeCd83d16665d27bA',
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = {
  [ChainId.LINEA_MAINNET]: '0x5D3D9E20ad27dd61182505230D1bD075bd249E4B',
  [ChainId.LINEA_TESTNET]: '0x28DeD2af752655Df5Ee92450DC259F92a5ABe449',
}

export const GAMMA_UNIPROXY_ADDRESSES = {
  [ChainId.LINEA_MAINNET]: '0xFc13Ebe7FEB9595D70195E9168aA7F3acE153621',
  [ChainId.LINEA_TESTNET]: '0x1E86A593E55215957C4755f1BE19a229AF3286f6',
}

export const STEER_PERIPHERY = {
  [ChainId.LINEA_MAINNET]: '0x0c5c5beb833fd382b04e039f151942dc3d9a60ce',
  [ChainId.LINEA_TESTNET]: '0x1E86A593E55215957C4755f1BE19a229AF3286f6',
}

export const ICHI_VAULTGUARD = {
  [ChainId.LINEA_MAINNET]: '0x57C9d919AEA56171506cfb62B60ce76be0A079DF',
  [ChainId.LINEA_TESTNET]: '0x6337625bF843911Da1e1eeFaEaFb5599C876469c',
}

export const ICHI_VAULT_DEPLOYER = {
  [ChainId.LINEA_MAINNET]: '0x75178e0a2829B73E3AE4C21eE64F4B684085392a',
  [ChainId.LINEA_TESTNET]: '0x3e9011BA607C6b65eA6451aBA078fEAb506B6A0B',
}

export const CLIP_HYPERLPOOL_USDC_WETH = {
  [ChainId.LINEA_MAINNET]: '0x2549D6b5658a75D7EAE869AF8cd8ae66507f0905',
  [ChainId.LINEA_TESTNET]: '',
}

export const CLIP_HYPERLPOOL_USDC_USDT = {
  [ChainId.LINEA_MAINNET]: '0x4232F47Bf47Ed5b4ad84aBB835E5Db267929ffEd',
  [ChainId.LINEA_TESTNET]: '',
}

export const GAMMA_MASTERCHEF_ADDRESSES = [
  {
    [ChainId.LINEA_MAINNET]: '',
  },
  {
    [ChainId.LINEA_MAINNET]: '',
  },
]

export const MULTICALL_ADDRESS = {
  [ChainId.LINEA_MAINNET]: '0xd7a2C76412d2eD2A2253f45d2c5c20Cb2b916De9',
  [ChainId.LINEA_TESTNET]: '0x38A5C36FA8c8c9E4649b51FCD61810B14e7ce047',
}
