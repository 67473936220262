import React, { useState } from 'react'
import Toggle from '../../components/common/Toggle'
import SwapCrossChain from '../../components/pages/swap/SwapCrossChain'
import CedeStoreWidget from '../../components/pages/bridge/CedeStoreWidget'
import BridgeCard from '../../components/pages/bridge/BridgeCard'
import { useWeb3Wagmi } from '../../hooks/useWeb3'

const bridgePartners = [
  {
    name: 'Linea Bridge',
    link: 'https://bridge.linea.build/',
    logo: 'images/bridges/linea-bridge.png',
    description: 'Linea Bridge is a bridge solution, providing secure and efficient cross-chain transactions between Layer 1 and Linea networks',
  },
  {
    name: 'Owlto',
    link: 'https://owlto.finance/bridge',
    logo: 'images/bridges/owlto-bridge.jpeg',
    description:
      'Owlto is a universal Cross-Rollup bridge product with several advantages, with the most prominent being that it does not involve minting intermediary assets.',
  },
  {
    name: 'Symbiosis',
    link: 'https://app.symbiosis.finance/swap?chainOut=Linea&tokenOut=ETH',
    logo: 'images/bridges/symbiosis-bridge.svg',
    description: 'Symbiosis is a cross-chain AMM DEX that pools together liquidity from different networks: L1s and L2s, EVM and non-EVM.',
  },
  {
    name: 'Orbiter',
    link: 'https://www.orbiter.finance/?source=Ethereum&dest=Linea&token=ETH',
    logo: 'images/bridges/orbiter-bridge.png',
    description:
      'Orbiter Finance enables cross-rollup transactions of Ethereum-native assets in a trustless and seamless manner. It supports various networks, including Ethereum',
  },
  {
    name: 'Rhino.fi',
    link: 'https://app.rhino.fi/bridge?token=ETH&chainOut=LINEA&chain=BASE',
    logo: 'images/bridges/rhino-bridge.svg',
    description:
      'rhino.fi is a frictionless multi-chain DeFi aggregator that gives you access to all the best decentralised finance (DeFi) opportunities in one place securely and safely.',
  },
  {
    name: 'XRGB',
    link: 'https://www.xrgb.xyz/',
    logo: 'images/bridges/xrgb-bridge.jpg',
    description:
      'With XRGB you can conveniently transfer X404 tokens between the Ethereum, BNB, Base, and Linea networks. You can choose to bridge the complete X404 NFT or fractional X404 tokens.',
  },
  {
    name: 'Free.tech',
    link: 'https://free.tech/',
    logo: 'images/bridges/freetech-bridge.png',
    description:
      'Low-cost, zero-slippage universal cross-chain swaps across all leading blockchains and layer-2 rollups. Including assets such as M-BTC and SolvBTC',
  },
  {
    name: 'Galaxy',
    link: 'https://galaxy.exchange/swap?affiliate=lynex',
    logo: 'images/bridges/galaxy-bridge.png',
    description: 'Galaxy provides instant access to cross-chain liquidity from over 100 blockchains, bridges, and liquidity aggregators.',
  },
  {
    name: 'Retro Bridge',
    link: 'https://app.retrobridge.io/?tokenFrom=ETH&source=Ethereum&destination=Linea&tokenTo=ETH',
    logo: 'images/bridges/retro-bridge.png',
    description:
      'RetroBridge is a robust multichain ecosystem and gateway to seamless connectivity across various EVM and non-EVM chains, providing solution for all interactions within a single app.',
  },
]

const Index = () => {
  const [isCrossChain, setIsCrossChain] = useState(false)
  const { account } = useWeb3Wagmi()
  const handleToggleChange = () => {
    setIsCrossChain(!isCrossChain)
  }

  return (
    <div className='max-w-[1200px] mx-auto px-5 sm:px-16 md:px-28 lg:px-5 xl:px-0 pt-20 pt-[160px]'>
      <div className='mb-10 px-4'>
        <h1 className='text-[34px] md:text-[42px] font-semibold text-white'>Trusted Bridge Partners</h1>
        <p className='text-[#b8b6cb] text-base md:text-lg mt-1'>Explore and utilize bridges trusted by Lynex. </p>
      </div>
      {/* Adjust layout based on screen size */}
      <div className='flex flex-col lg:flex-col xl:flex-row-reverse'>
        <div className='w-full lg:w-full xl:w-1/3 px-4 mb-4 md:mb-0 flex flex-col items-center'>
          <div className='w-full flex justify-center mb-4'>
            <div
              className='flex items-center justify-between p-4 bg-[rgba(255,255,255,0.05)] backdrop-filter backdrop-blur-lg rounded-[20px] border border-[rgba(255,255,255,0.20)] p-4'
              style={{ maxWidth: '385px', width: '100%' }}
            >
              <div className='text-white'>CEX On-Ramp</div>
              <Toggle onChange={handleToggleChange} toggleId='bridgeToggle' small rounded checked={isCrossChain} />
              <div className='text-white'>Crosschain</div>
            </div>
          </div>
          <div className='w-full flex justify-center ' style={{ minHeight: '600px', maxWidth: '385px' }}>
            {isCrossChain ? <SwapCrossChain /> : <CedeStoreWidget userAddress={account} />}
          </div>
        </div>
        <div className='w-full lg:w-full xl:w-2/3 px-4'>
          {/* Ensure cards display in rows of two between 768px and 1024px */}
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6'>
            {bridgePartners.map((partner, index) => (
              <BridgeCard key={index} {...partner} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
