import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

export const PoolBadge = ({ name, logo, link, index, address, onBadgeClick }) => {
  const [isMobile, setIsMobile] = useState(false)

  const safeName = name ? name.replace(/\s+/g, '-').toLowerCase() : 'undefined-badge'
  const tooltipId = `tooltip-${safeName}-${address}-${index}`

  useEffect(() => {
    const match = window.matchMedia('(max-width: 768px)')
    setIsMobile(match.matches)
    const handler = (e) => setIsMobile(e.matches)
    match.addListener(handler)
    return () => match.removeListener(handler)
  }, [])

  const image = logo ? (
    <img
      src={logo}
      alt={name || 'Unknown Badge'}
      style={{ width: '1.5em', height: '1.26em' }}
      data-tip
      data-for={tooltipId}
      onClick={() => isMobile && onBadgeClick(name, link)}
    />
  ) : null

  return (
    <div className='flex items-center justify-center -ml-1 py-0.25 px-0.5'>
      {image &&
        (isMobile ? (
          <div>{image}</div>
        ) : (
          <a href={link || '#'} target='_blank' rel='noopener noreferrer'>
            {image}
          </a>
        ))}
      {name && (
        <ReactTooltip
          id={tooltipId}
          className='max-w-[220px] !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33]'
          place='right'
          effect='solid'
          delayShow={200}
        >
          <div>
            <span>
              Liquidity Providers in this pool also earn
              <br />
              <b>{name}</b>
            </span>
          </div>
        </ReactTooltip>
      )}
    </div>
  )
}
