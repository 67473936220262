import React, { useState, useEffect, useMemo } from 'react'
import { useSplit } from '../../../hooks/useLock'
import { formatAmount, isInvalidAmount } from '../../../utils/formatNumber'
import Warning from '../../common/Warning'
import { TransactButton } from '../../common/Buttons/transactButton'

const arrayPercent = [2, 3, 4]
const validNumber = (val) => {
  return val === '' ? 0 : Number(val)
}

const SplitTab = ({ selected }) => {
  const [numberOfInputs, setNumberOfInputs] = useState(2)
  const [customInput, setCustomInput] = useState('')
  const [percentArr, setPercentArr] = useState([])

  const { onSplit, pending } = useSplit()

  useEffect(() => {
    const fixedArr = []
    const target = customInput !== '' ? customInput : numberOfInputs
    for (let i = 0; i < target; i++) {
      fixedArr.push('')
    }
    setPercentArr(fixedArr)
  }, [numberOfInputs, customInput])

  const total = useMemo(() => {
    return percentArr.reduce((sum, cur) => {
      return sum + validNumber(cur)
    }, 0)
  }, [percentArr])

  const errorMsg = useMemo(() => {
    let isError = false
    for (let i = 0; i < percentArr.length; i++) {
      if (validNumber(percentArr[i]) === 0) {
        isError = true
        break
      }
    }
    if (isError) {
      return 'ALL THE PERCENTAGES SHOULD BE FILLED'
    }
    if (total !== 100) {
      return 'TOTAL PERCENT SHOULD BE 100%'
    }
    return null
  }, [percentArr, total])

  return (
    <>
      <div className='mt-5 flex items-center justify-between'>
        <span className='text-sm lg:text-xl text-white font-light'>Token #{selected.id} veLYNX to:</span>
        <p className='text-[#B8B6CB] text-sm lg:text-xl'>veLYNX Balance: {formatAmount(selected.voting_amount)}</p>
      </div>
      <div className='md:mt-2.5 -mt-1 flex items-center flex-wrap w-full text-white text-sm md:text-lg'>
        <div className='flex gap-2 md:gap-3'>
          {arrayPercent.map((item, index) => {
            return (
              <div
                className={`h-10 px-4 flex-grow sm:flex-grow-0 mt-3 md:mt-0 flex-shrink-0 
                ${
                  item === numberOfInputs && customInput === '' ? 'bg-themeOrange font-medium' : 'bg-neutral-500/90 font-light'
                } rounded-md flex items-center justify-center cursor-pointer`}
                key={`level-${index}`}
                onClick={() => {
                  setCustomInput('')
                  setNumberOfInputs(item)
                }}
              >
                {item} Tokens
              </div>
            )
          })}
        </div>
        <div className='max-w-[150px] sm:ml-3 mt-3 md:mt-0 w-full relative'>
          <input
            className='placeholder-dimGray flex-shrink-0 font-normal bg-white/5 w-full h-10 rounded-md text-white pl-2.5 pr-2 text-sm md:text-base focus:!border-themeOrange focus:!border-[2px] block focus-visible:!outline-none'
            type='number'
            min={5}
            max={10}
            value={customInput}
            onChange={(e) => {
              if (!isInvalidAmount(e.target.value)) {
                const nums = Number(e.target.value)
                setCustomInput(Math.max(Math.min(10, nums), 5))
              } else {
                setCustomInput('')
              }
            }}
            placeholder='Enter Amount'
          />
          {customInput !== '' && <span className='absolute z-10 text-white text-base md:text-lg top-2.5 font-light right-[14px]'>Tokens</span>}
        </div>

        <div className='flex justify-between  w-full mt-5'>
          <div className='w-[25%] font-medium text-[13px] md:text-[17px] text-white f-f-fg pl-[15px]'>No</div>
          <div className='w-[50%] font-medium text-[13px] md:text-[17px] text-white f-f-fg'>veLYNX Amount</div>
          <div className='w-[25%] font-medium text-[13px] md:text-[17px] text-white f-f-fg'>Percentage</div>
        </div>
        <div className='w-full max-h-[180px] overflow-auto'>
          {percentArr.map((item, idx) => {
            return (
              <div key={idx} className={`bg-white/5 p-px w-full space-y-2.5 lg:space-y-0 rounded-md ${idx === 0 ? 'mt-[7px]' : 'mt-4'}`}>
                <div className='flex flex-row justify-between items-center rounded-md from-left to-right'>
                  <div className='w-[25%] py-3 pl-4 text-[#fff]'>
                    <div className='text-base lg:text-xl font-medium'>{idx + 1}</div>
                  </div>
                  <div className='w-[50%] text-[#fff]'>
                    <div className='text-base lg:text-xl font-medium'>{formatAmount(selected.voting_amount.times(validNumber(item)).div(100))}</div>
                  </div>
                  <div className='pl-px w-[25%]'>
                    <div className='bg-white/10 w-full relative text-[#fff] rounded-tr-md rounded-br-md'>
                      <input
                        type={'number'}
                        value={item}
                        onChange={(e) => {
                          const val = validNumber(e.target.value)
                          let temp = [...percentArr]
                          if (val > 0) {
                            const newVal = total - validNumber(percentArr[idx]) + val > 100 ? 100 - total + validNumber(percentArr[idx]) : val
                            temp[idx] = newVal > 0 ? newVal : ''
                            setPercentArr(temp)
                          } else {
                            temp[idx] = ''
                            setPercentArr(temp)
                          }
                        }}
                        className='py-3 px-3.5 w-[90%] lg:pl-[15px] text-white font-medium text-base lg:text-xl bg-transparent'
                      />
                      <span className='text-white font-medium text-base lg:text-xl absolute right-[10px] md:right-[14px] mt-3 z-10'>%</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className='mt-5 flex items-center w-full justify-between'>
          <span className='text-sm lg:text-base text-white font-light'>
            Total Split Amount: <span className='text-base lg:text-lg text-white font-medium'>{total}%</span>
          </span>
          <button
            className='text-sm md:text-base font-medium text-themeOrange'
            onClick={() => {
              const fixedArr = []
              for (let i = 0; i < percentArr.length; i++) {
                fixedArr.push('')
              }
              setPercentArr(fixedArr)
            }}
          >
            Reset
          </button>
        </div>
        <TransactButton
          disabled={errorMsg || pending}
          pending={pending}
          onClickHandler={() => {
            onSplit(selected, percentArr)
          }}
          content={errorMsg || 'SPLIT'}
          className='w-full text-base md:text-lg py-3 px-4 mt-4'
        />
        <Warning text='Merging/splitting will cause a loss of unclaimed and pending rewards, make sure to claim everything behorehand.' />
      </div>
    </>
  )
}

export default SplitTab
