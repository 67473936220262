import React from 'react'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import useWalletModal from '../../../../hooks/useWalletModal'
import LoadingBar from '../loadingBar/loadingBar'
import StyledButton from '../../../common/Buttons/styledButton'
import CommitInput from '../commit/commit'
import { idos } from '../../../../config/constants/ido'
import useIdoPublicData from '../../../../hooks/useIdoPublicData'
import useIdoWalletData from '../../../../hooks/useIdoWalletData'
import { formatAmount, fromWei } from '../../../../utils/formatNumber'
import { useIdoClaim } from '../../../../hooks/useIdoCommit'
import BigNumber from 'bignumber.js'
import './data.scss'

const statusMapper = {
  coming_soon: 'Coming soon',
  idle: 'Idle',
  live: 'Live now!',
  finished: 'Finished',
}

const Data = () => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const ido = idos[0]
  const idoPublicData = useIdoPublicData(ido)
  const idoWalletData = useIdoWalletData(ido)
  const { handleClaim } = useIdoClaim()

  const status = idoPublicData.status ? statusMapper[idoPublicData.status] : 'Coming soon'
  const startTime = new Date(ido.startTime * 1000).toLocaleString()
  const endTime = new Date(ido.releaseTime * 1000).toLocaleString()
  const toSell = fromWei(ido.tokensToSell, ido.token.decimals)
  const toSellPercentage = `${formatAmount(toSell * 0.25)}`
  const commitments = fromWei(idoPublicData?.commitmentsTotal, ido.currency.decimals)
  const totalCommitments = formatAmount(commitments)
  const tokenCommited = fromWei(idoWalletData?.amountTokenCommited, ido.currency.decimals)
  const amountTokenCommited = formatAmount(tokenCommited)
  const amountTokensClaimable = idoWalletData?.hasClaimed
    ? fromWei(idoWalletData?.claimed, ido.token.decimals)
    : fromWei(idoWalletData?.tokensClaimable, ido.token.decimals)
  const toClaim = formatAmount(amountTokensClaimable * 0.25)
  const ended = idoPublicData.auctionEnded || false
  const finalized = idoPublicData.finalized || false
  const totalRaise = fromWei(new BigNumber(ido.tokensToSell).times(ido.price), ido.token.decimals)

  const alloc = fromWei(idoWalletData?.points, ido.currency.decimals)
  const allocation = `${formatAmount(alloc)} ${ido.currency.symbol.toUpperCase()}`
  const remaining = idoPublicData.usePointList ? alloc.minus(tokenCommited) : totalRaise.minus(commitments)
  const remainingAlloc = `${formatAmount(remaining)} ${ido.currency.symbol.toUpperCase()}`

  return (
    <div className='box-ido'>
      <div className='header-ido'>
        <div className='logo'>
          <img alt='banner' src='/images/ido/lynex-logo.png'></img>
        </div>
        <div className='title'>
          <p className='heading'>{ido.currency.symbol.toUpperCase()} Offering</p>
          <p className='tag'>{status}</p>
        </div>
      </div>
      <div className='countdowns'>
        <div className='data-wrapper'>
          <p className='data'>STARTING TIME</p>
          <p className='data text-slate-50'>{startTime}</p>
        </div>
        <div className='data-wrapper'>
          <p className='data text-slate-300 text-right'>END TIME</p>
          <p className='data text-slate-50 text-right'>{endTime}</p>
        </div>
      </div>
      <LoadingBar
        symbol={ido.currency.symbol.toUpperCase()}
        tokenCommited={commitments}
        totalRaise={totalRaise}
        secondsUntilStart={idoPublicData.secondsUntilStart}
        secondsUntilEnd={idoPublicData.secondsUntilEnd}
        status={idoPublicData.status}
      />

      <>
        <div className='info-wrapper'>
          <div className='data-wrapper'>
            <p className='data'>TOTAL COMMITTED</p>
            <p className='data text-white font-semibold'>
              {totalCommitments} {ido.currency.symbol.toUpperCase()}
            </p>
          </div>
          <div className='data-wrapper'>
            <p className='data'>PRICE PER LYNX TOKEN</p>
            <p className='data text-white font-semibold'>
              {ido.price} {ido.currency.symbol.toUpperCase()}
            </p>
          </div>
          <p className='text-[#cbd5e1] text-md font-bold uppercase mt-8'>Distribution</p>
          <div className='data-wrapper'>
            <p className='data'>LYNX</p>
            <p className='data text-white font-semibold'>{toSellPercentage}</p>
          </div>
          <div className='data-wrapper'>
            <p className='data'>veLynx 180 days</p>
            <p className='data text-white font-semibold'>{toSellPercentage}</p>
          </div>
          <div className='data-wrapper'>
            <p className='data'>veLynx 365 days</p>
            <p className='data text-white font-semibold'>{toSellPercentage}</p>
          </div>
          <div className='data-wrapper'>
            <p className='data'>veLynx 730 days</p>
            <p className='data text-white font-semibold'>{toSellPercentage}</p>
          </div>
        </div>
        {idoPublicData.usePointList && (
          <div className='info-wrapper'>
            <p className='text-[#cbd5e1] text-md font-bold uppercase'>Whitelist round</p>
            <div className='data-wrapper'>
              <p className='data'>Total Allocation</p>
              <p className='data text-white font-semibold'>{allocation}</p>
            </div>
            <div className='data-wrapper'>
              <p className='data'>Remaining Allocation</p>
              <p className='data text-white font-semibold'>{remainingAlloc}</p>
            </div>
          </div>
        )}
        {account ? (
          ended ? (
            <button
              disabled={idoWalletData.hasClaimed || idoWalletData.amountTokenCommited.toNumber() === 0 || !finalized}
              className='claim-button'
              onClick={() => handleClaim(ido)}
            >
              {idoWalletData.hasClaimed ? 'CLAIMED' : 'CLAIM'}
            </button>
          ) : (
            <CommitInput status={idoPublicData.status} ido={ido} remaining={remaining} symbol={ido.currency.symbol.toUpperCase()} />
          )
        ) : (
          <StyledButton
            onClickHandler={() => {
              openWalletModal()
            }}
            content={'CONNECT WALLET'}
            className={'w-[100%] md:w-[50%] px-[16px] my-8 py-4 m-[auto] md:px-[42px] '}
          />
        )}
      </>

      {account && (
        <>
          <div className='info-wrapper'>
            <div className='data-wrapper'>
              <p className='data'>TOTAL COMMITTED</p>
              <p className='data text-white font-semibold'>
                {amountTokenCommited} {ido.currency.symbol.toUpperCase()}
              </p>
            </div>
            <p className='text-[#cbd5e1] text-md font-bold uppercase mt-8'>Distribution</p>
            <div className='data-wrapper'>
              <p className='data'>LYNX</p>
              <p className='data text-white font-semibold'>{toClaim}</p>
            </div>
            <div className='data-wrapper'>
              <p className='data'>veLynx 180 days</p>
              <p className='data text-white font-semibold'>{toClaim}</p>
            </div>
            <div className='data-wrapper'>
              <p className='data'>veLynx 365 days</p>
              <p className='data text-white font-semibold'>{toClaim}</p>
            </div>
            <div className='data-wrapper'>
              <p className='data'>veLynx 730 days</p>
              <p className='data text-white font-semibold'>{toClaim}</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Data
