import BigNumber from 'bignumber.js'
import { getV3VoterContract } from './contractHelpers'
import { fromWei } from './formatNumber'
import { epochDuration } from '../hooks/useGeneral'
import { multicall } from './multicall'
import { veTHEAbi, veTheV3ApiAbi } from '../config/abi'
import { getVeTHEAPIAddress, getVeTHEAddress } from './addressHelpers'

export const fetchUserVeTHEs = async (web3, account) => {
  const voterContract = getV3VoterContract(web3)
  const currentEpoch = await voterContract.methods._epochTimestamp().call()
  const currentWeek = Math.floor(new Date().getTime() / (epochDuration * 1000))
  let [[thisWeekVotingPower, nextWeekVotingPower], [veTHEInfos, votesInfos]] = await Promise.all([
    multicall(veTHEAbi, [
      {
        address: getVeTHEAddress(),
        name: 'getPastVotes',
        params: [account, currentEpoch],
      },
      {
        address: getVeTHEAddress(),
        name: 'getPastVotes',
        params: [account, parseInt(currentEpoch) + epochDuration],
      },
    ]),
    multicall(veTheV3ApiAbi, [
      {
        address: getVeTHEAPIAddress(),
        name: 'getNFTFromAddress',
        params: [account],
      },
      {
        address: getVeTHEAPIAddress(),
        name: 'getVotesFromAddress',
        params: [account],
      },
    ]),
  ])

  thisWeekVotingPower = thisWeekVotingPower.toString()
  nextWeekVotingPower = nextWeekVotingPower.toString()
  veTHEInfos = veTHEInfos[0]
  votesInfos = votesInfos[0]

  const totalVotes = votesInfos.votes.reduce((sum, current) => {
    return sum.plus(current.weight.toString())
  }, new BigNumber(0))

  const accountInfo = {
    votingPower: fromWei(votesInfos.votingPower.toString()),
    epochVotes: fromWei(votesInfos.epochVotes.toString()),
    nextEpochVotes: fromWei(votesInfos.nextEpochVotes.toString()),
    voteTs: votesInfos.voteTs.toString(),
    voted: votesInfos.voted && votesInfos.votes.length > 0,
    votedCurrentEpoch: votesInfos.voted && votesInfos.votes.length > 0,
    votes: votesInfos.votes.map((item) => {
      return {
        address: item.pair,
        weight: fromWei(item.weight.toString()),
        weightPercent: totalVotes.isZero() ? new BigNumber(0) : new BigNumber(item.weight.toString()).div(totalVotes).times(100),
      }
    }),
  }
  const userVeInfo = veTHEInfos.map((veTHE) => {
    const lockedEnd = Number(veTHE[7].toString())
    const diff = Math.ceil((lockedEnd - new Date().getTime() / 1000) / 86400)

    const votedWeek = Math.floor(Number(veTHE[8]) / epochDuration)
    const votedCurrentEpoch = votedWeek === currentWeek && veTHE.votes.length > 0 // && veTHE[1]

    return {
      thisWeekVotingPower: fromWei(thisWeekVotingPower),
      nextWeekVotingPower: fromWei(nextWeekVotingPower),
      decimals: Number(veTHE[0].toString()),
      voted: veTHE[1],
      votedCurrentEpoch,
      attachments: veTHE[2],
      id: veTHE[3].toString(),
      amount: fromWei(veTHE[4].toString()),
      isPermanent: veTHE[4].toString() === veTHE[5].toString(),
      voting_amount: fromWei(veTHE[5].toString()),
      rebase_amount: fromWei(veTHE[6].toString()),
      lockEnd: parseInt(lockedEnd.toString()),
      delegatee: veTHE.delegatee,
      vote_ts: veTHE[8].toString(),
      votes: veTHE[9].map((item) => {
        return {
          address: item[0],
          weight: fromWei(item[1].toString()),
          weightPercent: totalVotes.isZero() ? new BigNumber(0) : new BigNumber(item[1].toString()).div(totalVotes).times(100),
        }
      }),
      diffDates: diff > 0 ? 'Expires in ' + diff + ' days' : 'Expired ' + diff * -1 + ' days ago',
    }
  })
  return { accountInfo, userVeInfo }
}
