import React from 'react'

const CheckBox = ({ onChange, toggleId, small, checked = false }) => (
  <input
    type='checkbox'
    id={toggleId}
    checked={checked}
    onChange={onChange}
    className={`${small ? 'w-4 h-4' : 'w-6 h-6'} !text-themeOrange border-gray-200 rounded hover:ring-themeOrangeLight hover:ring-2 cursor-pointer`}
  />
)

export default CheckBox
