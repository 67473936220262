import React, { useState, useEffect, useMemo, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import BalanceInput from '../../components/common/Input/BalanceInput'
import { formatAmount, isInvalidAmount } from '../../utils/formatNumber'
import CommonHollowModal from '../../components/common/CommonHollowModal'
import { useCreateLock } from '../../hooks/useLock'
import { customNotify } from '../../utils/notify'
import { periodLevels } from '../../config/constants'
import Toggle from '../../components/common/Toggle'
import { TransactButton } from '../../components/common/Buttons/transactButton'

const week = 86400 * 7 * 1000

const minTimeStamp = 86400 * 14 * 1000
const maxTimeStamp = 86400 * 728 * 1000
const minDate = new Date(Math.floor((new Date().getTime() + minTimeStamp) / week) * week)
const maxDate = new Date(Math.floor((new Date().getTime() + maxTimeStamp) / week) * week)

const CreateModal = ({ isOpen, setIsOpen, theAsset }) => {
  const [amount, setAmount] = useState('')
  const [selectedDate, setSelectedDate] = useState(minDate)
  const [periodLevel, setPeriodLevel] = useState(0)
  const [isPermanent, setIsPermanent] = useState(false)
  const unlockTime = useMemo(() => {
    return moment(selectedDate).diff(moment(), 'seconds')
  }, [selectedDate])
  const { onCreateLock, pending } = useCreateLock(amount, selectedDate)

  const errorMsg = useMemo(() => {
    if (isInvalidAmount(amount)) {
      return 'ENTER AN AMOUNT'
    }
    if (!theAsset || theAsset.balance.lt(amount)) {
      return 'INSUFFICIENT BALANCE'
    }
    return null
  }, [amount, theAsset])

  const votingPower = useMemo(() => {
    if (amount && amount > 0) {
      return isPermanent ? formatAmount(amount) : formatAmount((amount * unlockTime) / (86400 * 365 * 2))
    } else {
      return '-'
    }
  }, [amount, unlockTime, isPermanent])

  useEffect(() => {
    let timestamp = 0
    if (periodLevel < 0) return
    switch (periodLevel) {
      case 0:
        timestamp = minTimeStamp
        break
      case 1:
        timestamp = 86400 * 180 * 1000
        break
      case 2:
        timestamp = 86400 * 364 * 1000
        break
      case 3:
        timestamp = 86400 * 730 * 1000
        break

      default:
        break
    }
    if (periodLevel !== 3) setIsPermanent(false)
    const date = new Date(Math.floor((new Date().getTime() + timestamp) / week) * week)
    setSelectedDate(date)
  }, [periodLevel])

  useEffect(() => {
    if (isPermanent) {
      setPeriodLevel(3)
    }
  }, [isPermanent])

  const onCreate = useCallback(() => {
    if (errorMsg) {
      customNotify(errorMsg, 'warn')
      return
    }
    onCreateLock(amount, unlockTime, isPermanent)
      .then(() => {
        setIsOpen(false)
      })
      .catch((err) => {
        customNotify(err.message, 'error')
      })
  }, [amount, unlockTime, isPermanent, errorMsg, onCreateLock, setIsOpen])

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title='Create New Lock'>
      <div className='mt-3'>
        <BalanceInput title='Amount' inputAmount={amount} setInputAmount={setAmount} symbol='LYNX' balance={theAsset?.balance} logoURIs={['/logo.png']} />
        <p className='mt-6 text-white text-sm md:text-base'>Lock Until</p>
        <div className='mt-1.5 md:mt-2.5 p-px w-full rounded-md'>
          <div className='bg-white/5 flex items-center px-2.5 h-[48px] lg:h-[62px] rounded-md'>
            <img alt='' className='w-[32px] lg:w-[40px] h-[32px] lg:h-[40px]' src='/images/lock/calendar-icon.svg' />
            <DatePicker
              className='bg-transparent w-full pl-[6px] text-xl lg:text-2xl leading-8 placeholder-dimGray text-white font-light'
              selected={selectedDate}
              dateFormat='yyyy/MM/dd'
              onChange={(date) => {
                if (periodLevel >= 0) {
                  setPeriodLevel(-1)
                }
                if (date.getTime() === selectedDate.getTime()) {
                  return
                }
                setSelectedDate(new Date(Math.floor(date.getTime() / week) * week))
              }}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </div>
        <span>
          {' '}
          <p className='mt-2 text-white text-xs md:text-sm italic'>The date picker auto-selects an epoch</p>
        </span>
        <div className='mt-4 grid grid-cols-2 md:grid-cols-4 gap-[11px] text-white text-[14px] lg:text-base'>
          {periodLevels.map((level, index) => {
            if (level.value === periodLevel) {
              return (
                <div
                  className='h-[40px] bg-themeOrange rounded-md flex items-center justify-center cursor-pointer font-medium'
                  key={`level-${index}`}
                  onClick={() => setPeriodLevel(level.value)}
                >
                  {level.label}
                </div>
              )
            } else {
              return (
                <div
                  className='h-[40px] bg-neutral-500/90 rounded-md flex items-center justify-center cursor-pointer font-light'
                  key={`level-${index}`}
                  onClick={() => setPeriodLevel(level.value)}
                >
                  {level.label}
                </div>
              )
            }
          })}
        </div>
        <div className='flex items-center space-x-2 mt-4'>
          <Toggle
            checked={isPermanent}
            onChange={() => {
              setIsPermanent(!isPermanent)
              if (!isPermanent) setPeriodLevel(3)
            }}
            small
            toggleId='permanent'
          />
          <p className='text-[#DEDBF2] text-sm md:text-base'>Max lock this veLYNX automatically every epoch</p>
        </div>
        <div className='my-3 lg:my-5 flex items-center justify-between'>
          <span className='text-base lg:text-xl text-white font-light'>veLYNX Voting Power:</span>
          <span className='text-lg lg:text-[22px] text-white font-medium'>{votingPower}</span>
        </div>
        <TransactButton
          disabled={errorMsg || pending}
          pending={pending}
          onClickHandler={onCreate}
          content={errorMsg || 'LOCK'}
          className='w-full text-base md:text-lg py-3 px-4 mt-4'
        />
      </div>
    </CommonHollowModal>
  )
}

export default CreateModal
