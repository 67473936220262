import React from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import StyledButton from '../../common/Buttons/styledButton'
import { formatAmount } from '../../../utils/formatNumber'

const WarningModal = ({ isOpen, setIsOpen, onClickHandler, priceImpact }) => {
  return (
    <>
      <div
        onClick={() => {
          setIsOpen(false)
        }}
        className={`fixed ${
          isOpen ? 'visible z-[150] opacity-100' : 'z-0 invisible opacity-0'
        } inset-0 w-full h-full transition-all duration-300 ease-in-out bg-opacity-[0.01] backdrop-blur-[6px] bg-body`}
      ></div>

      <div
        className={`${
          isOpen ? 'z-[151] visible opacity-100' : 'z-0 invisible opacity-0'
        } w-full inset-0 fixed transition-all duration-300 ease-in-out flex items-center min-h-screen justify-center flex-col paraent`}
      >
        <div className={`max-w-[92%] sm:min-w-[500px] sm:max-w-[500px] md:min-w-[588px] md:max-w-[588px] mx-auto w-full rounded-[20px] p-px`}>
          <div className='bg-white/10 backdrop-blur-lg border border-[#ffffff33] rounded-[20px] px-3 py-3 md:px-6 md:py-5'>
            <div className='text-center text-white text-[22px] lg:text-[27px] font-semibold f-f-fg'>Are You Sure You Want To Swap?</div>
            <div className='mt-3 text-center text-[#DEDBF2] text-[15px] md:text-[18px] font-medium'>
              <div>
                Your order will incur a <span className='text-[#CF3A41] font-bold'>{formatAmount(priceImpact)}% price impact</span>.
              </div>
              <div>Are you sure you want to continue?</div>
            </div>
            <div className='flex items-center mt-5 w-full space-x-[18px]'>
              <StyledButton
                onClickHandler={() => {
                  onClickHandler()
                  setIsOpen(false)
                }}
                content={'SWAP'}
                className='w-1/2 text-base md:text-lg py-3 px-4 mt-4'
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'CANCEL'}
                className='
                flex w-1/2 items-center justify-center text-white text-base md:text-lg py-3 px-4 mt-4 rounded-lg'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WarningModal
