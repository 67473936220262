import BigNumber from 'bignumber.js'
import { useContext, useMemo, useState, useEffect } from 'react'
import { FusionsContext } from '../context/FusionsContext'
import { BaseAssetsConetext } from '../context/BaseAssetsConetext'
import { getTHEAddress } from '../utils/addressHelpers'
import { ZERO_VALUE, fromWei } from '../utils/formatNumber'
import { useMinter, useV3Voter } from './useContract'
import useRefresh from './useRefresh'
import { TOTAL_VOLUME_DATA, FUSION_TOTAL_TVL } from '../apollo/queries'
import { fusionClient } from '../apollo/client'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { extraRewarderAbi } from '../config/abi'
import { multicall } from '../utils/multicall'
import { getCircSupply } from '../utils/api'
// import { getERC20Contract } from '../utils/contractHelpers'
// import { getVeTHEAddress } from '../utils/addressHelpers'
import { useParams } from 'react-router-dom'
import { DoubleRewarders } from '../config/constants/doubleRewarders'
import { defaultChainId } from '../config/constants'
import { getGasPrices } from '../helpers/gasPrice'

const useTHEAsset = () => {
  const baseAssets = useContext(BaseAssetsConetext)
  const theAsset = useMemo(() => {
    return baseAssets.length > 0 ? baseAssets.find((item) => item.address.toLowerCase() === getTHEAddress().toLowerCase()) : null
  }, [baseAssets])

  return theAsset
}

const useTVL = () => {
  const [fusionTvl, setFusionTvl] = useState(new BigNumber(0))
  const fusions = useContext(FusionsContext)

  const fetchInfo = async () => {
    const result = await fusionClient.query({
      query: FUSION_TOTAL_TVL(),
      fetchPolicy: 'cache-first',
    })
    if (result?.data?.factories[0]?.totalValueLockedUSD) {
      setFusionTvl(Number(result?.data?.factories[0]?.totalValueLockedUSD))
    }
  }

  useEffect(() => {
    fetchInfo()
  }, [fusions])

  const v1Tvl = useMemo(() => {
    return fusions
      .filter((fusion) => !fusion.isGamma)
      .reduce((sum, current) => {
        return sum.plus(current.tvl)
      }, new BigNumber(0))
  }, [fusions])

  return useMemo(() => {
    if (!v1Tvl.isZero() && fusionTvl > 0) {
      return v1Tvl.plus(fusionTvl)
    }
    return new BigNumber(0)
  }, [v1Tvl, fusionTvl])
}

const precision = 1000
const useVoteEmissions = () => {
  const [voteEmssions, setVoteEmissions] = useState(null)
  const [lpEmission, setLpEmission] = useState(new BigNumber(0))
  const voterContract = useV3Voter()
  const minterContract = useMinter()
  const theAsset = useTHEAsset()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchSupply = async () => {
      const [totalWeight, weekly_emission, teamRate, rebaseMax] = await Promise.all([
        voterContract.methods.totalWeight().call(),
        minterContract.methods.weekly_emission().call(),
        minterContract.methods.teamRate().call(),
        minterContract.methods.REBASEMAX().call(),
      ])
      const LPweight = (precision - teamRate - rebaseMax) / precision
      const lpEmissionRes = fromWei(weekly_emission).times(LPweight)
      setLpEmission(lpEmissionRes)
      setVoteEmissions(Number(totalWeight) > 0 ? lpEmissionRes.times(theAsset.price).div(100) : new BigNumber(0))
    }
    if (voterContract && minterContract && theAsset) {
      fetchSupply()
    }
  }, [voterContract, minterContract, theAsset, fastRefresh])
  return { voteEmssions, lpEmission }
}

export const epoch0 = 1707955200
export const epochDuration = 60 * 60 * 24 * 7 // One week
// export const epochDuration = 60 * 15 // 15 minutes

const useEpochTimer = () => {
  const [epochInfo, setEpochInfo] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    epoch: 0,
  })
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const curTime = new Date().getTime() / 1000
    const epoch = Math.floor((curTime - epoch0) / epochDuration)
    const nextEpoch = Math.ceil(curTime / epochDuration) * epochDuration
    const days = Math.floor((nextEpoch - curTime) / 86400)
    const hours = Math.floor((nextEpoch - curTime - days * 86400) / 3600)
    const mins = Math.floor((nextEpoch - curTime - days * 86400 - hours * 3600) / 60)
    setEpochInfo({
      days,
      hours: hours < 10 ? '0' + hours : hours,
      mins: mins < 10 ? '0' + mins : mins,
      epoch,
    })
  }, [fastRefresh])

  return epochInfo
}

const useSupply = () => {
  const [circSupply, setCircSupply] = useState(0)
  const [lockedSupply, setLockedSupply] = useState(0)
  const { fastRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchVolume = async () => {
      const [supply] = await Promise.all([getCircSupply()])
      setCircSupply(supply ? supply.circulating_supply : 0)
      setLockedSupply(supply ? supply.locked_supply : 0)
    }
    fetchVolume()
  }, [fastRefresh, web3])

  return { circSupply, lockedSupply }
}

const useOneDayVolume = () => {
  const [oneDayVolume, setOneDayVolume] = useState(0)
  const { fastRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchVolume = async () => {
      const curblockNumber = await web3.eth.getBlockNumber()
      const last = curblockNumber - 28800

      const [/* result, oneDayResult, */ todayFusionVolume, yesterdayFusionVolume] = await Promise.all([
        // client.query({
        //   query: TOTAL_VOLUME_DATA(),
        //   fetchPolicy: 'cache-first',
        // }),
        // client.query({
        //   query: TOTAL_VOLUME_DATA(last),
        //   fetchPolicy: 'cache-first',
        // }),
        fusionClient.query({
          query: TOTAL_VOLUME_DATA(),
          fetchPolicy: 'cache-first',
        }),
        fusionClient.query({
          query: TOTAL_VOLUME_DATA(last),
          fetchPolicy: 'cache-first',
        }),
      ])
      let v1Volume = 0
      let fusionVolume = 0

      // if (result?.data?.factories[0]?.totalVolumeUSD && oneDayResult?.data?.factories[0]?.totalVolumeUSD) {
      //   v1Volume = Number(result?.data?.factories[0]?.totalVolumeUSD) - Number(oneDayResult?.data?.factories[0]?.totalVolumeUSD)
      // }

      if (todayFusionVolume?.data?.factories[0]?.totalVolumeUSD && yesterdayFusionVolume?.data?.factories[0]?.totalVolumeUSD) {
        fusionVolume = Number(todayFusionVolume?.data?.factories[0]?.totalVolumeUSD) - Number(yesterdayFusionVolume?.data?.factories[0]?.totalVolumeUSD)
      }
      setOneDayVolume(v1Volume + fusionVolume)
    }
    fetchVolume()
  }, [fastRefresh, web3])

  return oneDayVolume
}

const useExtraRewardsInfo = () => {
  const [extraRewardsInfo, setExtraRewardsInfo] = useState([])
  const { fastRefresh } = useRefresh()
  const { account } = useWeb3Wagmi()

  useEffect(() => {
    const fetchInfo = async () => {
      const doubleRewarders = DoubleRewarders[defaultChainId] ?? []
      const calls = doubleRewarders.map((item) => {
        return {
          address: item.doubleRewarderAddress,
          name: 'rewardPerSecond',
          params: [],
        }
      })
      const rewardRates = await multicall(extraRewarderAbi, calls)
      let pendingRewards = []
      if (account) {
        const calls = doubleRewarders.map((item) => {
          return {
            address: item.doubleRewarderAddress,
            name: 'pendingReward',
            params: [account],
          }
        })
        pendingRewards = await multicall(extraRewarderAbi, calls)
      }
      const final = doubleRewarders.map((item, index) => {
        return {
          ...item,
          rewardRate: fromWei(Number(rewardRates[index])),
          pendingReward: account ? fromWei(Number(pendingRewards[index])) : ZERO_VALUE,
        }
      })
      setExtraRewardsInfo(final)
    }
    fetchInfo()
  }, [account, fastRefresh])
  return extraRewardsInfo
}

export const useAnalyticsVersion = () => {
  const params = useParams()
  const version = params && params.version ? params.version : 'total'
  return version
}

export const useGasPrice = () => {
  const endpoint = 'https://ethapi.openocean.finance/v2/59144/gas-price'
  const [gasPrices, setGasPrices] = useState({
    standard: '2978905516',
    fast: '2978905516',
    instant: '2978905516',
  })
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchGas = async () => {
      setGasPrices(await getGasPrices())
    }
    fetchGas()
  }, [slowRefresh, endpoint, setGasPrices])

  return gasPrices
}

export { useTHEAsset, useTVL, useVoteEmissions, useEpochTimer, useSupply, useOneDayVolume, useExtraRewardsInfo }
