import React, { useContext, useState } from 'react'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import SearchTokenPopup from '../SearchTokenPopup'

const RewardSelect = ({ asset, setAsset }) => {
  const [isOpen, setIsOpen] = useState(false)
  const baseAssets = useContext(BaseAssetsConetext)

  return (
    <div className={`mt-1.5 md:mt-2.5 p-px w-full relative`}>
      <div className='bg-white/10 rounded-[10px] flex items-center'>
        <div
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className={`w-full h-full cursor-pointer flex items-center justify-between relative z-10 p-3`}
        >
          {asset ? (
            <div className={`flex items-center space-x-3`}>
              <div className='flex items-center'>
                <img className='w-[26px] h-[26px] md:w-[30px] md:h-[30px]' alt='' src={asset.logoURI} />
              </div>
              <p className='text-white text-base md:text-[19px] font-medium leading-5 md:leading-[30px] f-f-fg'>{asset.symbol}</p>
            </div>
          ) : (
            <div className={`w-full h-full font-normal text-white text-lg md:text-2xl md:leading-10`}>Select</div>
          )}
          <img
            className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
            alt=''
            src='/images/swap/dropdown-arrow.png'
          />
        </div>
      </div>
      <SearchTokenPopup popup={isOpen} setPopup={setIsOpen} setSelectedAsset={setAsset} baseAssets={baseAssets.filter((asset) => asset.address !== 'ETH')} />
    </div>
  )
}

export default RewardSelect
