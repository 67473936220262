import React from 'react'
import useWeb3, { useWeb3Wagmi } from '../hooks/useWeb3Context'
import { useActiveWeb3React } from '../hooks/useActiveWeb3React'

const Web3Context = React.createContext([])

const Web3ContextProvider = ({ children }) => {
  const web3Wagmi = useWeb3Wagmi() ?? {}
  const web3 = useWeb3() ?? {}
  const activeWeb3React = useActiveWeb3React() ?? {}

  return <Web3Context.Provider value={{ web3Wagmi, web3, activeWeb3React }}>{children}</Web3Context.Provider>
}

export { Web3Context, Web3ContextProvider }
