import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TransactionType } from '../../../config/constants'
import { closeTransaction } from '../../../state/transactions/actions'
import Modal from '../Modal'
import Spinner from '../Spinner'
import { useNavigate } from 'react-router-dom'

const Transaction = () => {
  const { popup, title, transactions, final, link } = useSelector((state) => state.transactions)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setPopup = useCallback(
    (value) => {
      if (!value) {
        dispatch(closeTransaction())
      }
    },
    [dispatch],
  )

  const txns = useMemo(() => {
    return transactions ? Object.values(transactions) : []
  }, [transactions])

  return (
    <Modal isTransaction={true} width={540} popup={popup} setPopup={setPopup} title={final ? '' : title} disableOutside={true}>
      {final ? (
        <div className='w-full flex flex-col items-center justify-center sm:min-w-[450px] lg:max-w-[540px]'>
          <img alt='' className='my-4 max-w-[80px] md:max-w-[100px]' src='/images/swap/big-checkmark.svg' />
          <div className='max-w-[266px] md:max-w-[330px] w-full flex flex-col items-center'>
            <p className='text-white text-[24px] md:text-[28px] text-center'>{final}</p>
            <p className='my-3 text-dimGray text-base leading-6 text-center'>Transaction has been confirmed by the blockchain.</p>
          </div>
          <button
            onClick={() => {
              if (link) {
                navigate(link)
              }
              setPopup(false)
            }}
            className={`w-full py-2 md:py-3 bg-themeOrangeLight text-white text-base md:text-lg leading-8 tracking-[1.44px] ease-out mt-4 rounded-[10px]`}
          >
            {link ? 'CLOSE AND REDIRECT' : 'CLOSE'}
          </button>
        </div>
      ) : (
        <div className='py-3 mt-5 sm:min-w-[450px] lg:max-w-[540px]'>
          {txns.map((tx, index) => {
            return (
              <div className='flex items-center justify-between space-y-2' key={`tx-${index}`}>
                <p className={`text-white text-sm md:text-base leading-5`}>{tx.desc}</p>
                {tx.status === TransactionType.SUCCESS && <img alt='' src='/images/swap/success-mark.svg' />}
                {tx.status === TransactionType.WAITING && <img alt='' className='w-5' src='/images/swap/pending-mark.svg' />}
                {tx.status === TransactionType.PENDING && <Spinner />}
                {tx.status === TransactionType.FAILED && <img alt='' src='/images/swap/failed-mark.svg' />}
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  )
}

export default Transaction
