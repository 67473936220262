const contracts = {
  THE: {
    56: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
    // 97: '0x4E5009a313978EFa9732997e93928529F8a84072',
    97: '0x7A286Db4E11F6ED110a15aCb3412E0b838aF7E0A',
    59140: '0xCa1B6EB5B97b61813B3444DE45Be5897d8Cd6d9E',
    59144: '0x1a51b19CE03dbE0Cb44C1528E34a7EDD7771E9Af',
  },
  WBNB: {
    59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    59144: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
  },
  ETH: {
    56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
    59144: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
  },
  thenian: {
    56: '0x2Af749593978CB79Ed11B9959cD82FD128BA4f8d',
    97: '0x45c85db14Ff99803b59989e91178847e1892Ea02',
    59140: '',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    59140: '0xca11bde05977b3631167028862be2a173976ca11',
    59144: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  router: {
    // 56: '0x20a304a7d126758dfe6B243D0fc515F83bCA8431',
    // 56: '0x9B237893321b2D447E1D1Ae02004ebA30b187D0d',
    56: '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109',
    // 97: '0x761636F40307AAEC44367BE82FCD9F75FeA310f5',
    97: '0xC0f8eE736cEC8c1a12e9c25272e6f417510165FF',
    59140: '0xD586D412b88Bb58931f57ed180969e6791120Bbf',
    59144: '0x610D2f07b7EdC67565160F587F37636194C34E74',
  },
  factory: {
    56: '0xAFD89d21BdB66d00817d4153E055830B1c2B3970',
    // 97: '0x86D22c4130401a5723fFD6C0c798c205A2Fea1ed',
    97: '0xa584Fe0B5fBF5394D453f106e00112432fb1ee15',
    59140: '0x231e1740063FacD1092486156D5D9eC881Baa7E8',
    59144: '0xBc7695Fd00E3b32D08124b7a4287493aEE99f9ee',
  },
  veTHE: {
    56: '0xfBBF371C9B0B994EebFcC977CEf603F7f31c070D',
    // 97: '0x04106fb6E161D0C5E8E0129F4a8a2cd62dCF6110',
    97: '0x3D81B3CDdB8F9555c5cf5C680D3Fea630A5ff856',
    59140: '0x6c664a019Cf2240d948b7758D760427ba4A76CD3',
    59144: '0x8D95f56b0Bac46e8ac1d3A3F12FB1E5BC39b4c0c',
  },
  bveLYNX: {
    59140: '0xBFbCA1Ba966C9cbd227C75207F69D734043bCbB6',
    59144: '0xe8a4c9B6a2b79Fd844c9e3AdBc8Dc841eEce557B',
  },
  oLYNX: {
    59140: '0xB289A81228D77C19167Cd8129218631325f55f81',
    59144: '0x63349BA5E1F71252eCD56E8F950D1A518B400b60',
  },
  veDist: {
    // RewardDistributor
    56: '0xC6bE40f6a14D4C2F3AAdf9b02294b003e3967779',
    // 97: '0x934547fee052323F05454c8a2d8b45BBc47812D7',
    97: '0x231e1740063facd1092486156d5d9ec881baa7e8',
    59140: '0xb000D1e0106865Be509b66afFec7A03E0100a98E',
    59144: '0x2222C569387b2A98fE1A9350F2caCDe918910EE4',
  },
  voter: {
    56: '0x62Ee96e6365ab515Ec647C065c2707d1122d7b26',
    // 97: '0x1344244b3432846d00e1D5cc9FEB825743304539',
    97: '0xf5fffC4C1B54c0DAE0c969a4e6fc9Af59679ecA0',
    59140: '0x436369ab9Cab9359E767c55D51Cc2B0e78e9be5e',
    59144: '0x0B2c83B6e39E32f694a86633B4d1Fe69d13b63c5',
  },
  v3voter: {
    56: '0x3A1D0952809F4948d15EBCe8d345962A282C4fCb',
    97: '0xf5fffC4C1B54c0DAE0c969a4e6fc9Af59679ecA0',
    59140: '0x436369ab9Cab9359E767c55D51Cc2B0e78e9be5e',
    59144: '0x0B2c83B6e39E32f694a86633B4d1Fe69d13b63c5',
  },
  minter: {
    56: '0x8AF62eBF04552e9d4cD04744CF87854eA08a18f9',
    97: '0x4c781a41c95356441029350f2bd5cd6a15d51b69',
    59140: '0xF963c1F6253E974AF153330F8fA2F6c57a9f27C9',
    59144: '0x9030ae4D0997658de58D274f79d3074B1BeB19D4',
  },
  pairAPI: {
    56: '0x7419477C03b0FEb9286F216b9d19E42f86B288b3',
    97: '0x27bB9Fc188c8Dbc5d95af97Db259Ea433Bcf3194',
    59140: '0xD8895b416e66B552Dd331FC8055BC28038e43Ced',
    59144: '0x6c84329CC8c37376eb32db50a17F3bFc917c3665',
  },
  veTHEAPI: {
    56: '0x949d5B4eb1A8fbA23AE7741d02C602bFFFdF813b',
    97: '',
    59140: '0x7d91adF680B43a1c7e76307976a8e7068011EC0f',
    59144: '0x8297F18d1e55013620f46fDA07FF59f393796672',
  },
  rewardsAPI: {
    56: '0x54a3E5ef4Ba0308FC60a5c159a96376cc0bB4787',
    97: '0x490eE33d1559e223A82aF6B806a0d5bdb6159114',
    59140: '0xD3E563f00e013B514c2Ac9E771FAf3B8BAED9255',
    59144: '0x6DC1dff60096195c9d4c2B89C4Bd64C3644F8b41',
  },
  pairV3API: {
    56: '0xE89080cEb6CAEb9Eba5a0d4Aa13686eFcB78A32E',
    97: '0x27bB9Fc188c8Dbc5d95af97Db259Ea433Bcf3194',
    59140: '0xD8895b416e66B552Dd331FC8055BC28038e43Ced',
    59144: '0x6c84329CC8c37376eb32db50a17F3bFc917c3665',
  },
  veTHEV3API: {
    56: '0x64039b95537D2904716D1957dD07061Ab94a4549',
    97: '0x9DDc9E4094DA476878F45c711892C480001867B9',
    59140: '0x7d91adF680B43a1c7e76307976a8e7068011EC0f',
    59144: '0x8297F18d1e55013620f46fDA07FF59f393796672',
  },
  rewardsV3API: {
    56: '0xa636471A76dd3777e5b101DEA2e07B9656c664e9',
    97: '0x490eE33d1559e223A82aF6B806a0d5bdb6159114',
    59140: '0xD3E563f00e013B514c2Ac9E771FAf3B8BAED9255',
    59144: '0x6DC1dff60096195c9d4c2B89C4Bd64C3644F8b41',
  },
  staking: {
    56: '0xe58E64fb76e3C3246C34Ee596fB8Da300b5Adfbb',
    97: '',
    59140: '',
  },
  royalty: {
    56: '0xBB2caf56BF29379e329dFED453cbe60E4d913882',
    97: '',
    59140: '',
  },
  dibs: {
    56: '0x664cE330511653cB2744b8eD50DbA31C6c4C08ca',
    97: '',
    59140: '',
  },
  dibsLottery: {
    56: '0x287ed50e4c158dac38e1b7e16c50cd1b2551a300',
    97: '',
    59140: '',
  },
  muon: {
    56: '0xBa079Ad36E48e75b8b37f17aF1Fc285bceB84391',
    97: '',
    59140: '',
  },
  openOcean: {
    56: '0x6352a56caadc4f1e25cd6c75970fa768a3304e64',
    97: '',
    59144: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
  },
  preFarming: {
    56: '0x6352a56caadc4f1e25cd6c75970fa768a3304e64',
    97: '',
    59140: '0x402ce3B30fcF47f88654c58B2f31C6eE405fb6C6',
    59144: '0x0b3a25AE91De4825B52D51CA54DFC8867367c72A',
  },
  preFarmingP2: {
    59144: '0xbf05db69176E47Bf89A6b19F7492d50751D20452',
  },
  pairApiSimple: {
    59140: '0xD8895b416e66B552Dd331FC8055BC28038e43Ced',
    59144: '0x6c84329CC8c37376eb32db50a17F3bFc917c3665',
  },
  merkleTree: {
    community: {
      59144: '0x6Ccc6b30C91D7BcAD4884812dD9358f28066272F',
      59140: '0x85bf32faa90e06CD19e4576104dDD20aD6b410Db',
    },
    portal: {
      59144: '0x46fF46f42bAF96C02480a70A8c249Cc18E3635f1',
      // Airdop: https://lineascan.build/address/0x5d4614ffed6164df543e48f8906a255bdda6cce6
      59140: '0x36Ca78a4cF782527590B0d5b3432b1d0Db4294B5',
    },
    strategic: {
      59144: '0x08a846946a1d7E70c56Ec002b0832699747EbcF8',
      // Airdrop: https://lineascan.build/address/0x61fc82fd2c5C9f137ac11c0a2aB8D2F29C1557Da
      59140: '0x3e1B07c3f67E7f9d08453E882d4F9E9f91c4725e',
    },
  },
  merkleTreeKey: {
    59144: '0xa1Ea0E1d58ae20bfB9AB43b157D8063f1E71A3Bc',
    59140: '0x02d6b91763d850e79de8adfb4c8cfe70703ba8f6',
  },
  airdropClaim: {
    59144: '',
    59140: '0x3c9145B38ba41f488c5685384a07748423EEd071',
  },
  lynxClaimToken: {
    59144: '0xf86381c8c4bbf3d398571e3d904ec04f12ac645a',
    59140: '0xCf9fb542a2333eDb77582AEcE7308f1f80492EaA',
  },
  lynxClaim: {
    59144: '0xd8CEe411c33ED18a7472D9B21caa60e72523e6C6',
    59140: '0x2663350BD5cA3509daf1fcCD3f58776682F1AB82',
  },
  airdropClaimKey: {
    59144: '',
    59140: '0x402B7a61c13191E8724C53A18095166482A0F4B6',
  },
  lynexKeys: {
    59144: '',
    59140: '0x0a498dC9C5b32534e2c3d58b4f78d1aA8F146212',
  },
}
export default contracts
