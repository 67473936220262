import React, { useState, useMemo, useEffect } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import ReactTooltip from 'react-tooltip'
import Pagination from '../../common/Pagination'
import Sticky from 'react-stickynode'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount, getLP0Symbol, getLP1Symbol, ZERO_ADDRESS } from '../../../utils/formatNumber'
import { useNavigate } from 'react-router-dom'
import { useHarvest } from '../../../hooks/useGauge'
import { NumberOfRows } from '../../../config/constants'
import NoFound from '../../common/NoFound'
import StyledButton from '../../common/Buttons/styledButton'
import './style.scss'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { useTimestamp } from '../../../utils/time'
import { BadgeContainer } from './badgeContainer'
import MultipleTokenImage from '../../common/MultipleTokenImage'
import Spinner from '../../common/Spinner'
import DepositLpModal from './v3/depositLpModal'
import DepositIchiModal from './depositIchiModal'

export const ItemWithTooltip = ({ usd, content, account, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return account || type === 'tvl' ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd ?? 0, true)}</p>
        <button className={`${arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[180px] !text-white !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33]'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
        delayShow={200}
      >
        {content}
      </ReactTooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[14px] lg:text-[15px] xl:text-[16px]'>-</div>
  )
}

const renderModal = (hasStaked, hasLp, isIchi, depositToken, manage, setManage, item) => {
  if (isIchi) {
    return <DepositIchiModal isOpen={manage} setIsOpen={setManage} pair={item} depositToken={depositToken} hasLp={hasLp} hasStaked={hasStaked} />
  } else {
    return <DepositLpModal isOpen={manage} setIsOpen={setManage} pair={item} hasLp={hasLp} hasStaked={hasStaked} />
  }
}

const TableRow = ({ item, idx }) => {
  const [isOpen, setIsOpen] = useState(!item.account.gaugeBalance.isZero())
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const navigate = useNavigate()
  const { onHarvest, pending } = useHarvest()
  const [manage, setManage] = useState(false)
  const timestamp = useTimestamp()

  const hasLocked = item.account?.gaugeLockedBalance?.gt(0) && item.account?.gaugeLockEnd > timestamp
  const isIchi = item.strategist === 'Ichi'
  const hasStaked = item.account?.gaugeBalance?.gt(0)
  const hasLp = item.account?.lpBalance.gt(0)
  const depositToken = item && item.allowedToken0 ? item.token0 : item.token1
  const extraAssets = item.extraRewards.filter((reward) => !reward.isFinished).map((reward) => reward.asset)

  return (
    <div key={idx} className='table-row-wrapper'>
      <div className='w-full  lg:w-[20%]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-3 mr:0 lg:mr-3'>
            <div className='flex items-center -space-x-2'>
              <img className='relative z-10' alt='' src={item.token0.logoURI} width={30} height={30} />
              <img className='relative z-[5]' alt='' src={item.token1.logoURI} width={30} height={30} />
            </div>
            <div>
              <div className='flex flex-wrap text-base leading-[30px] font-medium'>
                <span>{getLP0Symbol(item)}</span>/<span>{getLP1Symbol(item)}</span>
              </div>
              <p className='tracking-[0.78px] text-[12px] leading-none'>{item.title}</p>
              <div className='mt-1 fee-container'>
                <p className='fee-tag'>{formatAmount(item.fee / 10000, false, 3)}%</p>
              </div>
            </div>
          </div>
          <button
            className='lg:hidden'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <img alt='' className={`${isOpen ? 'rotate-180' : ''} transform`} src='/images/swap/dropdown-arrow.png' />
          </button>
        </div>
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center space-y-1'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>APR</p>
        <div data-tip data-for={`${item}-${idx}`} className='flex flex-col'>
          <div className='flex items-center justify-center text-base sm:text-[14px] xl:text-[18px] space-x-1'>
            <span className='text-sm opacity-75' style={{ fontSize: 'smaller' }}>
              Up to:&nbsp;
              {formatAmount(item.gauge.apr, true)}%
            </span>
          </div>
        </div>
        {item.extraRewards.length > 0 && extraAssets.length > 0 && (
          <div className='flex items-center justify-start space-x-2'>
            <p className='text-xs opacity-75'>Extra reward: </p>
            <MultipleTokenImage tokens={extraAssets} size={20} />
          </div>
        )}
        <BadgeContainer item={item} />
        <ReactTooltip
          className='max-w-[260px] !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33]'
          id={`${item}-${idx}`}
          place='right'
          effect='solid'
          delayShow={200}
        >
          <div className='tooltip-content'>
            <div className='tooltip-row'>
              <span>oLYNX → veLYNX</span>
              <span>{formatAmount(item.gauge.veTokenApr, true)}%</span>
            </div>
            <div className='tooltip-row'>
              <span>oLYNX → LYNX</span>
              <span>{formatAmount(item.gauge.minApr, true)}%</span>
            </div>
            {item.extraRewards?.length > 0 &&
              item.extraRewards
                .filter((reward) => reward.apr > 0)
                .map((reward) => {
                  return (
                    <div className='tooltip-row' key={reward.symbol}>
                      <span>{reward.symbol}</span>
                      <span>{formatAmount(reward.apr, true)}%</span>
                    </div>
                  )
                })}
          </div>
        </ReactTooltip>
      </div>
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[11%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Staked</p>
        <ItemWithTooltip
          account={account}
          type={'tvl'}
          usd={item.gauge.tvl}
          content={
            <>
              {formatAmount(item.gauge.pooled0)} {item.token0.symbol}
              <br />
              {formatAmount(item.gauge.pooled1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div>
      {/* second row */}
      {/* <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Pool</p>
        <ItemWithTooltip
          account={account}
          type={'pool'}
          usd={item.account.totalUsd}
          content={
            <>
              {formatAmount(item.account.total0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.total1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div> */}
      <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Stake</p>
        <ItemWithTooltip
          account={account}
          type={'stake'}
          usd={item.account.stakedUsd}
          content={
            <>
              {formatAmount(item.account.staked0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.staked1)} {item.token1.symbol}
              {hasLocked && (
                <>
                  <br /> {formatAmount(item.account?.gaugeLockedBalance)} Locked
                </>
              )}
            </>
          }
          idx={idx}
        />
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block  w-1/2 lg:w-[8%] mt-2 lg:mt-0`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Earnings</p>
        <ItemWithTooltip
          account={account}
          type={'earning'}
          usd={item.account.earnedUsd}
          content={
            <>
              {item.extraRewards?.length > 0 &&
                item.extraRewards
                  .filter((reward) => !reward.isFinished || (reward.isFinished && !reward.amount.isZero()))
                  .map((reward) => {
                    return (
                      <div key={reward.symbol}>
                        {formatAmount(reward.amount)} {reward.symbol}
                        <br />
                      </div>
                    )
                  })}
              {formatAmount(item.account.gaugeEarned)} oLYNX
            </>
          }
          idx={idx}
        />
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-full lg:w-[25%] mt-3.5 lg:mt-0`}>
        {account ? (
          <div className='space-x-5 xl:space-x-6 w-full flex items-center lg:justify-end'>
            <TransparentButton
              content={'Manage'}
              onClickHandler={() => {
                if (item && item.gauge.address === ZERO_ADDRESS) {
                  navigate(`/liquidity/manage/${item.address}`)
                } else {
                  setManage(true)
                }
              }}
              fontWeight={'font-medium'}
              className='h-10 px-[26px] text-white flex  max-w-[96px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[14px] w-full'
            />
            <button
              disabled={item.account.earnedUsd.isZero() || pending}
              className={`${
                item.account.earnedUsd.isZero() || pending ? 'opacity-[0.66] cursor-not-allowed text-gray' : 'text-[#e6a160] font-semibold'
              } text-base `}
              onClick={() => {
                onHarvest(
                  item,
                  item?.extraRewards?.map((reward) => reward.asset.address),
                )
              }}
            >
              Claim Earnings
            </button>
          </div>
        ) : (
          <div className='flex items-center lg:justify-end'>
            <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='py-[10px] px-[16px] text-sm' />
          </div>
        )}
      </div>
      {manage && renderModal(hasStaked, hasLp, isIchi, depositToken, manage, setManage, item)}
    </div>
  )
}

const TablePairs = ({ pairsData, loading, sort, setSort, sortOptions, isInactive, filter, searchText, isStaked, isMigration = false, baseAssets }) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)

  const pageCount = useMemo(() => {
    return Math.ceil(pairsData.length / pageSize)
  }, [pairsData, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize, filter, searchText, isStaked])

  return (
    <>
      {loading ? (
        <div className='flex flex-col w-full items-center p-3 mt-10'>
          <Spinner />
          <div className='text-white text-sm mt-2'>
            <p>Loading Pools..</p>
          </div>
        </div>
      ) : pairsData.length === 0 ? (
        <div className='flex flex-col w-full items-center'>
          <NoFound title='No pools found' />
          <p className={`${!isInactive && 'invisible'} text-white text-sm`}>Try toggling inactive gauges!</p>
        </div>
      ) : (
        <>
          {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
            <>
              <Sticky
                enabled={true}
                innerActiveClass={'bg-themeOrangeLight rounded-b-lg'}
                top={90}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={'sticky-table-header'}
                className={`z-[100]`}
              >
                <div className='w-[20%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${index === 4 ? 'w-[8%]' : index === 0 ? 'w-[14%]' : 'w-[11%]'} ml-6 text-[14px] xl:text-[18px] text-white f-f-fg`}
                    key={`header-${index}`}
                  >
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                    >
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                      <p className='flex items-center'>{option.label}</p>
                    </div>
                  </div>
                ))}
                <div className='w-[25%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
              </Sticky>
              <div className='flex flex-col gap-3'>
                {pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, idx) => {
                  return (
                    <TableRow
                      isLast={idx === pairsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length - 1}
                      idx={idx}
                      item={item}
                      key={`row-${idx}`}
                      isMigration={isMigration}
                      baseAssets={baseAssets}
                    />
                  )
                })}
              </div>
              <Pagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                currentPage={currentPage}
                total={pairsData.length}
              />
            </>
          ) : (
            <div className='flex flex-col w-full items-center'>
              <NoFound title='No pools found' />
              <p className={`${!isInactive && 'invisible'} text-white text-sm`}>Try toggling inactive gauges!</p>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default TablePairs
