import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import StyledButton from '../../components/common/Buttons/styledButton'
import PoolSelect from '../../components/common/PoolSelect'
import SubPage from '../../components/common/SubPage'
import { useV3Voter } from '../../hooks/useContract'
import useWalletModal from '../../hooks/useWalletModal'
import { useAddGauge } from '../../hooks/useWhitelist'
import { ZERO_ADDRESS } from '../../utils/formatNumber'
import { customNotify } from '../../utils/notify'
import { FusionsContext } from '../../context/FusionsContext'
import { useWeb3Wagmi } from '../../hooks/useWeb3'
import { TransactButton } from '../../components/common/Buttons/transactButton'

const GaugeModal = () => {
  const [pool, setPool] = useState(null)
  const { account } = useWeb3Wagmi()
  const fusions = useContext(FusionsContext)
  const fusionsWithGauges = useMemo(() => {
    return fusions.filter((pair) => pair && pair.gauge.address === ZERO_ADDRESS)
  }, [fusions])
  const { onAddGauge, pending } = useAddGauge()
  const { openWalletModal } = useWalletModal()
  const voterContract = useV3Voter()
  const { final } = useSelector((state) => state.transactions)

  const errorMsg = useMemo(() => {
    if (!pool) {
      return 'CHOOSE POOL'
    }
    return null
  }, [pool])

  useEffect(() => {
    if (final) {
      setPool(null)
    }
  }, [final])

  return (
    <SubPage title='Add Gauge'>
      <div className='w-full pt-3 md:pt-5'>
        {!account ? (
          <StyledButton
            onClickHandler={openWalletModal}
            content={'CONNECT WALLET'}
            className='py-[13px] md:py-[14px] mt-4 text-base md:text-lg px-[19px] w-full'
          />
        ) : (
          <>
            <div className={`flex flex-col w-full items-center justify-center mt-3 md:mt-5`}>
              <div className={`w-full`}>
                <p className='text-dimGray texts-[13px] md:text-xl'>Choose Pool</p>
                <PoolSelect pool={pool} setPool={setPool} pools={fusionsWithGauges} />
              </div>
            </div>
            <TransactButton
              disabled={errorMsg || pending}
              pending={pending}
              onClickHandler={async () => {
                const res = await Promise.all([
                  voterContract.methods.isWhitelisted(pool.token0.address).call(),
                  voterContract.methods.isWhitelisted(pool.token1.address).call(),
                ])
                if (res[0] && res[1]) {
                  onAddGauge(pool)
                } else {
                  customNotify('Tokens are not whitelisted', 'warn')
                }
              }}
              content={errorMsg || 'CONFIRM'}
              className='w-full h-[42px] sm:h-12 mt-[18px] text-[14px] mdLg:text-lg px-[23px]'
            />
          </>
        )}
      </div>
    </SubPage>
    // </CommonHollowModal>
  )
}

export default GaugeModal
