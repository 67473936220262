import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import Spinner from '../../common/Spinner'

const MobilePoolInfo = ({ item }) => {
  return item ? (
    <div className='flex flex-col xs:flex-row justify-around p-4 mt-4 bg-white/5 rounded-xl'>
      <div className='flex flex-col items-center justify-start xs:border-r border-slate-600 xs:pr-3 mb-3 xs:mb-0'>
        <p className='text-xs font-semibold'>My Stake</p>
        <p className='text-sm'>{'$' + formatAmount(item.account.totalUsd || 0, true)}</p>
        <div className='flex flex-col items-center text-dimGray text-xs'>
          <p className='truncate w-full text-center'>
            {formatAmount(item.account.total0)} {item.token0.symbol}
          </p>
          <p className='truncate w-full text-center'>
            {formatAmount(item.account.total1)} {item.token1.symbol}
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-start mb-3 xs:mb-0'>
        <p className='text-xs font-semibold'>TVL</p>
        <p className='text-sm'>{'$' + formatAmount(item.gauge.tvl || 0, true)}</p>
        <div className='flex flex-col items-center text-dimGray text-xs'>
          <p className='truncate w-full text-center'>
            {formatAmount(item.token0.reserve)} {item.token0.symbol}
          </p>
          <p className='truncate w-full text-center'>
            {formatAmount(item.token1.reserve)} {item.token1.symbol}
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-start xs:border-l border-slate-600 xs:pl-3'>
        <p className='text-xs font-semibold'>My Earnings</p>
        <p className='text-sm'>{'$' + formatAmount(item.account.earnedUsd || 0, true)}</p>
        <div className='flex flex-col items-center text-dimGray text-xs'>
          <>
            {item.extraRewards?.length > 0 &&
              item.extraRewards
                .filter((reward) => !reward.isFinished || (reward.isFinished && !reward.amount.isZero()))
                .map((reward) => {
                  return (
                    <p key={reward.symbol} className='truncate w-full text-center'>
                      {formatAmount(reward.amount)} {reward.symbol}
                    </p>
                  )
                })}
            <p className='truncate w-full text-center'>{formatAmount(item.account.gaugeEarned)} oLYNX</p>
          </>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default MobilePoolInfo
