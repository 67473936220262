import React from 'react'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { getXpPoints } from '../../../../hooks/wallet'
import { formatAmount } from '../../../../utils/formatNumber'
import '../dashboard.scss'

const LxplBanner = () => {
  const { account } = useWeb3Wagmi()
  const xp = getXpPoints(account)

  return (
    <div className='lxpl-banner-wrapper'>
      <div className='flex items-center space-x-1 md:space-x-4'>
        <img width={32} src='https://docs.linea.build/img/favicon.ico' alt='' />
        <div className='flex'>
          <span>Your LXP-L Points: </span> <span className='text-themeOrangeLight font-semibold mx-1'>{formatAmount(xp)}</span> <span>XP</span>
        </div>
      </div>
      <div className='flex space-x-1'>
        <p
          onClick={() => {
            window.open('https://referrals.linea.build/?refCode=oGi7YJuUKT', '_blank')
          }}
          className='font-semibold cursor-default hover:underline hover:cursor-pointer'
        >
          Learn More
        </p>
        <img alt='' src='/images/common/diagonal-arrow.svg' width={10} />
      </div>
    </div>
  )
}

export default LxplBanner
