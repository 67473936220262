import React, { useEffect, useRef, useContext, useMemo, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseAssetsConetext } from '../../../../../context/BaseAssetsConetext'
import { formatAmount } from '../../../../../utils/formatNumber'
import { fetchImportedToken } from '../../../../../utils/fetchImportToken'
import { useImportTokens } from '../../../../../state/application/hooks'
import { useAsset, useToken } from '../../../../../hooks/v3/Tokens'
import { unwrappedToken } from '../../../../../v3lib/utils/utils'
import { useCurrencyLogo } from '../../../../../hooks/v3/useCurrencyLogo'
import { usePool } from '../../../../../hooks/v3/usePools'
import usePrevious from '../../../../../hooks/usePrevious'
import { Position } from '../../../../../v3lib/entities'
import { useGetPriceOrderingFromPositionForUI } from '../../../liquidity/v3/myAlgebraPools/algebraPosition'
import { getRatio } from '../../../../../v3lib/utils/formatTickPrice'
import { useWeb3Wagmi } from '../../../../../hooks/useWeb3'
import StyledButton from '../../../../common/Buttons/styledButton'
import RemoveModal from '../../../liquidity/v3/myAlgebraPools/components/RemoveModal'
import DepositModal from '../../../liquidity/depositModal'

const Pool = ({ position, auto = null, manual = null }) => {
  const { account } = useWeb3Wagmi()
  const [withDraw, setWithDraw] = useState(false)
  const [stake, setStake] = useState(false)
  const [unstake, setUnstake] = useState(false)
  const navigate = useNavigate()

  let token0
  let token1
  let type
  let status
  let apr
  let staked0
  let staked1
  let stakedUsd
  let token0PooledPercent
  let outOfRange
  let closed
  let stakeBtnMsg

  if (auto) {
    token0 = position.token0
    token1 = position.token1
    type = position.type
    status = position.account.lpBalance.isZero() ? 'STAKED' : 'UNSTAKED'
    apr = position.gauge.apr
    staked0 = position.account.total0
    staked1 = position.account.total1
    stakedUsd = position.account.totalUsd
    token0PooledPercent = position.account.total0.times(position.token0.price).div(position.account.totalUsd).times(100).toFixed(2)
    stakeBtnMsg = useMemo(() => {
      if (position.account.lpBalance.isZero()) return 'UNSTAKE LP'
      return 'STAKE LP'
    }, [position.account.lpBalance])
  } else if (manual) {
    const baseAssets = useContext(BaseAssetsConetext)
    const firstRun = useRef(true)
    const { token0: _token0Address, token1: _token1Address, liquidity: _liquidity, tickLower: _tickLower, tickUpper: _tickUpper } = position
    const { addImportedToken } = useImportTokens()

    // Fetch tokens
    useEffect(() => {
      if (baseAssets.length === 0 || !firstRun.current) return
      async function fetchToken(address) {
        let token = address ? baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase()) : null
        if (!token && address) {
          token = await fetchImportedToken(address, account)
          if (token) {
            addImportedToken(token)
          }
        }
        return token
      }
      fetchToken(_token0Address)
      fetchToken(_token1Address)
      firstRun.current = false
    }, [baseAssets, _token0Address, _token1Address])

    // Get tokens data
    const _token0 = useToken(_token0Address)
    const _token1 = useToken(_token1Address)
    const currency0 = _token0 ? unwrappedToken(_token0) : undefined
    const currency1 = _token1 ? unwrappedToken(_token1) : undefined
    const asset0 = useAsset(_token0Address)
    const asset1 = useAsset(_token1Address)
    const logoURI0 = useCurrencyLogo(currency0)
    const logoURI1 = useCurrencyLogo(currency1)

    // Build Position from details returned
    const [poolState, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined)
    const [prevPoolState, prevPool] = usePrevious([poolState, pool]) || []
    const [, _pool] = useMemo(() => {
      if (!pool && prevPool && prevPoolState) {
        return [prevPoolState, prevPool]
      }
      return [poolState, pool]
    }, [pool, poolState, prevPool, prevPoolState])

    const manualPosition = useMemo(() => {
      if (_pool) {
        return new Position({
          pool: _pool,
          liquidity: _liquidity.toString(),
          tickLower: _tickLower,
          tickUpper: _tickUpper,
        })
      }
      return undefined
    }, [_liquidity, _pool, _tickLower, _tickUpper])

    outOfRange = _pool ? _pool.tickCurrent < _tickLower || _pool.tickCurrent >= _tickUpper : false
    closed = position.liquidity?.eq(0)
    const priceTickInfo = useGetPriceOrderingFromPositionForUI(manualPosition)
    const { priceLower, priceUpper } = priceTickInfo

    // Set global variables
    token0 = { logoURI: logoURI0 || null, symbol: currency0?.symbol || null }
    token1 = { logoURI: logoURI1 || null, symbol: currency1?.symbol || null }
    type = 'Manual'
    status = closed ? 'CLOSED' : outOfRange ? 'OUT OF RANGE' : 'IN RANGE'
    staked0 = manualPosition?.amount0 ? manualPosition?.amount0?.toFixed() : 0
    staked1 = manualPosition?.amount1 ? manualPosition?.amount1?.toFixed() : 0

    stakeBtnMsg = 'WITHDRAW'

    // TODO: Doesn't seem right
    const fiatValueOfLiquidity = useMemo(() => {
      if (!asset0 || !asset1 || !manualPosition) return
      return parseFloat(staked0) * asset0.price + parseFloat(staked1) * asset1.price
    }, [asset0, asset1, manualPosition])
    stakedUsd = fiatValueOfLiquidity

    token0PooledPercent = useMemo(() => {
      return priceLower && _pool && priceUpper ? getRatio(manualPosition.token0PriceLower, _pool.token0Price, manualPosition.token0PriceUpper) : undefined
    }, [_pool, priceLower, priceUpper])
  }

  const onRedirect = useCallback(() => {
    if (auto) {
      if (position.strategist !== 'V1') {
        navigate(`/liquidity/fusion?currency0=${position.token0.address}&currency1=${position.token1.address}&strategy=${position.title}`)
      } else navigate(`/liquidity/manage/${position.address.toLowerCase()}`)
    } else if (manual) {
      navigate(`/liquidity/fusion?currency0=${position.token0}&currency1=${position.token1}`)
    }
  }, [position])

  return (
    <>
      <div className='position'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <div className='flex items-center'>
              <img alt='' className='w-8 relative' src={token0.logoURI || '/images/tokens/UKNOWN.png'} />
              <img alt='' className='w-8 -ml-3' src={token1.logoURI || '/images/tokens/UKNOWN.png'} />
            </div>
            <div className='name'>
              <div className='symbol'>
                {token0.symbol}/{token1.symbol}
              </div>
              <div className='strategy'>{type}</div>
            </div>
          </div>
          <div
            className={`flex items-center py-1 px-2 rounded-md border ${
              status === 'UNSTAKED' || outOfRange || closed ? 'border-[#FF363666] bg-[#C900001A]/10' : 'border-[#72727a] bg-white/10'
            }  `}
          >
            <span className='text-[10px] uppercase text-white whitespace-nowrap'>{status}</span>
          </div>
        </div>
        <div className='data'>
          {auto && (
            <div className='line'>
              <p>APR</p>
              <p>{formatAmount(apr)}%</p>
            </div>
          )}
          <div className='line'>
            <p>{token0.symbol}</p>
            <p>{formatAmount(staked0) + ' (' + formatAmount(token0PooledPercent) + '%)'} </p>
          </div>
          <div className='line'>
            <p>{token1.symbol}</p>
            <p>{formatAmount(staked1) + ' (' + formatAmount(100 - token0PooledPercent, 2) + '%)'}</p>
          </div>
          <div className='line'>
            <p>Liquidity</p>
            <p>$ {formatAmount(stakedUsd)}</p>
          </div>
        </div>
        <div className='actions'>
          <StyledButton
            onClickHandler={() => {
              if (manual) {
                setWithDraw(true)
              }
              if (auto && status === 'UNSTAKED') {
                setStake(true)
              } else if (auto && status === 'STAKED') {
                setUnstake(true)
              }
            }}
            content={stakeBtnMsg}
            className={'w-1/2 py-2 px-2 text-sm font-thin tracking-wide'}
          />
          <StyledButton onClickHandler={() => onRedirect()} content='MANAGE' className={'w-1/2 py-2 px-2 text-sm font-thin tracking-wide'} />
        </div>
      </div>
      {withDraw && <RemoveModal isOpen={withDraw} setIsOpen={setWithDraw} position={position} />}
      {stake && <DepositModal isOpen={stake} setIsOpen={setStake} pair={position} />}
      {unstake && <DepositModal isOpen={unstake} stake={false} setIsOpen={setUnstake} pair={position} />}
    </>
  )
}

export default Pool
