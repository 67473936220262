import React from 'react'

const BridgeCard = ({ name, link, logo, description }) => {
  return (
    <a
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      className=' bg-[rgba(255,255,255,0.05)] backdrop-filter backdrop-blur-lg rounded-[20px] border border-[rgba(255,255,255,0.20)] p-6 hover:border-themeOrange transition-all duration-300 ease-in-out hover:shadow-lg flex flex-col justify-start h-[250px] w-full relative'
    >
      <div className='flex items-center mb-4'>
        <img src={logo} alt={`${name} Logo`} className='w-12 h-12 mr-4' />
        <h3 className='text-lg text-white font-semibold'>{name}</h3>
      </div>
      <p className='text-sm text-[#b8b6cb] mb-4'>{description}</p>
      <div className='absolute bottom-4 right-4 text-white'>
        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='currentColor' className='bi bi-arrow-up-right' viewBox='0 0 16 16'>
          <path fillRule='evenodd' d='M14 2.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-2.69L3.854 14.146a.5.5 0 1 1-.708-.708L12.793 4H10.5a.5.5 0 0 1 0-1h4z' />
        </svg>
      </div>
    </a>
  )
}

export default BridgeCard
