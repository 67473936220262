import React from 'react'
import 'react-rangeslider/lib/index.css'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'
import { useAlgebraClaim } from '../../../../../../hooks/v3/useAlgebra'

const CollectModal = ({ isOpen, setIsOpen, position, feeValue0, feeValue1 }) => {
  const logoURI0 = useCurrencyLogo(feeValue0?.currency)
  const logoURI1 = useCurrencyLogo(feeValue1?.currency)
  const { pending, onAlgebraClaim } = useAlgebraClaim()

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Collect Fees'} width={588}>
      <div className='flex flex-col items-start py-3 mt-5 sm:min-w-[450px] lg:max-w-[540px]'>
        <div className='flex w-full items-center justify-between'>
          <div className='flex items-center space-x-[5px]'>
            <img alt='' src={logoURI0 || '/images/tokens/UKNOWN.png'} className={'w-[22px] md:w-6'} />
            <span className='text-base leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>{feeValue0?.currency.symbol}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-[#DEDBF2] leading-5'>{feeValue0?.toSignificant()}</span>
          </div>
        </div>
        <div className='flex w-full items-center justify-between mt-2'>
          <div className='flex items-center space-x-[5px]'>
            <img alt='' src={logoURI1 || '/images/tokens/UKNOWN.png'} className={'w-[22px] md:w-6'} />
            <span className='text-base leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>{feeValue1?.currency.symbol}</span>
          </div>
          <div className='flex items-center space-x-2'>
            <span className='text-[#DEDBF2] leading-5'>{feeValue1?.toSignificant()}</span>
          </div>
        </div>
        <StyledButton
          pending={pending}
          onClickHandler={() => {
            onAlgebraClaim(position.tokenId, feeValue0, feeValue1)
            setIsOpen(false)
          }}
          content={'Claim'}
          className='w-full mt-[15px] py-2 text-sm md:text-lg px-[19px]'
        />
        <p className='mt-2 md:mt-1.5 text-[#DEDBF2] leading-5 text-sm italic font-light text-center'>
          Collecting fees will withdraw currently available fees for you.
        </p>
      </div>
    </Modal>
  )
}

export default CollectModal
