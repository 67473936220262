import React, { useState } from 'react'
import LinearGhostButton from '../../../../common/Buttons/linearGhostButton'
import GammaRemoveModal from './components/gammaRemoveModal'
import GammaAddModal from './components/gammaAddModal'
import { formatAmount } from '../../../../../utils/formatNumber'
import { useTokenPrice } from '../../../../../hooks/usePrices'
import DepositModal from '../../depositModal'

const GammaPosition = ({ id, setAutoLp, autoLp, position, noArrow = false }) => {
  const [addLiquidity, setIsLiquidity] = useState(false)
  const [unstake, setUnstake] = useState(false)
  const [withDraw, setIsWithDraw] = useState(false)
  const { token0, token1, account, type, strategist } = position
  const token0Price = useTokenPrice(token0.address)
  const token0PooledPercent = account.total0.times(token0Price).div(account.totalUsd).times(100).toFixed(2)
  const needsToUnstake = account?.lpBalance.isZero() && !account?.gaugeBalance.isZero()

  return (
    <>
      <div key={id} className={`w-full px-5 py-5 bg-white/5 md:min-w-[320px] rounded-xl`}>
        <div
          onClick={() => {
            autoLp === id ? setAutoLp(-1) : setAutoLp(id)
          }}
          className='flex justify-between md:justify-start items-center space-x-2 cursor-pointer w-full'
        >
          <div className='flex items-center justify-between space-x-2 w-full'>
            <div className='flex items-center space-x-2'>
              <div className='flex items-center'>
                <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0.logoURI} />
                <img alt='' className='w-6 lg:w-[30px] -ml-3' src={token1.logoURI} />
              </div>
              <div className='flex flex-col'>
                <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
                  {token0.symbol}/{token1.symbol}
                </p>
                <p className='text-[10px] lg:text-[14px] f-f-fg text-gray-400'>Managed by {strategist}</p>
              </div>
            </div>
            <div className='md:flex-row flex flex-col-reverse items-start md:items-center md:space-x-2'>
              <div className='bg-white/10 py-1 px-2 rounded-2xl w-full flex items-center flex-shrink-0'>
                <span className='text-[10px] md:text-sm fonts-medium text-white whitespace-nowrap'>{type} range</span>
              </div>
            </div>
          </div>
          {!noArrow && (
            <button className={`${autoLp === id ? 'rotate-180' : 'rotate-0'} w-3 md:w-auto`}>
              <img alt='' src='/images/svgs/dropdown.svg' />
            </button>
          )}
        </div>
        {autoLp === id ? (
          <div className='w-full mt-5'>
            <div className='flex items-center justify-between'>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-medium text-white'>Pooled Liquidity</span>
              <span className='text-sm md:text-base leading-4 md:leading-5 font-light  text-white'>{`$${formatAmount(account.totalUsd)} (${formatAmount(
                account.totalLp,
              )} LP)`}</span>
            </div>
            <div className='mt-3.5'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={token0.logoURI} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>{token0.symbol}</span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{formatAmount(account.total0)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-themeOrange/50 rounded-md'>
                    {formatAmount(token0PooledPercent)}%
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-2'>
                <div className='flex items-center space-x-[5px]'>
                  <img alt='' src={token1.logoURI} className={'w-6 md:w-7'} />
                  <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-medium'>{token1.symbol}</span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-[#DEDBF2] leading-5 font-light'>{formatAmount(account.total1)}</span>
                  <div className='py-1 px-2 text-sm md:text-[15px] leading-4 md:leading-[19px] text-white bg-themeOrange/50 rounded-md'>
                    {formatAmount(100 - token0PooledPercent)}%
                  </div>
                </div>
              </div>
            </div>

            <div className='flex items-center space-x-3 mt-4'>
              {/* 
              // TODO: Add liquidity to existing position
              {type !== 'YieldIQ' && (
                <LinearGhostButton
                  onClickHanlder={() => {
                    setIsLiquidity(true)
                  }}
                  smallCase={true}
                  title={'Add Liquidity'}
                />
              )} */}
              <LinearGhostButton
                onClickHanlder={() => {
                  if (!account.lpBalance.isZero()) {
                    setIsWithDraw(true)
                  } else {
                    setUnstake(true)
                  }
                }}
                smallCase={true}
                title={needsToUnstake ? 'UNSTAKE' : 'WITHDRAW'}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {addLiquidity && <GammaAddModal isOpen={addLiquidity} setIsOpen={setIsLiquidity} position={position} />}
      {unstake && <DepositModal isOpen={unstake} stake={false} setIsOpen={setUnstake} pair={position} />}
      {withDraw && <GammaRemoveModal isOpen={withDraw} setIsOpen={setIsWithDraw} position={position} />}
    </>
  )
}

export default GammaPosition
