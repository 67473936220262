import React, { useEffect, useState } from 'react'
import useWalletModal from '../../../../hooks/useWalletModal'
import StyledButton from '../../../common/Buttons/styledButton'
import './dist.scss'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { getAirdropClaim } from '../../../../utils/api'
import { formatAmount, BIG_ZERO, fromWei } from '../../../../utils/formatNumber'
import BigNumber from 'bignumber.js'
import Skeleton from '../../../common/Spinner/skeleton'
import { useAirdropClaim } from '../hooks/useAirdropClaim'
import { useHasClaimed } from '../hooks/useClaimTokens'
import { useTHEAsset } from '../../../../hooks/useGeneral'

const Distribution = ({ type }) => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const [loading, setLoading] = useState(true)
  const [amount, setAmount] = useState(BIG_ZERO)
  const [veShare, setveShare] = useState(BIG_ZERO)
  const [proof, setProof] = useState([])
  const { onClaim, pending } = useAirdropClaim(type)
  const { hasClaimed, setRefresh, refresh } = useHasClaimed(type)
  const lynxAsset = useTHEAsset()
  const disable = !account || pending || !amount.gt(0) || hasClaimed
  const price = lynxAsset?.price

  useEffect(() => {
    setLoading(true)
    const fetchAirdropClaim = async () => {
      const res = await getAirdropClaim(account, type)
      if (res) {
        setAmount(new BigNumber(res.amount))
        setveShare(new BigNumber(res.veShare))
        setProof(res.proof)
      }
      setLoading(false)
    }
    if (account) {
      fetchAirdropClaim()
    }
  }, [account])

  const handleClaim = async () => {
    await onClaim(amount, veShare, proof)
    setRefresh(!refresh)
  }

  const lynxTokens = amount?.minus(amount.times(veShare).div(1000))
  const veTokens = type === 'strategic' ? amount?.times(veShare).div(1000).div(6) : amount?.times(veShare).div(1000).div(3)
  const veAllo = fromWei(veTokens)
  const lynxAllo = fromWei(lynxTokens)
  const lynxUsd = price ? lynxAllo.times(price) : BIG_ZERO
  const veUsd = price ? veAllo.times(price) : BIG_ZERO

  return (
    <div className='dist'>
      <p className='title'>Token distribution</p>
      {loading ? (
        <Skeleton />
      ) : amount.gt(0) ? (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className={`row ${hasClaimed && 'line-through'}`}>
            {formatAmount(lynxAllo)} oLYNX TOKENS (${formatAmount(lynxUsd)})
          </p>
          {type === 'strategic' ? (
            <>
              <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(veAllo)} veLYNX locked for 1 month</p>
              <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(veAllo)} veLYNX locked for 2 months</p>
              <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(veAllo)} veLYNX locked for 3 months</p>
              <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(veAllo)} veLYNX locked for 4 months</p>
              <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(veAllo)} veLYNX locked for 5 months</p>
              <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(veAllo)} veLYNX locked for 6 months</p>
            </>
          ) : (
            <>
              <p className={`row ${hasClaimed && 'line-through'}`}>
                {formatAmount(veAllo)} veLYNX locked for 6 months (${formatAmount(veUsd)})
              </p>
              <p className={`row ${hasClaimed && 'line-through'}`}>
                {formatAmount(veAllo)} veLYNX locked for 12 months (${formatAmount(veUsd)})
              </p>
              <p className={`row ${hasClaimed && 'line-through'}`}>
                {formatAmount(veAllo)} veLYNX locked for 24 months (${formatAmount(veUsd)})
              </p>
            </>
          )}
        </div>
      ) : (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className='row'>No tokens to claim</p>{' '}
        </div>
      )}
      {account ? (
        <StyledButton
          disabled={disable}
          content={hasClaimed ? 'ALREADY CLAIMED' : 'CLAIM'}
          className='action-button'
          onClickHandler={() => {
            handleClaim()
          }}
        />
      ) : (
        <StyledButton
          onClickHandler={() => {
            openWalletModal()
          }}
          content={'CONNECT WALLET'}
          className='action-button'
        />
      )}
    </div>
  )
}

export default Distribution
