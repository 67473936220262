import React from 'react'
import { Link } from 'react-router-dom'
import V3AddLiquidity from '../../components/pages/liquidity/v3/addLiquidity'
import MyGammaPools from '../../components/pages/liquidity/v3/myGammaPools'
import MyAlgebraPools from '../../components/pages/liquidity/v3/myAlgebraPools'
import { useWeb3Wagmi } from '../../hooks/useWeb3'
import useWalletModal from '../../hooks/useWalletModal'
import StyledButton from '../../components/common/Buttons/styledButton'

const ManageLiquidity = () => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()

  return (
    <div className='w-full max-w-[1200px] mx-auto pt-[160px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
      <div className='flex justify-center'>
        <div className='flex h-11'>
          <Link
            to={'/liquidity/manage'}
            className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-[#A2A0B7] font-normal border-[#555367] border-l border-t border-b rounded-l-[5px] -mr-[3px] -ml-px`}
          >
            CLASSIC
          </Link>
          <Link
            to={'/liquidity/fusion'}
            className={`w-[100px] h-full flex flex-col items-center justify-center cursor-pointer text-white font-semibold border-themeOrange border rounded-[5px] -mr-px popup-gradientbg`}
          >
            FUSION
          </Link>
        </div>
      </div>
      {account ? (
        <div className='lg:flex items-start lg:space-x-6 mt-6'>
          <V3AddLiquidity />
          <div className='lg:max-w-[600px] w-full space-y-4 mt-5 lg:mt-0'>
            <MyGammaPools />
            <MyAlgebraPools />
          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center my-4 mx-auto'>
          <p className='text-white text-sm lg:text-base my-3'>Please connect your wallet to add, remove or manage liquidity.</p>
          <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='py-[8px] px-[16px] text-sm' />
        </div>
      )}
    </div>
  )
}

export default ManageLiquidity
