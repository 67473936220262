import React from 'react'

const InfoBanner = ({ logo, title, text, url, setBanner }) => {
  return (
    <div className='flex justify-between items-center w-full bg-themeOrangeLight/30 border border-themeOrange px-4 py-2 my-4 rounded-xl'>
      <div className='flex items-center'>
        <div className='flex-shrink-0 mr-4'>
          <img alt='' src={logo} width={32} />
        </div>
        <div className='flex flex-col'>
          <p
            onClick={() => {
              url && window.open(url, '_blank')
            }}
            className={`text-white text-sm font-semibold cursor-default ${url && 'hover:underline hover:cursor-pointer'}`}
          >
            {title}
          </p>
          {text && <p className='text-white text-sm'>{text}</p>}
        </div>
      </div>
      <div className='flex justify-end items-center flex-shrink-0 ml-auto'>
        <img className='hover:cursor-pointer' alt='close-button' src='/images/common/close-button2.svg' width={16} onClick={() => setBanner(false)} />
      </div>
    </div>
  )
}

export default InfoBanner
