import React, { useState, useContext, useEffect, useMemo } from 'react'
import { veTHEsContext } from '../../../context/veTHEsConetext'
import VeTHEPopup from '../VeTHEPopup'
import { useGetAllVeRewards } from '../../../hooks/useRewards'

const VeTHESelect = ({ className, isSelected, setIsSelected, small }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [init, setIsInit] = useState(false)
  const { veTHEs } = useContext(veTHEsContext)
  const { rewards } = useGetAllVeRewards()

  const filteredData = useMemo(() => {
    return veTHEs?.filter((item, index) => item.amount.gt(0) || item.rebase_amount.gt(0) || rewards[index]?.length > 0).sort((a, b) => b.amount - a.amount)
  }, [veTHEs, rewards])

  useEffect(() => {
    if (filteredData.length > 0) {
      if (!init) {
        setIsInit(true)
        setIsSelected(filteredData[0])
      }
    } else {
      setIsSelected(null)
    }
  }, [filteredData, init])

  return (
    <div className={`${className ? className : ''}`}>
      <div className={`w-full flex items-center h-[42px] ${!small && 'md:h-[52px]'} rounded-[12px] border bg-white/5 bg-opacity-80 px-4`}>
        <div className={`text-white text-sm ${!small && 'md:text-base'} font-medium whitespace-nowrap pr-3 border-r border-white f-f-fg`}>SELECT veLYNX:</div>
        <div
          className={`pl-3 w-full relative focus:outline-none py-2 bg-transparent rounded-[3px] text-white flex items-center justify-between cursor-pointer`}
          onClick={() => {
            if (filteredData.length > 0) {
              setIsOpen(true)
            }
          }}
        >
          {isSelected ? <span>{`#${isSelected.id}`}</span> : <div className='text-dimGray text-md'>Not found</div>}
          <img
            className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
            alt=''
            src='/images/swap/dropdown-arrow.png'
          />
        </div>
      </div>
      <VeTHEPopup popup={isOpen} setPopup={setIsOpen} setSelectedVeTHE={setIsSelected} veTHEs={filteredData} />
    </div>
  )
}

export default VeTHESelect
