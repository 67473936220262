import { Web3Provider } from '@ethersproject/providers'
import { useWeb3Wagmi } from './useWeb3Context'

// TODO: Check this out and how it works with WAGMI
// (Seems to be working as is)
export function useActiveWeb3React() {
  const context = useWeb3Wagmi()
  const library = context.active ? new Web3Provider(context.library || window.ethereum, 'any') : undefined
  return {
    ...context,
    library,
  }
}
