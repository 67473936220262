import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CommonHollowModal from '../../components/common/CommonHollowModal'
import ManageTab from '../../components/pages/lock/manage'
import MergeTab from '../../components/pages/lock/merge'
import SplitTab from '../../components/pages/lock/split'
import TransferTab from '../../components/pages/lock/transfer'
import DelegateTab from '../../components/pages/lock/delegate'
import DropDown from '../../components/common/Dropdown'

const data = ['MANAGE', 'TRANSFER', 'MERGE', 'SPLIT', 'DELEGATE']

const ManageModal = ({ isOpen, setIsOpen, selected, theAsset }) => {
  const [tab, setTab] = useState(data[0])
  const { final } = useSelector((state) => state.transactions)

  useEffect(() => {
    if (['Split Successful', 'Merge Successful', 'Transfer Successful', 'Increase Successful', 'Unlock Successful'].includes(final)) {
      setIsOpen(false)
    }
  }, [final, setIsOpen])

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage veLYNX #${selected.id}`}>
      <DropDown fullWidth className={'mt-4 md:mt-[29px]'} options={data} selected={tab} setSelected={(typeSelected) => setTab(typeSelected)} capitalized />
      {tab === data[0] && <ManageTab selected={selected} theAsset={theAsset} onClose={() => setIsOpen(false)} />}
      {tab === data[1] && <TransferTab selected={selected} />}
      {tab === data[2] && <MergeTab selected={selected} />}
      {tab === data[3] && <SplitTab selected={selected} />}
      {tab === data[4] && <DelegateTab selected={selected} />}
    </CommonHollowModal>
  )
}

export default ManageModal
