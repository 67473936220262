import React from 'react'
import styled from 'styled-components'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { customNotify } from '../../../utils/notify'

const Button = styled.button`
  background: ${({ disabled, bg }) => (disabled ? 'unset' : bg ?? '#DF832F')};
`

export const TransactButton = ({ content, className, disabled, bg, onClickHandler = null, pending = false }) => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, authenticationStatus, mounted, openChainModal }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated')
        const wrongNetwork = chain?.unsupported || chain?.id.toString() !== process.env.REACT_APP_CHAIN_ID
        const correct = connected && !wrongNetwork && !disabled && !pending && onClickHandler
        const errorMsg = !chain ? 'NOT CONNECTED' : wrongNetwork ? 'WRONG NETWORK' : 'SOMETHING WENT WRONG'

        return (
          <Button
            bg={bg}
            onClick={(e) => {
              if (wrongNetwork) {
                return openChainModal()
              }
              if (correct) {
                onClickHandler(e.target.value)
              } else {
                customNotify(errorMsg, 'warn')
              }
            }}
            type='button'
            className={`f-f-fg flex items-center justify-center text-white font-medium tracking-[1.44px] rounded-lg ${className ? ' ' + className : ''}${
              disabled ? ' !bg-gray-500/80 !text-gray-300 cursor-not-allowed' : ''
            } `}
            disabled={disabled}
          >
            {pending ? 'PENDING...' : wrongNetwork ? errorMsg : content}
          </Button>
        )
      }}
    </ConnectButton.Custom>
  )
}
