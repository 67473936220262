import { useCallback, useState } from 'react'
import useWeb3 from '../../../../hooks/useWeb3'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { completeTransaction, openTransaction, updateTransaction } from '../../../../state/transactions/actions'
import { TransactionType } from '../../../../config/constants'
import { sendContract, getAllowance } from '../../../../utils/api'
import { getLynxClaimContract, getERC20Contract } from '../../../../utils/contractHelpers'
import { getLynxClaimTokenAddress } from '../../../../utils/addressHelpers'
import { fromWei, MAX_UINT256 } from '../../../../utils/formatNumber'
import { parseUnits } from 'ethers/lib/utils'

export const useLynxClaim = (account) => {
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const [pending, setPending] = useState(false)

  const handleClaim = useCallback(
    async (amount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim earnings`,
          transactions: {
            [approveuuid]: {
              desc: `Approve LYNXClaim`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      let isApproved = true
      const lynxClaimContract = getLynxClaimContract(web3)
      const tokenContract = getERC20Contract(web3, getLynxClaimTokenAddress())
      setPending(true)
      const allowance = await getAllowance(tokenContract, lynxClaimContract._address, account)
      if (fromWei(allowance, 18).lt(amount)) {
        isApproved = false
        try {
          // TODO: Reconsider MAX approve
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [lynxClaimContract._address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      try {
        const params = [parseUnits(amount.toString(), 18)]
        await sendContract(dispatch, key, harvestuuid, lynxClaimContract, 'claim', params, account)
      } catch (err) {
        console.log('claim error :>> ', err)
        setPending(false)
        return
      }
      if (isApproved) {
        dispatch(
          completeTransaction({
            key,
            final: 'Rewards Claimed',
          }),
        )
      }
      setPending(false)
    },
    [account, web3],
  )

  return { onClaim: handleClaim, pending }
}
