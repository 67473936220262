import { useContext, useMemo } from 'react'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'

export const useCurrencyLogo = (currency) => {
  const assets = useContext(BaseAssetsConetext)
  return useMemo(() => {
    return currency && assets
      ? assets.find((asset) => asset.address.toLowerCase() === (currency.address ? currency.address.toLowerCase() : 'eth')).logoURI
      : null
  }, [assets, currency])
}

export const useLogoFromAddress = (address) => {
  const assets = useContext(BaseAssetsConetext)
  return useMemo(() => {
    return address && assets
      ? assets.find((asset) => asset.address === address.toLowerCase())?.logoURI ?? '/images/tokens/UKNOWN.png'
      : '/images/tokens/UKNOWN.png'
  }, [assets, address])
}
