import React, { useState, useMemo, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import Pagination from '../../common/Pagination'
import Max from '../../common/Buttons/max'
import Sticky from 'react-stickynode'
import TransparentButton from '../../common/Buttons/transparentButton'
import { NumberOfRows, PoolTypes } from '../../../config/constants'
import { formatAmount, getLPSymbol } from '../../../utils/formatNumber'
import useWalletModal from '../../../hooks/useWalletModal'
import NoFound from '../../common/NoFound'
import { useVote } from '../../../hooks/useVote'
import { customNotify } from '../../../utils/notify'
import { usePoke, useReset } from '../../../hooks/useLock'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { TransactButton } from '../../common/Buttons/transactButton'

const memeGauges = [
  '0x90fb440828f67B702144c1739c82dA58dEaCB023',
  '0xD0300B59794Ab0f591CFb066CfBA8fa615E53696',
  '0xAd4486c7187d9ABCdb05bA2A4E585ac8156628Bf',
  '0xad2d0F5284751be45f03Cf372976c1e1dB9583B8',
  '0x8b521fC7D60f115D1B7c67C6C23380E550485095',
  '0x903964EA6BD2F708328e085dcd71a9e19b39B63D',
].map((gauge) => gauge.toLowerCase())

const MAX_VOTING = 100

const Item = ({ usd, content, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return !usd.isZero() ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd)}</p>
        <button className={`${arrowReverse === `${type}-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[180px] !text-white !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33]'
        id={`${type}-${idx}`}
        place='right'
        effect='solid'
        delayShow={200}
      >
        {content}
      </ReactTooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>$0</div>
  )
}

const Row = ({ idx, pool, percent, setPercent, totalPercent, accountInfo }) => {
  const { openWalletModal } = useWalletModal()
  const { account } = useWeb3Wagmi()

  const expectedRewards = useMemo(() => {
    const totalVotes = pool.gauge.weight.toNumber()
    const userVotes = pool.votes.weight.toNumber()
    const totalRewards = pool.gauge.bribeUsd.toNumber()

    if (totalVotes === 0) return 0

    return (userVotes / totalVotes) * totalRewards
  }, [pool])

  const [arrowReverse, setArrowReverse] = useState()

  return (
    <div key={idx} className='table-row-wrapper'>
      <div className='flex w-full  lg:w-[25%] items-center  space-x-3'>
        <div className='flex items-center  -space-x-2'>
          <img className='relative z-10' alt='' src={pool.token0.logoURI} width={30} height={30} />
          <img className='relative z-[5]' alt='' src={pool.token1.logoURI} width={30} height={30} />
        </div>
        <div className=''>
          <p className='text-base xl:text-[19px] leading-[30px] font-medium'>{`${getLPSymbol(pool)}`}</p>
          <p className='tracking-[0.78px] text-[13px] leading-none'>{pool.title}</p>
        </div>
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Voting APR</p>
        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(pool.gauge.voteApr, true)}%</div>
      </div>
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[18%] justify-center'>
        <div className='text-sm xl:text-base flex items-center space-x-1.5'>
          <div className='flex flex-col items-start justify-center'>
            <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Votes</p>
            <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(pool.gauge.weight)}</div>
            <p className='leading-[22px] text-dimGray text-[15px]'>{formatAmount(pool.gauge.weightPercent, false, 2)}%</p>
          </div>
        </div>
      </div>
      {/* second row */}
      <div className='flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Rewards</p>
        <Item
          type={'rewards'}
          usd={pool.gauge.bribeUsd}
          content={
            <>
              {pool.gauge.bribes && pool.gauge.bribes.bribe && (
                <>
                  <div className='text-themeOrange'>Bribes</div>
                  {pool.gauge.bribes.bribe.map((bribe, idx) => {
                    return (
                      <p key={`bribe-${idx}`}>
                        {formatAmount(bribe.amount)} {bribe.symbol}
                      </p>
                    )
                  })}
                </>
              )}
              {pool.gauge.bribes && pool.gauge.bribes.fee && (
                <>
                  <div className='text-themeOrange'>Projected Fees</div>
                  {pool.gauge.bribes.fee.map((fee, idx) => {
                    return (
                      <p key={`fee-${idx}`}>
                        {formatAmount(fee.amount)} {fee.symbol}
                      </p>
                    )
                  })}
                </>
              )}
            </>
          }
          idx={idx}
        />
      </div>
      <div className='flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[18%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Your Vote</p>
        <div className='flex items-center'>
          <div className='flex flex-col'>
            <p>{formatAmount(pool.votes.weight)}</p>
            <div className='flex items-center'>
              <p className='leading-[22px] text-dimGray text-[15px]'>{formatAmount(pool.votes.weightPercent, false, 2)}%</p>
              {accountInfo?.votedCurrentEpoch && pool.votes.weight.gt(0) && (
                <button
                  className={`${arrowReverse === `votes-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out ml-2`}
                  data-tip
                  data-for={`votes-${idx}`}
                  onMouseEnter={() => setArrowReverse(`votes-${idx}`)}
                  onMouseLeave={() => setArrowReverse(null)}
                >
                  <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
                </button>
              )}
            </div>
          </div>
          {accountInfo?.votedCurrentEpoch && pool.votes.weight.gt(0) && (
            <ReactTooltip
              className='max-w-[180px] !text-white !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33]'
              id={`votes-${idx}`}
              place='right'
              effect='solid'
              delayShow={200}
            >
              <div>Expected Rewards:</div>
              <div>${formatAmount(expectedRewards)}</div>
            </ReactTooltip>
          )}
        </div>
      </div>
      <div className='w-full lg:w-[24%] mt-3.5 lg:mt-0'>
        {!account ? (
          <div className='w-full  flex  items-center lg:justify-end'>
            <TransparentButton
              onClickHandler={openWalletModal}
              content={'Connect Wallet'}
              className='h-10 px-[26px] text-white flex lg:max-w-[173px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px] w-full'
            />
          </div>
        ) : (
          <div className='flex flex-col lg:items-end justify-end w-full'>
            <div className='relative w-full lg:w-auto'>
              <input
                className='w-full lg:max-w-[202.89px] h-[52px] bg-white/5 rounded-md z-[10] text-white text-lg pl-5 pr-2 placeholder-gray focus:!placeholder-transparent block'
                placeholder={'Enter Vote'}
                onWheel={(e) => e.target.blur()} // Remove focus from component so scrolling doesn't affect input
                type='number'
                min={0}
                step={1}
                value={percent[pool.address] || ''}
                onChange={(e) => {
                  const val = isNaN(Number(percent[pool.address])) ? 0 : Number(percent[pool.address])
                  let newVal = isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Number(e.target.value)
                  newVal = Math.min(newVal, MAX_VOTING - totalPercent + val) // Ensure it doesn't exceed 100%
                  newVal = formatAmount(newVal, false, 2) // Ensure two decimal places
                  setPercent({
                    ...percent,
                    [pool.address]: !e.target.value ? '' : newVal,
                  })
                }}
              />

              {!!percent[pool.address] && <p className='text-lg text-white absolute top-3 right-20 z-10 '>%</p>}
              <Max
                className={'absolute z-10 right-2 top-2'}
                onClickHanlder={() => {
                  const val = isNaN(Number(percent[pool.address])) ? 0 : Number(percent[pool.address])
                  let maxValue = MAX_VOTING - totalPercent + val
                  maxValue = formatAmount(Math.min(maxValue, MAX_VOTING), false, 2) // Ensure it doesn't exceed 100% and has two decimal places
                  setPercent({
                    ...percent,
                    [pool.address]: maxValue === 0 ? '' : maxValue,
                  })
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const Table = ({ pools, sort, setSort, sortOptions, filter, searchText, isVoted, memeOnly, accountInfo, percent, setPercent, totalPercent }) => {
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)
  const { onVote, pending } = useVote()
  const { onReset, pending: resetPending } = useReset()
  const { onPoke, pending: pokePending } = usePoke()
  const { account } = useWeb3Wagmi()

  const filteredPools = useMemo(() => {
    const result = pools.filter((pool) => {
      return (isVoted && !pool.votes.weight.isZero()) || !isVoted
    })
    if (memeOnly) {
      return result.filter((pool) => {
        return memeGauges?.includes(pool.gauge.address)
      })
    }
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')
        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [pools, filter, searchText, isVoted, memeOnly])

  const sortedPools = useMemo(() => {
    return filteredPools.sort((a, b) => {
      let res
      switch (sort.value) {
        case sortOptions[0].value:
          res = a.gauge.voteApr
            .minus(b.gauge.voteApr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[1].value:
          res = a.gauge.weight
            .minus(b.gauge.weight)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[2].value:
          res = a.gauge.bribeUsd
            .minus(b.gauge.bribeUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case sortOptions[3].value:
          res = a.votes.weight
            .minus(b.votes.weight)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [filteredPools, sort])

  const pageCount = useMemo(() => {
    return Math.ceil(sortedPools.length / pageSize)
  }, [sortedPools, pageSize])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize, filter, isVoted, searchText])

  const tolerance = 0.01
  const lowerBound = MAX_VOTING - tolerance
  const upperBound = MAX_VOTING + tolerance

  const errorMsg = useMemo(() => {
    if (!accountInfo) {
      return 'Not connected'
    }
    if (accountInfo.epochVotes.isZero()) {
      return 'Voting power is zero'
    }
    if (totalPercent < lowerBound || totalPercent > upperBound) {
      return 'Total votes should be approximately 100%'
    }
    return null
  }, [totalPercent, accountInfo])

  return (
    <>
      <div className='w-full lg:mt-2 xl:mt-0'>
        {sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).length > 0 ? (
          <div className='w-full'>
            <Sticky
              enabled={true}
              innerActiveClass={'bg-themeOrangeLight'}
              top={90}
              activeClass={''}
              innerClass={'px-6 lg:flex justify-between hidden z-[100] py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'}
              className={`z-[100]`}
            >
              <div className='w-[25%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
              {sortOptions.map((option, index) => (
                <div className={`${index === 0 ? 'w-[14%]' : 'w-[18%]'} font-medium text-[17px] xl:text-[18px] text-white f-f-fg`} key={`header-${index}`}>
                  <div
                    onClick={() => {
                      setSort({
                        ...option,
                        isDesc: sort.value === option.value ? !sort.isDesc : true,
                      })
                    }}
                    className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                  >
                    {sort.value === option.value && (
                      <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                        <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                      </button>
                    )}
                    <p className='flex items-center'>{option.label}</p>
                  </div>
                </div>
              ))}
              <div className='w-[24%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
            </Sticky>
            <div className='flex flex-col gap-3'>
              {sortedPools.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((pool, idx) => {
                return <Row pool={pool} idx={idx} key={idx} percent={percent} setPercent={setPercent} totalPercent={totalPercent} accountInfo={accountInfo} />
              })}
            </div>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
              total={sortedPools.length}
            />
          </div>
        ) : (
          <NoFound title='No pools found' />
        )}
      </div>
      {account && (
        <div
          className={`bottom-0 md:bottom-4 transition-all duration-300 ease-in-out md:flex items-center  fixed md:max-w-[629px] mx-auto w-full  left-0 right-0 z-[100] border-themeOrange border-t md:border-l md:border-r md:border-b md md:rounded-[5px] bg-[#3d3d3d] px-5 py-3 md:py-3.5`}
        >
          <p className=''>
            <span className='text-white text-base md:text-[16px] f-f-fg '>Voting Power Used:</span>{' '}
            <span
              className={`${
                accountInfo && (accountInfo.votedCurrentEpoch || accountInfo.epochVotes.isZero()) ? `text-[#2CBA52]` : 'text-error'
              } text-lg md:text-1xl font-semibold`}
            >
              {accountInfo && (accountInfo.votedCurrentEpoch || accountInfo.epochVotes.isZero()) ? 'Yes' : 'No'}
            </span>
          </p>
          <div className='flex flex-wrap items-center mt-2 md:mt-0 w-full justify-between md:w-auto'>
            <TransactButton
              disabled={pending}
              pending={pending}
              onClickHandler={() => {
                if (errorMsg) {
                  customNotify(errorMsg, 'warn')
                  return
                }
                onVote(percent)
              }}
              content={'CAST VOTES'}
              className={'w-full md:w-auto text-sm md:text-[17px] px-[32px] flex-col h-[42px] mb-2 md:mb-0 mr-4 md:ml-3'}
            />
            <button
              className='text-themeOrange mr-4'
              disabled={!accountInfo || pokePending || accountInfo?.votes?.length === 0}
              onClick={() => {
                if (accountInfo?.votes?.length > 0) {
                  onPoke()
                }
              }}
            >
              Recast Votes
            </button>
            <button
              className='text-themeOrange'
              disabled={!accountInfo || !accountInfo.voted || resetPending}
              onClick={() => {
                if (accountInfo.voted) {
                  onReset()
                }
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Table
