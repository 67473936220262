import web3 from './web3'
import { fromWei } from './formatNumber'
import { veTHEAbi } from '../config/abi'
import { getVeTHEAddress } from './addressHelpers'

export const fetchTotalVotingPower = async (timestamp) => {
  const veTHEAddress = getVeTHEAddress()
  const veTHEContract = new web3.eth.Contract(veTHEAbi, veTHEAddress)

  const totalVotingPower = await veTHEContract.methods.getPastTotalSupply(timestamp).call()

  return {
    totalVotingPower: fromWei(totalVotingPower).toString(),
  }
}
