import React, { useCallback, useState } from 'react'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import Modal from '../../../../../common/Modal'
import StyledButton from '../../../../../common/Buttons/styledButton'
import { useDerivedV3BurnInfo } from '../../../../../../hooks/v3/useDerivedV3BurnInfo'
import useDebounce from '../../../../../../hooks/useDebounce'
import { useAlgebraRemove } from '../../../../../../hooks/v3/useAlgebra'
import { customNotify } from '../../../../../../utils/notify'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'

const slippage = 0.5
const deadline = 1200

const percentArray = [25, 50, 75, 100]
const RemoveModal = ({ isOpen, setIsOpen, position }) => {
  const [percent, setPercent] = useState(0)
  const debouncedPercent = useDebounce(percent)
  const { tokenId, liquidity } = position
  const derivedInfo = useDerivedV3BurnInfo(position, debouncedPercent)
  const { pending, onAlgebraRemove, onAlgebraRemoveAndBurn, burnPending } = useAlgebraRemove()
  const { liquidityValue0, liquidityValue1, feeValue0, feeValue1, outOfRange } = derivedInfo
  const logoURIA = useCurrencyLogo(liquidityValue0.currency)
  const logoURIB = useCurrencyLogo(liquidityValue1.currency)

  const onRemove = useCallback(() => {
    if (debouncedPercent > 0) {
      onAlgebraRemove(tokenId, derivedInfo, slippage, deadline)
    } else {
      customNotify('Invalid Amount', 'warn')
    }
  }, [tokenId, derivedInfo, debouncedPercent])

  const onRemoveAndBurn = useCallback(() => {
    if (debouncedPercent === 100) {
      onAlgebraRemoveAndBurn(tokenId, derivedInfo, slippage, deadline)
    } else {
      customNotify('Invalid Amount', 'warn')
    }
  }, [tokenId, derivedInfo, debouncedPercent])

  const removed = liquidity?.eq(0)

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Withdraw Liquidity'} width={548}>
      <div className='p-4 mt-[13px]'>
        <div className='flex items-center md:items-center justify-between'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              <img alt='' className='w-6 lg:w-[30px] relative shadow' src={logoURIA || '/images/tokens/UKNOWN.png'} />
              <img alt='' className='w-6 lg:w-[30px] -ml-3' src={logoURIB || '/images/tokens/UKNOWN.png'} />
            </div>
            <p className='text-sm md:text-xl f-f-fg font-semibold text-white'>
              {liquidityValue0.currency.symbol}/{liquidityValue1.currency.symbol}
            </p>
          </div>
          <div className='bg-white bg-opacity-[0.09] py-1 pl-3 rounded-[13px] flex items-center space-x-[5px] pr-4 flex-shrink-0'>
            <div className={`${removed ? 'bg-red-700' : !outOfRange ? 'bg-[#55A361]' : 'bg-warn'} w-2 h-2 rounded-full`} />
            <span className='text-xs md:text-base fonts-medium text-white whitespace-nowrap'>
              {removed ? 'Closed' : !outOfRange ? 'In range' : 'Out of range'}
            </span>
          </div>
        </div>
        <div className='mt-3 flex flex-wrap items-center justify-between'>
          <span className='text-[#E0DEF0] text-sm md:text-base leading-6'>Amount</span>
          <div className='flex items-center space-x-2.5'>
            {percentArray.map((percent, index) => {
              return (
                <div
                  className={`flex items-center justify-center bg-white/10 rounded-md text-white text-xs md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                  onClick={() => setPercent(percent)}
                  key={`percent-${index}`}
                >
                  {percent}%
                </div>
              )
            })}
          </div>
        </div>
        <div className='w-full py-2 md:py-3 mt-1 md:mt-2'>
          <div className='text-base md:text-xl leading-5 md:leading-[29px] text-white'>{percent}%</div>
          <Slider
            min={0}
            max={100}
            className='!mt-3 mb-0'
            value={percent}
            onChange={(value) => {
              setPercent(value)
            }}
          />
        </div>
        <div className='mt-3 w-full'>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px]'>
            <span>Pooled {liquidityValue0?.currency.symbol}</span>
            <span>{liquidityValue0?.toSignificant()}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <span>Pooled {liquidityValue1?.currency.symbol}</span>
            <span>{liquidityValue1?.toSignificant()}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <span>{feeValue0?.currency.symbol} Fees Earned:</span>
            <span>{feeValue0?.toSignificant()}</span>
          </div>
          <div className='flex items-center justify-between text-white leading-4 md:leading-5 md:text-base text-[13px] mt-2 md:mt-3'>
            <span>{feeValue1?.currency.symbol} Fees Earned:</span>
            <span>{feeValue1?.toSignificant()}</span>
          </div>
        </div>

        <StyledButton
          disabled={pending}
          pending={pending}
          onClickHandler={onRemove}
          content={'WITHDRAW'}
          className='mt-4 py-[13px]  md:py-[14px] text-sm md:text-lg px-[19px] w-full'
        />

        <StyledButton
          disabled={debouncedPercent < 100 || burnPending}
          pending={burnPending}
          onClickHandler={onRemoveAndBurn}
          content={'WITHDRAW AND BURN'}
          className='mt-4 py-[13px] md:py-[14px] text-sm md:text-lg px-[19px] w-full'
        />
      </div>
    </Modal>
  )
}

export default RemoveModal
