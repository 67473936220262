import React from 'react'
import Data from '../../components/pages/ido/data/data'
import HowTo from '../../components/pages/ido/howTo/howTo'
import Faq from '../../components/pages/ido/faq/faq'

const Index = () => {
  return (
    <div className='w-full pt-40 pb-10 xl:pb-0 2xl:pb-[32px] px-5 xl:px-0 '>
      <div className='max-w-[1104px] mx-auto w-full bg-white/10 rounded-[10px] border border-[#ffffff33]'>
        <div className='w-full rounded-t-[10px] max-h-[200px]'>
          <img alt='banner' className='rounded-t-[10px]' src='/images/ido/ido-header.png'></img>
        </div>
        <div className='flex flex-col w-full px-4 md:px-8 xl:px-32 pb-4 md:pb-8 xl:pb-16'>
          <div className=' mx-auto py-6'>
            <p className='font-semibold text-slate-100 text-4xl md:text-5xl tracking-tight'>LYNX IDO</p>
          </div>
          <Data />
        </div>
      </div>
      <HowTo />
      <Faq />
    </div>
  )
}

export default Index
