import { createAction } from '@reduxjs/toolkit'

export const updateBlockNumber = createAction('application/updateBlockNumber')

export const addBookMarkToken = createAction('application/addBookMarkedToken')

export const removeBookmarkToken = createAction('application/removeBookMarkedToken')

export const updateBookmarkTokens = createAction('application/updateBookMarkedTokens')

export const addBookMarkPair = createAction('application/addBookMarkPair')

export const removeBookmarkPair = createAction('application/removeBookmarkPair')

export const updateBookmarkPairs = createAction('application/updateBookmarkPairs')

export const addImportedToken = createAction('application/addImportedToken')

export const removeImportedToken = createAction('application/removeImportedToken')

export const clearImportedToken = createAction('application/clearImportedToken')

export const updateSwapType = createAction('application/updateSwapType')

export const updateFromToken = createAction('application/updateFrom')

export const updateToToken = createAction('application/updateTo')
