import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { TransactionType } from '../config/constants'
import { v4 as uuidv4 } from 'uuid'
import { getAllowance, sendContract } from '../utils/api'
import { getERC20Contract, getIchiVaultContract, getPreMiningContract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { fromWei, MAX_UINT256, toWei } from '../utils/formatNumber'
import { useV3Voter } from './useContract'
import { getAddress } from '../utils/addressHelpers'
import { BigNumber } from 'ethers'

const useStake = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const onStake = async (farm, amount, inStakeToken = false) => {
    if (farm.type === 'Ichi') return handleStakeIchi(farm, amount, inStakeToken)
    return handleStake(farm, amount)
  }

  const handleStake = useCallback(
    async (farm, amount) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Stake ${farm.stakeToken.symbol} in the farm`,
          transactions: {
            [approveuuid]: {
              desc: `Approve ${farm.stakeToken.symbol}`,
              status: TransactionType.WAITING,
              hash: null,
            },
            [stakeuuid]: {
              desc: `Stake tokens in the farm`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      let isApproved = true
      const tokenContract = getERC20Contract(web3, farm.stakeToken.address)
      const allowance = await getAllowance(tokenContract, farm.address, account)
      if (fromWei(allowance, farm.stakeToken.decimals).lt(amount)) {
        isApproved = false
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [farm.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      }
      if (isApproved) {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [farm.pid, toWei(amount, farm.stakeToken.decimals).toFixed(0)]
      const gaugeContract = getPreMiningContract(web3, farm.address)
      try {
        await sendContract(dispatch, key, stakeuuid, gaugeContract, 'deposit', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Staked',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  const handleStakeIchi = useCallback(
    async (farm, amount, inStakeToken = false) => {
      const key = uuidv4()
      const approveuuid = uuidv4()
      const stakeuuid = uuidv4()
      setPending(true)
      const tokenContract = getERC20Contract(web3, farm.stakeToken.address)
      if (!inStakeToken) {
        const approveuuid0 = uuidv4()
        const deposituuid = uuidv4()
        dispatch(
          openTransaction({
            key,
            title: `Stake ${farm.stakeToken.symbol} in the farm`,
            transactions: {
              [approveuuid0]: {
                desc: `Approve ${farm.principalToken.symbol}`,
                status: TransactionType.WAITING,
                hash: null,
              },
              [deposituuid]: {
                desc: `Deposit ${farm.principalToken.symbol} and get LP`,
                status: TransactionType.START,
                hash: null,
              },
              [approveuuid]: {
                desc: `Approve ${farm.stakeToken.symbol}`,
                status: TransactionType.START,
                hash: null,
              },
              [stakeuuid]: {
                desc: `Stake tokens in the farm`,
                status: TransactionType.START,
                hash: null,
              },
            },
          }),
        )
        const principalContract = getERC20Contract(web3, getAddress(farm.principalToken.address))
        const principalAllowance = await getAllowance(principalContract, farm.stakeToken.address, account)
        if (fromWei(principalAllowance, farm.principalToken.decimals).lt(amount)) {
          try {
            await sendContract(dispatch, key, approveuuid0, principalContract, 'approve', [farm.stakeToken.address, MAX_UINT256], account)
          } catch (err) {
            console.log('approve error :>> ', err)
            setPending(false)
            return
          }
        } else {
          dispatch(
            updateTransaction({
              key,
              uuid: approveuuid0,
              status: TransactionType.SUCCESS,
            }),
          )
        }

        const vaultContract = getIchiVaultContract(web3, farm.stakeToken.address)
        const pos = farm.principalToken.symbol.toLowerCase() === farm.token0.symbol.toLowerCase() ? 0 : 1
        const params0 = []
        params0[pos] = toWei(amount, farm.principalToken.decimals).toFixed(0)
        params0[2] = account
        params0[pos === 0 ? 1 : 0] = '0'
        try {
          await sendContract(dispatch, key, deposituuid, vaultContract, 'deposit', params0, account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
        amount = await tokenContract.methods.balanceOf(account).call()
      } else {
        dispatch(
          openTransaction({
            key,
            title: `Stake ${farm.stakeToken.symbol} in the farm`,
            transactions: {
              [approveuuid]: {
                desc: `Approve ${farm.stakeToken.symbol}`,
                status: TransactionType.START,
                hash: null,
              },
              [stakeuuid]: {
                desc: `Stake tokens in the farm`,
                status: TransactionType.START,
                hash: null,
              },
            },
          }),
        )
        amount = toWei(amount, farm.stakeToken.decimals).toFixed(0)
      }
      const allowance = await getAllowance(tokenContract, farm.address, account)
      if (BigNumber.from(allowance).lt(amount)) {
        try {
          await sendContract(dispatch, key, approveuuid, tokenContract, 'approve', [farm.address, MAX_UINT256], account)
        } catch (err) {
          console.log('approve error :>> ', err)
          setPending(false)
          return
        }
      } else {
        dispatch(
          updateTransaction({
            key,
            uuid: approveuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }
      const params = [farm.pid, amount]
      const gaugeContract = getPreMiningContract(web3, farm.address)
      try {
        await sendContract(dispatch, key, stakeuuid, gaugeContract, 'deposit', params, account)
      } catch (err) {
        console.log('stake error :>> ', err)
        setPending(false)
        return
      }
      dispatch(
        completeTransaction({
          key,
          final: 'Tokens Staked',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onStake, handleStake, handleStakeIchi, pending }
}

const useUnstake = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const onUnstake = async (farm, amount) => {
    if (farm.type === 'Ichi') return handleUnstakeIchi(farm, amount)
    return handleUntake(farm, amount)
  }

  const handleUntake = useCallback(
    async (farm, amount) => {
      const key = uuidv4()
      const unstakeuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Unstake ${farm.stakeToken.symbol}`,
          transactions: {
            [unstakeuuid]: {
              desc: `Unstake tokens`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const params = [farm.pid, toWei(amount, farm.stakeToken.decimals).toFixed(0)]
      console.log(params)
      const prefarmingContract = getPreMiningContract(web3, farm.address)
      setPending(true)
      try {
        await sendContract(dispatch, key, unstakeuuid, prefarmingContract, 'withdraw', params, account)
      } catch (err) {
        console.log('unstake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Unstaked',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  const handleUnstakeIchi = useCallback(
    async (farm, amount) => {
      const key = uuidv4()
      const unstakeuuid = uuidv4()
      const unstakeLpuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Unstake ${farm.stakeToken.symbol}`,
          transactions: {
            [unstakeuuid]: {
              desc: `Unstake tokens from farm`,
              status: TransactionType.START,
              hash: null,
            },
            [unstakeLpuuid]: {
              desc: `Unstake tokens from LP`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const params = [farm.pid, toWei(amount, farm.stakeToken.decimals).toFixed(0)]
      const prefarmingContract = getPreMiningContract(web3, farm.address)
      setPending(true)
      try {
        await sendContract(dispatch, key, unstakeuuid, prefarmingContract, 'withdraw', params, account)
      } catch (err) {
        console.log('unstake error :>> ', err)
        setPending(false)
        return
      }

      const vaultContract = getIchiVaultContract(web3, farm.stakeToken.address)
      const params0 = [toWei(amount, farm.stakeToken.decimals).toFixed(0), account]
      try {
        await sendContract(dispatch, key, unstakeLpuuid, vaultContract, 'withdraw', params0, account)
      } catch (err) {
        console.log('unstake error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Unstaked',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onUnstake, handleUntake, handleUnstakeIchi, pending }
}

const useHarvest = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleHarvest = useCallback(
    async (farm) => {
      const key = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim earnings`,
          transactions: {
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const preFarmingContract = getPreMiningContract(web3, farm.address)
      setPending(true)
      try {
        await sendContract(dispatch, key, harvestuuid, preFarmingContract, 'withdraw', [farm.pid, '0'], account)
      } catch (err) {
        console.log('harvest error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Rewards Claimed',
        }),
      )
      setPending(false)
    },
    [account, web3],
  )

  return { onHarvest: handleHarvest, pending }
}

const useAllHarvest = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const voterContract = useV3Voter()

  const handleAllHarvest = useCallback(
    async (pairs) => {
      const key = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim all earnings (${pairs.length})`,
          transactions: {
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      const gaugeAddresses = pairs.map((pair) => pair.gauge.address)
      try {
        await sendContract(dispatch, key, harvestuuid, voterContract, 'claimRewards', [gaugeAddresses], account)
      } catch (err) {
        console.log('all harvest error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Claimed all earnings',
        }),
      )
      setPending(false)
    },
    [account, voterContract],
  )

  return { onAllHarvest: handleAllHarvest, pending }
}

export { useStake, useUnstake, useHarvest, useAllHarvest }
