import React from 'react'
import Info from '../../components/pages/lynxClaim/info/info'
import Distribution from '../../components/pages/lynxClaim/dist/dist'
import '../claim/claim.scss'

const Index = () => {
  return (
    <div className='w-full pt-36 sm:pt-40 px-5 pb-28 xl:pb-0 xl:px-0 2xl:pb-[32px]'>
      <div className='relative top-[50px] left-[-40px] sm:left-[-60px] max-w-[900px] mx-auto'>
        <img className='token1' src='/images/claim/token2.svg' alt='Lynx Token' />
      </div>
      <div className='relative max-w-[900px] mx-auto w-full bg-[#FFFFFF26] rounded-md z-10 backdrop-blur-md'>
        <div className='flex flex-col sm:flex-row w-full sm:px-8 sm:py-8 sm:divide-x divide-[#A9A9A94D]'>
          <Info />
          <Distribution />
        </div>
      </div>
      <div className='relative flex justify-end top-[-50px] sm:right-[-50px] max-w-[900px] mx-auto'>
        <img className='token2' src='/images/claim/token3.svg' alt='Lynx Token' />
      </div>
    </div>
  )
}

export default Index
