import React, { useState, useEffect } from 'react'
import useRefresh from '../hooks/useRefresh'
import { fetchUserVeTHEs } from '../utils/fetchUserVeTHEs'
import useWeb3, { useWeb3Wagmi } from '../hooks/useWeb3'
import { getVeTHEV3APIAddress } from '../utils/addressHelpers'
import { fetchManagersData } from '../utils/fetchManagers'

const veTHEsContext = React.createContext([])

const VeTHEsContextProvider = ({ children }) => {
  const [veTHEs, setVeTHEs] = useState([])
  const [accountInfo, setAccountInfo] = useState(null)
  const [managerInfo, setManagerInfo] = useState([])

  const { account } = useWeb3Wagmi()
  const { fastRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await fetchUserVeTHEs(web3, account)
        setVeTHEs(userData.userVeInfo)
        setAccountInfo(userData.accountInfo)
      } catch (e) {
        console.error('user veLYNXs fetched had error', e)
      }
    }
    const getManagerData = async () => {
      try {
        const managerData = await fetchManagersData()
        setManagerInfo(managerData)
      } catch (e) {
        console.error('user veLYNXs fetched had error', e)
      }
    }

    if (account && getVeTHEV3APIAddress()) {
      getUserData()
    } else {
      setVeTHEs([])
    }
    getManagerData()
  }, [account, fastRefresh])

  return <veTHEsContext.Provider value={{ veTHEs, accountInfo, managerInfo }}>{children}</veTHEsContext.Provider>
}

export { veTHEsContext, VeTHEsContextProvider }
