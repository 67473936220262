import React from 'react'
import { Link } from 'react-router-dom'
import { FaSquareTwitter, FaDiscord, FaTelegram } from 'react-icons/fa6'
import { AiFillMediumSquare } from 'react-icons/ai'

import './style.scss'

const Footer = () => {
  const iconClasses = 'max-w-[36px] text-themeOrange text-[36px]'
  const text =
    "Step into the heart of Linea's intrinsic on-chain liquidity marketplace. Driven by state-of-the-art DEX infrastructure, we present an exceptionally efficient DeFi solution. Immerse yourself in the capabilities of Lynex now!"
  const otherLinks = [
    {
      text: 'Home',
      url: '/',
    },
    {
      text: 'Swap',
      url: '/swap',
    },
    {
      text: 'Liquidity',
      url: '/liquidity',
    },
  ]
  const moreLinks = [
    {
      text: 'Docs',
      url: 'https://lynex.gitbook.io/',
      external: true,
    },
    {
      text: 'Legal Disclaimer',
      url: 'https://lynex.gitbook.io/lynex-docs/security/legal-disclaimer',
      external: true,
    },
  ]
  const socialLinks = [
    {
      img: '/images/footer/twitter.png',
      icon: <FaSquareTwitter className={iconClasses} />,
      url: 'https://twitter.com/LynexFi',
    },
    {
      img: '/images/footer/medium.png',
      icon: <AiFillMediumSquare className={iconClasses} />,
      url: 'https://medium.com/@lynexfi',
    },
    {
      img: '/images/footer/discord.png',
      icon: <FaDiscord className={iconClasses} />,
      url: 'https://discord.gg/3BFkJjPmB3',
    },
    {
      img: '/images/footer/telegram.png',
      icon: <FaTelegram className={iconClasses} />,
      url: 'https://t.me/lynexfi',
    },
  ]
  return (
    <div id='footer' className={`footer-wrap`}>
      <img className='invisible hidden sm:visible sm:block absolute left-10 bottom-0 pt-6 max-w-[550px]' src='/images/footer/bg-footer.svg'></img>
      <div className='upper-footer-wrapper'>
        <div className='info'>
          <p className='heading'>The Native Liquidity Layer Of Linea</p>
          <p>{text}</p>
        </div>
        <div className='links'>
          <div className='column'>
            <p className='heading'>Other</p>
            {otherLinks.map((item, j) => {
              return (
                <Link key={j} className='link' to={item.url}>
                  {item.text}
                </Link>
              )
            })}
          </div>
          <div className='column'>
            <p className='heading'>More of Lynex</p>
            {moreLinks.map((item, j) => {
              return item.external ? (
                <div
                  className='link hover:underline'
                  id={`subitem-${j}`}
                  key={`subitem-${j}`}
                  onClick={() => {
                    window.open(item.url, '_blank')
                  }}
                >
                  {item.text}
                </div>
              ) : (
                <Link key={j} className='link' to={item.url}>
                  {item.text}
                </Link>
              )
            })}
          </div>
        </div>
      </div>

      <div className='lower-footer-wrapper'>
        <div className='flex space-x-4'>
          <img src='/images/header/logo.svg' width={110} />
          <img src='/images/footer/consensys.svg' width={110} />
        </div>
        <p>Copyright © 2024 Lynex. All rights reserved.</p>
        <div className='flex items-center space-x-[14px]'>
          {socialLinks.map((item, idx) => {
            return (
              <a key={idx} href={item.url} rel='noreferrer' target={'_blank'}>
                {item.icon ? item.icon : <img alt='' className='max-w-[36px]' src={item.img} />}
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Footer
