import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useSearchParams } from 'react-router-dom'
import TokenInput from '../../common/Input/TokenInput'
import useWalletModal from '../../../hooks/useWalletModal'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { formatAmount, isInvalidAmount, wrappedAsset } from '../../../utils/formatNumber'
import { useProceedSwap, useQuoteSwap } from '../../../hooks/useSwap'
import Spinner from '../../common/Spinner'
import StyledButton from '../../common/Buttons/styledButton'
// import SwapPopup from './popups/swapPopup'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import useDebounce from '../../../hooks/useDebounce'
import WarningModal from './WarningModal'
import { useImportTokens } from '../../../state/application/hooks'
import { fetchImportedToken } from '../../../utils/fetchImportToken'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import './swap.scss'

const SwapV1 = ({ slippage, multihops, deadline }) => {
  const [init, setInit] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [fromAmount, setFromAmount] = useState('')
  const [fromAsset, setFromAsset] = useState(null)
  const [toAsset, setToAsset] = useState(null)
  const [reverseTransiction, setReverseTransiction] = useState(false)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const debouncedFromAmount = useDebounce(fromAmount)
  const { bestTrade, priceImpact, quotePending } = useQuoteSwap(wrappedAsset(fromAsset), wrappedAsset(toAsset), debouncedFromAmount, multihops)
  const { onSwap, onWrap, onUnwrap, swapPending } = useProceedSwap()
  const [searchParams] = useSearchParams()
  const { importedTokens, addImportedToken } = useImportTokens()

  const isTokenImported = useMemo(() => {
    if (toAsset && fromAsset) {
      return importedTokens.some((asset) => asset.address === toAsset.address || asset.address === fromAsset.address)
    } else {
      return false
    }
  }, [fromAsset, toAsset, importedTokens])

  const toAmount = useMemo(() => {
    if (bestTrade) {
      return bestTrade.finalValue.toString(10)
    }
    if (fromAsset && toAsset && wrappedAsset(fromAsset).address.toLowerCase() === wrappedAsset(toAsset).address.toLowerCase()) {
      return fromAmount
    }
    return ''
  }, [bestTrade, fromAsset, toAsset, fromAmount])

  const isWrap = useMemo(() => {
    if (fromAsset && toAsset && fromAsset.address === 'ETH' && toAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const isUnwrap = useMemo(() => {
    if (fromAsset && toAsset && toAsset.address === 'ETH' && fromAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const btnMsg = useMemo(() => {
    if (!account) {
      return {
        isError: true,
        label: 'CONNECT WALLET',
      }
    }

    if (!fromAsset || !toAsset) {
      return {
        isError: true,
        label: 'SELECT A TOKEN',
      }
    }

    if (isInvalidAmount(fromAmount)) {
      return {
        isError: true,
        label: 'ENTER AN AMOUNT',
      }
    }

    if (fromAsset.balance && fromAsset.balance.lt(fromAmount)) {
      return {
        isError: true,
        label: 'INSUFICCIENT ' + fromAsset.symbol + ' BALANCE',
      }
    }

    if (isWrap) {
      return {
        isError: false,
        label: 'WRAP',
      }
    }

    if (isUnwrap) {
      return {
        isError: false,
        label: 'UNWRAP',
      }
    }

    if (!bestTrade) {
      return {
        isError: true,
        label: 'INSUFFICIENT LIQUIDITY FOR THIS TRADE',
      }
    }

    return {
      isError: false,
      label: 'SWAP',
    }
  }, [account, fromAsset, toAsset, fromAmount, bestTrade, priceImpact, isWrap, isUnwrap])

  const baseAssets = useContext(BaseAssetsConetext)
  const firstRun = useRef(true)
  useEffect(() => {
    if (init || baseAssets.length === 0 || !firstRun.current) return
    setInputAndOutput()
    async function fetchToken(address) {
      let token = address ? baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase()) : null
      if (!token && address) {
        token = await fetchImportedToken(address, account)
        if (token) addImportedToken(token)
      }
      return token
    }
    async function setInputAndOutput() {
      firstRun.current = false
      const inputCurrency = searchParams.get('inputCurrency')
      const outputCurrency = searchParams.get('outputCurrency')
      const from = await fetchToken(inputCurrency)
      const to = await fetchToken(outputCurrency)
      if (!from) {
        setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
      } else {
        setFromAsset(from)
      }
      if (!to) {
        setToAsset(baseAssets.find((asset) => asset.symbol === 'LYNX'))
      } else {
        setToAsset(to)
      }
      setInit(true)
    }
  }, [baseAssets, searchParams])

  useEffect(() => {
    // NOTE: Refreshes balances
    if (baseAssets.length === 0 || !init) return
    const inputCurrency = fromAsset
    const outputCurrency = toAsset
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.address.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.address.toLowerCase()) : null
    if (!from) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
    } else {
      setFromAsset(from)
    }
    if (!to) {
      setToAsset(baseAssets.find((asset) => asset.symbol === 'LYNX'))
    } else {
      setToAsset(to)
    }
  }, [baseAssets])

  const handleSwap = useCallback(() => {
    onSwap(fromAsset, toAsset, fromAmount, bestTrade, slippage, deadline)
  }, [fromAsset, toAsset, fromAmount, bestTrade, slippage, deadline])

  return (
    <>
      <div className='swap-wrapper'>
        <div className='fromto'>
          <TokenInput
            title='From'
            asset={fromAsset}
            setAsset={setFromAsset}
            otherAsset={toAsset}
            setOtherAsset={setToAsset}
            amount={fromAmount}
            onInputChange={(val) => {
              setFromAmount(val)
            }}
            isDollar
          />
        </div>
        <div className='flex justify-center -my-4 '>
          <button
            onClick={() => {
              const tempAsset = fromAsset
              if (new BigNumber(toAmount).gt(0)) {
                setFromAmount(toAmount)
              }
              setFromAsset(toAsset)
              setToAsset(tempAsset)
            }}
            className='flex justify-center items-center w-[42px] h-[42px] bg-black/70 border border-[#ffffff33] rounded-full transition duration-200 ease-in-out hover:rotate-180 z-20'
          >
            <img className='w-[20px] h-[20px]' src='/images/swap/reverse-icon.svg' />
          </button>
        </div>
        <div className='fromto'>
          <TokenInput
            title='To'
            asset={toAsset}
            setAsset={setToAsset}
            amount={toAmount}
            otherAsset={fromAsset}
            setOtherAsset={setFromAsset}
            disabled
            isDollar
          />
        </div>

        {isTokenImported && (
          <div className='w-full flex flex-row justify-end my-4'>
            <img alt='' src='/images/svgs/warning.svg' />
            <p className='text-red-500 text-sm mx-2'>WARNING! Token imported; trade at your own risk.</p>
          </div>
        )}

        {account ? (
          <StyledButton
            disabled={btnMsg.isError || swapPending}
            pending={swapPending}
            onClickHandler={() => {
              if (isWrap) {
                onWrap(fromAmount)
              } else if (isUnwrap) {
                onUnwrap(fromAmount)
              } else {
                if (priceImpact.gt(5)) {
                  setIsWarning(true)
                } else {
                  handleSwap()
                }
              }
            }}
            content={btnMsg.label}
            className='w-full text-base md:text-lg py-3 px-4 mt-4'
          />
        ) : (
          <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='w-full text-base md:text-lg py-3 px-4 mt-4' />
        )}

        {bestTrade && (
          <div className='fromto mt-4 text-gray-400'>
            {priceImpact.gt(0) && (
              <div
                className={`flex space-x-2 p-1 items-center justify-center border border-[#ffffff33] text-white rounded-xl ${
                  priceImpact.lt(1) ? 'bg-success/15' : priceImpact.lt(2) ? 'bg-white/10' : priceImpact.lt(5) ? 'bg-warn/40' : 'bg-error/40'
                }`}
              >
                <p className='text-sm md:text-md leading-5'>Price Impact</p>
                <p className='text-sm md:text-md leading-5 font-semibold'>{formatAmount(priceImpact)}%</p>
              </div>
            )}
            <div className='flex items-center justify-between'>
              <p className='text-sm md:text-md leading-5 mt-2'>Price:</p>
              {quotePending ? (
                <Spinner />
              ) : (
                <>
                  <div className='flex items-center space-x-1.5'>
                    <p className='text-sm md:text-md leading-5'>
                      {reverseTransiction
                        ? `${formatAmount(new BigNumber(toAmount).div(fromAmount))} ${toAsset.symbol} per ${fromAsset.symbol}`
                        : `${formatAmount(new BigNumber(fromAmount).div(toAmount))} ${fromAsset.symbol} per ${toAsset.symbol}`}
                    </p>
                    <button onClick={() => setReverseTransiction(!reverseTransiction)}>
                      <img alt='' src='/images/swap/reverse-small-icon.svg' />
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='mt-[0.3rem]'>
              <div className='flex items-center justify-between'>
                <p className='text-sm md:text-md leading-5'>Minimum received</p>
                <p className='text-sm md:text-md leading-5'>
                  {formatAmount(bestTrade.finalValue.times(100 - slippage).div(100))} {toAsset.symbol}
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between mt-[0.3rem]'>
              <p className='text-sm md:text-md leading-5'>Slippage Tolerance</p>
              <p className='text-sm md:text-md leading-5'>{slippage}%</p>
            </div>
            <div className='flex items-center justify-between mt-[0.3rem]'>
              <p className='text-sm md:text-md leading-5'>Route:</p>
            </div>
            <div className='flex relative items-center mt-7 justify-between'>
              <img className='z-10 w-7 sm:w-[38px] -ml-0.5 sm:-ml-1' alt='' src={fromAsset.logoURI || '/images/tokens/UKNOWN.png'} />
              <div className='relative flex flex-col items-center'>
                <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[0].stable ? 'Stable' : 'Volatile'}</p>
                <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
              </div>
              {bestTrade.base && bestTrade.base.length === 1 && (
                <>
                  <img className='z-10 w-7 sm:w-[38px]' alt='' src={bestTrade.base[0].logoURI || '/images/tokens/UKNOWN.png'} />
                  <div className='relative flex flex-col items-center'>
                    <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[1].stable ? 'Stable' : 'Volatile'}</p>
                    <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                  </div>
                </>
              )}
              {bestTrade.base && bestTrade.base.length === 2 && (
                <>
                  <img className='z-10 w-7 sm:w-[38px]' alt='' src={bestTrade.base[0].logoURI || '/images/tokens/UKNOWN.png'} />
                  <div className='relative flex flex-col items-center'>
                    <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[1].stable ? 'Stable' : 'Volatile'}</p>
                    <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                  </div>
                  <img className='z-10 w-7 sm:w-[38px]' alt='' src={bestTrade.base[1].logoURI} />
                  <div className='relative flex flex-col items-center'>
                    <p className='text-[13px] md:text-sm text-white absolute -top-7'>{bestTrade.routes[2].stable ? 'Stable' : 'Volatile'}</p>
                    <img className='z-10 w-[18px] sm:w-6' alt='' src='/images/swap/route-arrow.svg' />
                  </div>
                </>
              )}
              <img className='z-10 w-7 sm:w-[38px] -mr-0.5 sm:-mr-1' alt='' src={toAsset.logoURI || '/images/tokens/UKNOWN.png'} />
              <div className='border-custom w-full h-0.5  absolute' />
            </div>
          </div>
        )}

        {isWarning && <WarningModal isOpen={isWarning} setIsOpen={setIsWarning} onClickHandler={handleSwap} priceImpact={priceImpact} />}
      </div>
    </>
  )
}

export default SwapV1
