import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import '../../../../common/Dropdown/dropdown.scss'

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
  },
  {
    label: 'Staked',
    value: 'stake',
  },
]

const SortToggle = ({ sort, setSort }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='flex space-x-4 items-center'>
      <p className='text-sm text-dimGray'>Sort by</p>
      <div className={`w-[120px] dropdownwrapper mt-0`}>
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <div
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className={`w-full flex items-center h-[36px] border border-[#ffffff33] rounded-[10px] px-4 cursor-pointer`}
          >
            <div className={`w-full h-10 relative rounded-[10px] text-white flex items-center justify-between`}>
              <p className='text-sm text-white font-medium'>{sort.label}</p>
              <img
                className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
                alt=''
                src='/images/swap/dropdown-arrow.png'
              />
              {isOpen && (
                <div className={`w-[120px] z-[500] dropdown-blur border border-[#ffffff33] -left-[16px] rounded-[10px] top-10 absolute`}>
                  {sortOptions.map((item, idx) => {
                    return (
                      <div
                        onClick={() => {
                          setSort(item)
                          setIsOpen(false)
                        }}
                        key={idx}
                        className='z-[600] px-4 py-2 hover:bg-opacity-30'
                      >
                        <p className='text-white text-sm leading-7 tracking-wide'>{item.label}</p>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  )
}

export default SortToggle
