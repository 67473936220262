import React, { useEffect, useState, useMemo } from 'react'
import OtokenInput from '../../common/Input/OtokenInput'
import StyledButton from '../../common/Buttons/styledButton'
import AddToMetamask from '../../common/Buttons/addToMetamask'
import useWalletModal from '../../../hooks/useWalletModal'
import { getOptionAddress, getTHEAddress } from '../../../utils/addressHelpers'
import { formatUnits } from 'ethers/lib/utils'
import { useExcercise } from '../../../hooks/useExercise'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { isInvalidAmount } from '../../../utils/formatNumber'
import './otoken.scss'
import '../swap/swap.scss'
import { TransactButton } from '../../common/Buttons/transactButton'

const Lynx = ({ baseAssets, optionsData }) => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const [redeemAmount, setRedeemAmount] = useState('')
  const [redeemAsset, setRedeemAsset] = useState(null)
  const [payAmoount, setPayAmount] = useState('')
  const [payAsset, setPayAsset] = useState(null)
  const [toAsset, setToAsset] = useState(null)
  const option = optionsData.fetchedOptions
  const { onExcercise } = useExcercise()
  const notAllowed =
    !account ||
    redeemAsset?.balance.isZero() ||
    Number(redeemAmount) === 0 ||
    redeemAsset?.balance.lt(redeemAmount) ||
    payAsset?.balance.isZero() ||
    payAsset?.balance.lt(payAmoount)

  const btnMsg = useMemo(() => {
    if (!account) {
      return {
        isError: true,
        label: 'CONNECT WALLET',
      }
    }

    if (isInvalidAmount(redeemAmount)) {
      return {
        isError: true,
        label: 'ENTER AN AMOUNT',
      }
    }

    if (payAsset.balance && payAsset.balance.lt(payAmoount)) {
      return {
        isError: true,
        label: 'INSUFFICIENT ' + payAsset.symbol + ' BALANCE',
      }
    }
    return {
      isError: false,
      label: 'REDEEM INTO LYNX',
    }
  }, [account, payAsset, payAmoount, redeemAmount])

  useEffect(() => {
    if (!baseAssets || baseAssets.length === 0) return
    const oLynxtoken = baseAssets.find((asset) => asset.address.toLowerCase() === getOptionAddress()?.toLowerCase())
    setRedeemAsset(oLynxtoken)
    const paymentToken = baseAssets.find((asset) => asset.address.toLowerCase() === option.paymentToken.toLowerCase())
    setPayAsset(paymentToken)
    if (!toAsset) {
      const lynxToken = baseAssets.find((asset) => asset.address.toLowerCase() === getTHEAddress()?.toLowerCase())
      setToAsset(lynxToken)
    } else {
      setToAsset(toAsset)
    }
  }, [baseAssets])

  useEffect(() => {
    if (!payAsset) return
    const principal = redeemAmount * option?.discountPrice?.toNumber()
    setPayAmount(formatUnits(parseInt(principal), payAsset.decimals))
  }, [redeemAmount])

  return (
    <div className='wrapper-otoken'>
      <p className='option-info'>Redeem for LYNX: Exchange oLYNX for LYNX at a discounted rate.</p>
      <div className='fromto'>
        <OtokenInput
          asset={redeemAsset}
          amount={redeemAmount}
          onInputChange={(val) => {
            setRedeemAmount(val)
          }}
          title='Use'
          noPrice
        />
      </div>
      <div className='flex justify-center -my-7 '>
        <div className='flex justify-center w-[38px] h-[37px] bg-black/70 border border-[#ffffff33] rounded-full z-20'>
          <img className='w-5 rotate-[270deg]' src='/images/svgs/plus.svg' />
        </div>
      </div>
      <div className='fromto'>
        <OtokenInput asset={payAsset} amount={payAmoount} title='Pay' noPrice disabled />
      </div>
      <div className='flex justify-center -my-7 '>
        <div className='flex justify-center w-[38px] h-[37px] bg-black/70 border border-[#ffffff33] rounded-full z-20'>
          <img className='w-5 rotate-[270deg]' src='/images/swap/back-arrow.svg' />
        </div>
      </div>
      <div className='fromto'>
        <OtokenInput asset={toAsset} amount={redeemAmount} title='Get' noBalance disabled />
      </div>
      {account ? (
        <TransactButton
          content={btnMsg.label}
          disabled={notAllowed || btnMsg.isError}
          onClickHandler={() => {
            onExcercise(payAsset, payAmoount, redeemAmount)
          }}
          className='w-full text-base md:text-lg py-3 px-4'
        />
      ) : (
        <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='w-full text-base md:text-lg py-3 px-4' />
      )}
      <div className='line'>
        <p className='title'>Discount</p>
        <p className='value'>{100 - option.discount}%</p>
      </div>
      <p className='text-[14px] text-white italic'>Note: Discount rates are dynamic and market-responsive. They are subject to change.</p>
      {account && (
        <div className='flex'>
          <AddToMetamask
            text='Add oLYNX to Metamask'
            tokenAddress={redeemAsset?.address}
            tokenSymbol={redeemAsset?.symbol}
            tokenDecimals={redeemAsset?.decimals}
            imgUrl={redeemAsset?.logoURI}
          />
        </div>
      )}
    </div>
  )
}

export default Lynx
