/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import useWalletModal from '../../../../hooks/useWalletModal'
import StyledButton from '../../../common/Buttons/styledButton'
import './dist.scss'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { getMetamaskSwaps, submitFoxyClaim } from '../../../../utils/api'
import { formatAmount } from '../../../../utils/formatNumber'
import BigNumber from 'bignumber.js'
import Skeleton from '../../../common/Spinner/skeleton'
import { useAirdropClaim } from '../hooks/useAirdropClaim'
import { useTHEAsset } from '../../../../hooks/useGeneral'
import { customNotify } from '../../../../utils/notify'

const Distribution = ({ type }) => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const [loading, setLoading] = useState(true)
  const [amount, setAmount] = useState(0)
  const [hasClaimed, setHasClaimed] = useState(false)
  const lynxAsset = useTHEAsset()
  const disable = !account || hasClaimed || amount <= 0
  const price = lynxAsset?.price

  useEffect(() => {
    setLoading(true)
    const fetchAirdropClaim = async () => {
      const res = await getMetamaskSwaps(account)
      setHasClaimed(false)
      if (res) {
        setAmount(res.amount)
        setHasClaimed(res.claimed)
      }
      setLoading(false)
    }
    if (account) {
      fetchAirdropClaim()
    }
  }, [account])

  const handleClaim = async () => {
    const claim = await submitFoxyClaim(account)
    setHasClaimed(claim.result)
    customNotify('Claim Submitted!', 'success')
  }

  return (
    <div className='dist'>
      <p className='title'>Token distribution</p>
      {loading ? (
        <Skeleton />
      ) : amount > 0 ? (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(amount)} swaps on Metamask. You qualify for this claim</p>
        </div>
      ) : (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className='row'>🙁 you are not eligible for this claim</p>{' '}
        </div>
      )}
      {account ? (
        <StyledButton
          disabled={disable}
          content={hasClaimed ? 'ALREADY SUBMITTED' : 'SUBMIT CLAIM'}
          className='action-button'
          onClickHandler={() => {
            handleClaim()
          }}
        />
      ) : (
        <StyledButton
          onClickHandler={() => {
            openWalletModal()
          }}
          content={'CONNECT WALLET'}
          className='action-button'
        />
      )}
    </div>
  )
}

export default Distribution
