import { fusionClient } from '../apollo/client'
import { FUSION_POOLS, FUSION_POOLS_DAILY } from '../apollo/queries'
import { poolAbi } from '../config/abi/v3'
import { multicall } from './multicall'

export const fetchFusionPools = async () => {
  try {
    const result = await fusionClient.query({
      query: FUSION_POOLS(),
      fetchPolicy: 'cache-first',
    })
    const data = result.data.pools
    const calls = data.map((pool) => {
      return {
        address: pool.id,
        name: 'globalState',
      }
    })
    const globalStates = await multicall(poolAbi, calls)
    return data.map((ele, index) => {
      return {
        address: ele.id,
        liquidity: ele.liquidity,
        globalState: globalStates[index],
        tickSpacing: Number(ele.tickSpacing),
      }
    })
  } catch (error) {
    console.log('fusion pool fetch error :>> ', error)
  }
}

export const fetchFusionPoolsDaily = async () => {
  try {
    const result = await fusionClient.query({
      query: FUSION_POOLS_DAILY(),
      fetchPolicy: 'cache-first',
    })
    const data = result.data.pools
    const calls = data.map((pool) => {
      return {
        address: pool.id,
        name: 'globalState',
      }
    })
    let [globalStates, poolAprs] = await Promise.all([multicall(poolAbi, calls), fetch(process.env.REACT_APP_ALGEBRA_POOL_API_URL)])
    poolAprs = poolAprs.json()
    return data.map((ele, index) => {
      return {
        ...ele,
        address: ele.id,
        liquidity: ele.liquidity,
        globalState: globalStates[index],
        tickSpacing: Number(ele.tickSpacing),
        poolApr: poolAprs && poolAprs[ele.id.toLowerCase()] ? poolAprs[ele.id.toLowerCase()] : 0,
      }
    })
  } catch (error) {
    console.log('fusion pool fetch error :>> ', error)
  }
}
