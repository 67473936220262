import React from 'react'
import './info.scss'

const Portal = () => {
  return (
    <div className='info'>
      <div className='title'>
        <p>Claim Portal</p>
        <p className='text-2xl'>$FOXY</p>
      </div>
      <p className='text'>Claim your FOXY airdrop</p>
      <p className='text'>Claim your $FOXY airdrop! 5,000 $FOXY tokens will be distributed to each eligible wallet.</p>
      <p className='text'>
        The qualifying factor was the number of completed successful Swaps in MetaMask wallet in the last 6 months across all supported chains.
      </p>
      <div className='flex items-center space-x-2.5 cursor-pointer w-full sm:w-1/2 group'>
        <a className='learn-more' href='https://twitter.com/FoxyLinea/status/1775650346230034825' target='_blank' rel='noreferrer'>
          Learn more
        </a>
        <img className={`group-hover:w-[35px] w-[30px] duration-300 ease-in-out`} src='/images/common/spear.svg' alt='' />
      </div>
    </div>
  )
}

export default Portal
