import Web3 from 'web3'
import getRpcUrl, { Nodes } from './getRpcUrl'
import random from 'lodash/random'

const RPC_URL = getRpcUrl()
export const defaultHttpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 })
const web3NoAccount = new Web3(defaultHttpProvider)

const clients = {}

export const getRandomClient = () => {
  const nodes = Nodes[Number(process.env.REACT_APP_CHAIN_ID)]
  const randomIndex = random(0, nodes.length - 1)
  const nodeUrl = nodes[randomIndex]
  if (clients[nodeUrl]) return clients[nodeUrl]
  const httpProvider = new Web3.providers.HttpProvider(nodeUrl, { timeout: 10000 })
  const web3NoAccount = new Web3(httpProvider)
  clients[nodeUrl] = web3NoAccount
  return clients[nodeUrl]
}

const getWeb3NoAccount = () => {
  return getRandomClient()
}

export { getWeb3NoAccount }
export default web3NoAccount
