import React, { useState, useMemo, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import TransparentButton from '../../common/Buttons/transparentButton'
import StyledButton from '../../common/Buttons/styledButton'
import CommonHollowModal from '../../common/CommonHollowModal'
import Tab from '../../common/Tab'
import BalanceInput from '../../common/Input/BalanceInput'
import { useStake, useUnstake } from '../../../hooks/useGauge'
import { isInvalidAmount } from '../../../utils/formatNumber'
import { customNotify } from '../../../utils/notify'
import Toggle from '../../common/Toggle'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { useIchiAdd } from '../../../hooks/v3/useIchi'

const DepositIchiModal = ({ isOpen, setIsOpen, pair, depositToken, hasLp = false, hasStaked = true }) => {
  const navigate = useNavigate()
  const [staking, setStaking] = useState(!hasStaked || hasLp)
  const [stakeAmount, setStakeAmount] = useState('')
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [depositLp, setDepositLp] = useState(hasLp)
  const { onStake, pending: stakePending } = useStake()
  const { onUnstake, pending: unstakePending } = useUnstake()
  const baseAssets = useContext(BaseAssetsConetext)
  const depositAsset = useMemo(() => baseAssets.find((asset) => asset.address.toLowerCase() === depositToken.address.toLowerCase()), [depositToken])

  const { handleStakeIchi } = useIchiAdd()

  // amountA, amountB, amountToWrap, ichiPair
  const stakeBtnMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(stakeAmount)) {
        return {
          isError: true,
          label: 'INVALID AMOUNT',
        }
      }

      if (depositLp && pair.account.lpBalance.lt(stakeAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT LP BALANCE',
        }
      }
      if (!depositLp && depositAsset.balance.lt(stakeAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT ' + depositAsset.symbol + ' BALANCE',
        }
      }
    }
    return {
      isError: false,
      label: 'DEPOSIT AND STAKE',
    }
  }, [pair, stakeAmount])

  const unstakeErrorMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return 'Invalid Amount'
      }
      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return 'Insufficient ' + pair.symbol + ' Balance'
      }
    }
    return null
  }, [withdrawAmount, pair])

  const unstakeBtnMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return {
          isError: true,
          label: 'INVALID AMOUNT',
        }
      }

      if (pair.account.gaugeBalance.lt(withdrawAmount)) {
        return {
          isError: true,
          label: 'INSUFFICIENT LP BALANCE',
        }
      }
    }
    return {
      isError: false,
      label: `UNSTAKE ${pair.symbol.split(' ')[0]}`,
    }
  }, [pair, withdrawAmount, depositAsset])

  return (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage liquidity`}>
      <div className='w-full mt-[29px] flex items-center justify-center flex-col'>
        <Tab leftTitle={'STAKE'} rightTitle={'UNSTAKE'} isLeft={staking} setIsLeft={setStaking} />
        {staking ? (
          <div className='w-full flex items-center justify-center flex-col mt-4'>
            <BalanceInput
              title=''
              inputAmount={stakeAmount}
              setInputAmount={setStakeAmount}
              symbol={depositLp ? pair.symbol : depositAsset.symbol}
              balance={depositLp ? pair.account.lpBalance : depositAsset.balance}
              logoURIs={depositLp ? [pair.token0.logoURI, pair.token1.logoURI] : [depositAsset.logoURI]}
            />
            <div className='flex w-full items-center justify-between mt-2'>
              <TransparentButton
                onClickHandler={() => navigate(`/swap?outputCurrency=${depositAsset.address}`)}
                content={`Get ${depositAsset.symbol}`}
                className='flex items-center justify-center py-1.5 md:py-2 px-4 text-white text-[10px] md:text-xs tracking-wide hover:bg-themeOrangeLight/30'
              />
              <div className='flex items-center justify-end space-x-2'>
                <p className='text-white text-sm'>Stake {pair.symbol.split(' ')[0]}</p>
                <Toggle checked={depositLp} onChange={() => setDepositLp(!depositLp)} small toggleId='depositLp' />
              </div>
            </div>
            <div className='flex w-full space-x-2 mt-4'>
              <StyledButton
                disabled={stakePending || stakeBtnMsg.isError}
                onClickHandler={() => (depositLp ? onStake(pair, stakeAmount) : handleStakeIchi(pair, stakeAmount, false))}
                content={stakeBtnMsg.label}
                className='w-1/2 py-2 md:py-3 text-xs md:text-sm'
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content='CANCEL'
                className='flex justify-center items-center w-1/2 py-2 md:py-3 text-xs md:text-sm tracking-wide hover:bg-themeOrangeLight/30'
              />
            </div>
          </div>
        ) : (
          <div className='w-full flex items-center justify-center flex-col mt-5'>
            <BalanceInput
              title=''
              inputAmount={withdrawAmount}
              setInputAmount={setWithdrawAmount}
              symbol={pair.symbol}
              balance={pair.account.withdrawBalance}
              logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
            />
            <div className='flex items-center mt-[26px] w-full space-x-5'>
              <StyledButton
                content={unstakePending ? 'PENDING...' : unstakeBtnMsg.label}
                disabled={unstakePending || unstakeBtnMsg.isError}
                onClickHandler={() => {
                  if (unstakeErrorMsg) {
                    customNotify(unstakeErrorMsg, 'warn')
                    return
                  }
                  onUnstake(pair, withdrawAmount)
                }}
                className='w-1/2 py-2 md:py-3 text-xs md:text-sm'
              />
              <TransparentButton
                onClickHandler={() => setIsOpen(false)}
                content={'CANCEL'}
                className='
                flex justify-center items-center w-1/2 py-2 md:py-3 text-xs md:text-sm tracking-wide hover:bg-themeOrangeLight/30'
              />
            </div>
          </div>
        )}
      </div>
    </CommonHollowModal>
  )
}

export default DepositIchiModal
