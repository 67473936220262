import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const percentArray = [25, 50, 75, 100]

const OtokenInput = ({ asset, amount, title, noBalance = false, noPrice = false, onInputChange = () => {}, disabled = false }) => {
  const { account } = useWeb3Wagmi()

  return (
    <>
      <div className={`flex flex-wrap items-center justify-between mb-1`}>
        <p className='text-white text-sm md:text-xl'>{title}</p>
        {!disabled && account && (
          <div className='flex items-center justify-end space-x-2.5 '>
            {percentArray.map((percent, index) => {
              return (
                <div
                  className={`flex items-center justify-center bg-white/5 hover:bg-white/20 border border-[#ffffff33] rounded-md text-dimGray text-xs md:text-sm w-[40px] md:w-[52px] h-[22px] md:h-[28px] cursor-pointer`}
                  onClick={() => {
                    asset && onInputChange(asset?.balance.times(percent).div(100).dp(asset?.decimals).toString(10) || '')
                  }}
                  key={`percent-${index}`}
                >
                  {index === percentArray.length - 1 ? 'MAX' : percent}
                  {index === percentArray.length - 1 ? '' : '%'}
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className='p-px w-full rounded-md'>
        <div className='flex rounded-md items-center justify-between mb-2'>
          {asset && (
            <div className='flex items-center cursor-default rounded-md'>
              <div className='flex items-center space-x-[3.5px] lg:space-x-2 '>
                <img className='w-[20px] h-[20px] lg:w-[36px] lg:h-[36px]' alt='token image' src={asset.logoURI || '/images/tokens/UKNOWN.png'} />
                <p className='font-medium text-sm lg:text-[1.2rem] leading-6 text-white'>{asset.symbol}</p>
              </div>
            </div>
          )}

          <div className={`flex flex-col items-end justify-end w-[70%] text-dimGray`}>
            <input
              value={amount}
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  onInputChange('')
                } else {
                  onInputChange(e.target.value)
                }
              }}
              className='bg-transparent w-full !border-0 text-xl lg:text-3xl placeholder-white text-white'
              placeholder='0'
              type={'number'}
              disabled={disabled}
              min={0}
              style={{ textAlign: 'right' }}
            />
          </div>
        </div>
        {account && (
          <div className='flex w-full justify-between items-center text-gray-400'>
            <p className={`${!noBalance ? 'visible' : 'invisible'} text-xs lg:text-sm`}>Balance: {!asset ? '-' : formatAmount(asset.balance)}</p>
            <div className={`${!noPrice ? 'visible' : 'invisible'} text-xs lg:text-sm`}>${formatAmount((asset?.price || 0) * (Number(amount) || 0))}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default OtokenInput
