import React, { useCallback, useMemo, useState } from 'react'
import { TransactButton } from '../../common/Buttons/transactButton'
import StyledButton from '../../common/Buttons/styledButton'
import BalanceInput from '../../common/Input/BalanceInput'
import SearchInput from '../../common/Input/SearchInput'
import TransparentButton from '../../common/Buttons/transparentButton'
import LiquidityDetails from './liquidityDetails'
import { useQuoteRemove, useRemoveLiquidity } from '../../../hooks/useLiquidity'
import { customNotify } from '../../../utils/notify'
import useWalletModal from '../../../hooks/useWalletModal'
import { getLPSymbol, isInvalidAmount } from '../../../utils/formatNumber'
import NoFound from '../../common/NoFound'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const RemoveLiquidity = ({ slippage, deadline, pairAddress, pairs, lite = null }) => {
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [searchText, setSearchText] = useState('')
  const [selectedPairAddress, setSelectedPairAddress] = useState(pairAddress)
  const { account } = useWeb3Wagmi()
  const userPairs = useMemo(() => {
    return pairs.filter((pair) => !pair.account?.lpBalance.isZero())
  }, [pairs])
  const pair = useMemo(() => {
    if (pairs && pairs.length > 0) {
      return pairs.find((item) => item.address === selectedPairAddress)
    }
    return null
  }, [selectedPairAddress, pairs])

  const filteredPairs = useMemo(() => {
    if (!searchText || searchText === '') {
      return userPairs
    }
    return userPairs.filter((pair) => pair.symbol.toLowerCase().includes(searchText.toLowerCase()))
  }, [userPairs, searchText])
  const { firstAmount, secondAmount } = useQuoteRemove(pair, withdrawAmount)
  const { openWalletModal } = useWalletModal()

  const { onRemove } = useRemoveLiquidity()

  const errorMsg = useMemo(() => {
    if (pair) {
      if (isInvalidAmount(withdrawAmount)) {
        return 'Invalid Amount'
      }
      if (pair.account.lpBalance.lt(withdrawAmount)) {
        return 'Insufficient ' + pair.symbol + ' Balance'
      }
    }
    return null
  }, [withdrawAmount, pair])

  const onRemoveLiquidity = useCallback(() => {
    if (errorMsg) {
      customNotify(errorMsg, 'warn')
      return
    }
    onRemove(pair, withdrawAmount, slippage, deadline, firstAmount, secondAmount)
  }, [pair, withdrawAmount, slippage, deadline, firstAmount, secondAmount, errorMsg])

  return (
    <>
      {pair ? (
        <div className={`flex flex-col w-full items-center justify-center mt-4`}>
          {!pairAddress && (
            <div
              className='flex items-center w-full mb-2 cursor-pointer'
              onClick={() => {
                setSelectedPairAddress(null)
              }}
            >
              <button className='mr-[12px]'>
                <img alt='' src='/images/swap/back-arrow.svg' width={15} />
              </button>
              <div className='text-base f-f-fg text-white'>Back to List</div>
            </div>
          )}
          <BalanceInput
            title='Amount'
            inputAmount={withdrawAmount}
            setInputAmount={setWithdrawAmount}
            symbol={pair.symbol}
            balance={pair.account.lpBalance}
            logoURIs={[pair.token0.logoURI, pair.token1.logoURI]}
          />
          <button className='focus:outline-none my-1 z-[8]'>
            <img src='/images/liquidity/arrow-down2.svg' />
          </button>
          <div className='md:flex-row flex-col flex w-full items-center space-y-2 md:space-y-0 md:space-x-3'>
            <div className='p-px w-full md:w-1/2'>
              <div className='bg-white/5 pr-4 rounded-md justify-between flex items-center'>
                <input
                  className='bg-transparent w-3/5 p-2 pr-0 text-lg md:text-xl placeholder-[#757384] text-white'
                  placeholder='0.00'
                  value={firstAmount}
                  disabled
                />
                <button className='flex items-center space-x-2'>
                  <img className='relative z-[5]' alt='' src={pair.token0.logoURI} width={24} height={24} />
                  <p className='text-sm md:text-base font-semibold text-white'>{pair.token0.symbol === 'WETH' ? 'ETH' : pair.token0.symbol}</p>
                </button>
              </div>
            </div>
            <div className=' p-px w-full md:w-1/2'>
              <div className='bg-white/5 pr-4 rounded-md flex justify-between items-center'>
                <input
                  className='bg-transparent w-3/5 p-2 pr-0 text-lg md:text-xl placeholder-[#757384] text-white'
                  placeholder='0.00'
                  value={secondAmount}
                  disabled
                />
                <button className='flex items-center space-x-2'>
                  <img className='relative z-[5]' alt='' src={pair.token1.logoURI} width={24} height={24} />
                  <p className='text-sm md:text-base font-semibold text-white'>{pair.token1.symbol === 'WETH' ? 'ETH' : pair.token1.symbol}</p>
                </button>
              </div>
            </div>
          </div>
          <LiquidityDetails pair={pair} slippage={slippage} lite={lite} />
          {account ? (
            <>
              <TransactButton content={'REMOVE LIQUIDITY'} onClickHandler={onRemoveLiquidity} className='w-full mt-3 py-3 px-4 text-xs md:text-sm' />
            </>
          ) : (
            <StyledButton onClickHandler={openWalletModal} content={'CONNECT WALLET'} className='w-full mt-3 py-3 px-4 text-xs md:text-sm' />
          )}
        </div>
      ) : (
        <>
          <SearchInput className={'mt-3 bg-white/10'} full searchText={searchText} setSearchText={setSearchText} placeholder='Search LP' />
          {filteredPairs.length > 0 ? (
            <div className='w-full mt-[18px]'>
              <p className='f-f-fg text-white font-semibold pl-4 text-lg pb-2'>My Liquidity Pairs</p>
              {filteredPairs.map((item, idx) => (
                <div key={idx} className={`${filteredPairs.length - 1 !== idx && 'mb-3'} bg/white/5 p-px rounded-[5px]`}>
                  <div className='bg-white/5 px-5 py-3.5 rounded-[5px] md:flex items-center justify-between w-full'>
                    <div className='flex text-white items-center  space-x-3'>
                      <div className='flex items-center  -space-x-2'>
                        <img className='relative z-10' alt='' src={item.token0.logoURI} width={31} height={31} />
                        <img className='relative z-[5]' alt='' src={item.token1.logoURI} width={31} height={31} />
                      </div>
                      <div className='font-medium'>
                        <p className='text-[17px] md:text-[19px] leading-[30px]'>{`${getLPSymbol(item)}`}</p>
                        <p className='tracking-[0.78px] text-[11px] md:text-[13px] leading-3 md:leading-none'>{item.stable ? 'STABLE' : 'VOLATILE'}</p>
                      </div>
                    </div>
                    <TransparentButton
                      onClickHandler={() => {
                        setSelectedPairAddress(item.address)
                      }}
                      fontWeight={'font-normal'}
                      className={
                        'rounded-r-[5px] h-10 px-4 max-w-[160px] mt-2.5 md:mt-0  md:max-w-fit text-white flex flex-col items-center justify-center text-[15px] md:text-[17px]'
                      }
                      content={'Withdraw Liquidity'}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='w-full mt-[18px]'>
              <NoFound title='No liquidity found' />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default RemoveLiquidity
