import React from 'react'
import Assets from './assets/assets'
import Positions from './positions/positions'
import LxplBanner from './lxplBanner'

const MyAssets = ({ setOption }) => {
  return (
    <div>
      <Assets setOption={setOption} />
      <LxplBanner />
      <Positions />
    </div>
  )
}

export default MyAssets
