import { Interface } from '@ethersproject/abi'
import { getMulticallContract } from './contractHelpers'
import { chunk, flatten } from 'lodash'
const step = 100

export const multicall = async (abi, calls) => {
  const multi = getMulticallContract()
  const itf = new Interface(abi)

  const totalLength = calls.length
  let index = 0
  let result = []
  while (index < totalLength) {
    const calldata = calls.slice(index, index + step).map((call) => ({
      target: call.address.toLowerCase(),
      callData: itf.encodeFunctionData(call.name, call.params),
    }))
    const { returnData } = await multi.methods.aggregate(calldata).call()
    result = [...result, ...returnData]
    index += step
  }

  const res = result.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}

export const multicallChunk = async (abi, calls, size) => {
  const chunked = chunk(calls, size)
  const callsPromises = []
  for (let i = 0; i < chunked.length; i++) {
    callsPromises.push(multicall(abi, chunked[i]))
  }
  const res = await Promise.all(callsPromises)
  return flatten(res)
}
