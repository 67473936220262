import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import JSBI from 'jsbi'
import Modal from '../../../../../common/Modal'
import { ZERO_ADDRESS, formatAmount, toWei } from '../../../../../../utils/formatNumber'
import { AddAmountCard } from './addAmountCard'
import { useV3GammaUniproxy } from '../../../../../../hooks/useContractV3'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useSingleContractMultipleData } from '../../../../../../state/multicall/v3/hooks'
import { useGammaAdd } from '../../../../../../hooks/v3/useGamma'
import { useCurrencyBalance } from '../../../../../../hooks/v3/useCurrencyBalances'
import { WETH_EXTENDED } from '../../../../../../v3lib/entities/constants'
import { defaultChainId } from '../../../../../../config/constants'
import { useCurrency } from '../../../../../../hooks/v3/Tokens'
import BigNumber from 'bignumber.js'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { customNotify } from '../../../../../../utils/notify'
import { TransactButton } from '../../../../../common/Buttons/transactButton'
import { FusionsContext } from '../../../../../../context/FusionsContext'

const chainId = defaultChainId

const GammaAddModal = ({ isOpen, setIsOpen, position }) => {
  const [amount0, setAmount0] = useState('')
  const [amount1, setAmount1] = useState('')
  const [isBaseInput, setIsBaseInput] = useState(false)
  const { onGammaAdd, onGammaAddAndStake, pending } = useGammaAdd()
  const { type, address: pairAddress, token0, token1, account } = position
  const currency0 = useCurrency(token0.address)
  const currency1 = useCurrency(token1.address)
  const token0Balance = useCurrencyBalance(currency0)
  const token1Balance = useCurrencyBalance(currency1)
  const eth = useCurrency('ETH')
  const ethBalance = useCurrencyBalance(eth)
  const wethBalance = useCurrencyBalance(WETH_EXTENDED[chainId])
  const gammaUNIPROXYContract = useV3GammaUniproxy()
  const fusions = useContext(FusionsContext)
  const gamma = useMemo(() => {
    return fusions.find((ele) => ele.address.toLowerCase() === pairAddress.toLowerCase())
  }, [pairAddress, fusions])
  const amountA = useMemo(() => {
    return amount0 ? CurrencyAmount.fromRawAmount(currency0, JSBI.BigInt(toWei(amount0, currency0.decimals).toFixed(0))) : undefined
  }, [amount0, currency0])
  const amountB = useMemo(() => {
    return amount1 ? CurrencyAmount.fromRawAmount(currency1, JSBI.BigInt(toWei(amount1, currency1.decimals).toFixed(0))) : undefined
  }, [amount1, currency1])

  const isDouble0 = useMemo(() => {
    return currency0?.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()
  }, [currency0])

  const isDouble1 = useMemo(() => {
    return currency1?.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()
  }, [currency1])
  const maxAmount0 = isDouble0 ? new BigNumber(ethBalance.toExact()).plus(wethBalance.toExact()).minus(0.005) : new BigNumber(token0Balance.toExact())
  const maxAmount1 = isDouble1 ? new BigNumber(ethBalance.toExact()).plus(wethBalance.toExact()).minus(0.005) : new BigNumber(token1Balance.toExact())

  const depositAmountsData = useSingleContractMultipleData(
    gammaUNIPROXYContract,
    'getDepositAmount',
    [currency0, currency1].map((token) => [pairAddress, token.address, parseUnits('1', token.decimals)]),
  )

  const depositRange = useMemo(() => {
    if (depositAmountsData.length < 1) return
    const baseDepositMin =
      !depositAmountsData[1].loading && depositAmountsData[1].result && depositAmountsData[1].result.length > 1
        ? Number(formatUnits(depositAmountsData[1].result[0], currency0.decimals))
        : 0
    const baseDepositMax =
      !depositAmountsData[1].loading && depositAmountsData[1].result && depositAmountsData[1].result.length > 1
        ? Number(formatUnits(depositAmountsData[1].result[1], currency0.decimals))
        : 0
    const quoteDepositMin =
      !depositAmountsData[0].loading && depositAmountsData[0].result && depositAmountsData[0].result.length > 1
        ? Number(formatUnits(depositAmountsData[0].result[0], currency1.decimals))
        : 0
    const quoteDepositMax =
      !depositAmountsData[0].loading && depositAmountsData[0].result && depositAmountsData[0].result.length > 1
        ? Number(formatUnits(depositAmountsData[0].result[1], currency1.decimals))
        : 0

    return {
      base: { min: baseDepositMin, max: baseDepositMax },
      quote: { min: quoteDepositMin, max: quoteDepositMax },
    }
  }, [depositAmountsData, currency0.decimals, currency1.decimals])

  useEffect(() => {
    if (depositRange) {
      if (isBaseInput) {
        if (Number(amount0) > 0) {
          const quoteDeposit = ((depositRange.quote.min + depositRange.quote.max) / 2) * Number(amount0)
          setAmount1(quoteDeposit.toFixed(currency0.decimals))
        } else {
          setAmount1('')
        }
      } else {
        if (Number(amount1) > 0) {
          const baseDeposit = ((depositRange.base.min + depositRange.base.max) / 2) * Number(amount1)
          setAmount0(baseDeposit.toFixed(currency1.decimals))
        } else {
          setAmount0('')
        }
      }
    }
  }, [depositRange, amount0, amount1, isBaseInput, currency0.decimals, currency1.decimals])

  const amountToWrap = useMemo(() => {
    if (!currency0 || !currency1 || !amountA || !amountB) return
    if (currency0.isNative || currency0.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()) {
      if (wethBalance && JSBI.greaterThan(amountA.numerator, wethBalance.numerator)) {
        return JSBI.subtract(amountA.numerator, wethBalance.numerator)
      }
      return
    } else if (currency1.isNative || currency1.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()) {
      if (wethBalance && JSBI.greaterThan(amountB.numerator, wethBalance.numerator)) {
        return JSBI.subtract(amountB.numerator, wethBalance.numerator)
      }
      return
    }
    return
  }, [amountA, amountB, currency0, currency1, wethBalance])

  const errorMessage = useMemo(() => {
    if (!amountA || !amountB) {
      return 'Enter an amount'
    }

    if (amountA && maxAmount0.lt(amountA.toExact())) {
      return `Insufficient ${amountA.currency.symbol} balance`
    }

    if (amountB && maxAmount1.lt(amountB.toExact)) {
      return `Insufficient ${amountB.currency.symbol} balance`
    }
    return
  }, [amountA, amountB, maxAmount0, maxAmount1])

  const onAdd = useCallback(() => {
    if (errorMessage) {
      customNotify(errorMessage, 'warn')
      return
    }
    onGammaAdd(amountA, amountB, amountToWrap, gamma)
  }, [errorMessage, amountA, amountB, amountToWrap, gamma])

  const onAddLiquidityAndStake = useCallback(() => {
    if (errorMessage) {
      customNotify(errorMessage, 'warn')
      return
    }
    onGammaAddAndStake(amountA, amountB, amountToWrap, gamma)
  }, [errorMessage, amountToWrap, onGammaAddAndStake, amountA, amountB])

  return (
    <Modal popup={isOpen} setPopup={setIsOpen} title={'Add Liquidity'} width={588}>
      <div className='px-4 pt-5 pb-3 rounded-[5px] border border-themeOrange mt-[13px]'>
        <div className='flex items-start md:items-center justify-between'>
          <div className='flex items-center space-x-3 '>
            <div className='flex items-center'>
              <img alt='' className='w-6 lg:w-[30px] relative shadow' src={token0.logoURI} />
              <img alt='' className='w-6 lg:w-[30px] -ml-3' src={token1.logoURI} />
            </div>
            <p className='text-[13px] lg:text-[19px] f-f-fg font-semibold text-white'>
              {currency0.symbol}/{currency1.symbol}
            </p>
          </div>
          <div className='bg-white bg-opacity-[0.09] py-1 pl-3 md:mt-2 rounded-[13px] flex items-center space-x-[5px] pr-4 flex-shrink-0'>
            <span className='text-[15px] fonts-medium text-white whitespace-nowrap'>{type} range</span>
          </div>
        </div>
        <div className='mt-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-[5px]'>
              <img alt='' src={token0.logoURI} className={'w-[22px] md:w-6'} />
              <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>Pooled {currency0.symbol}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='text-[#DEDBF2] leading-5'>{formatAmount(account.total0)}</span>
            </div>
          </div>
          <div className='flex items-center justify-between mt-2'>
            <div className='flex items-center space-x-[5px]'>
              <img alt='' src={token1.logoURI} className={'w-[22px] md:w-6'} />
              <span className='text-[15px] md:text-lg leading-[18px] md:leading-[22px] text-white f-f-fg font-semibold'>Pooled {currency1.symbol}</span>
            </div>
            <div className='flex items-center space-x-2'>
              <span className='text-[#DEDBF2] leading-5'>{formatAmount(account.total1)}</span>
            </div>
          </div>
          <div className='mt-5'>
            <div className='mt-3'>
              <AddAmountCard
                currency={currency0}
                amount={amount0}
                setAmount={setAmount0}
                maxAmount={maxAmount0}
                balance={token0Balance}
                isBase={true}
                setIsBaseInput={setIsBaseInput}
              />
            </div>
            <div className='mt-5'>
              <AddAmountCard
                currency={currency1}
                amount={amount1}
                setAmount={setAmount1}
                maxAmount={maxAmount1}
                balance={token1Balance}
                isBase={false}
                setIsBaseInput={setIsBaseInput}
              />
            </div>
          </div>
          {gamma.gauge && gamma.gauge.address !== ZERO_ADDRESS && (
            <TransactButton
              onClickHandler={onAddLiquidityAndStake}
              disabled={pending}
              content={'ADD LIQUIDITY AND STAKE'}
              className='w-full mt-3 py-[13px] md:py-[14px] px-[19px] text-sm md:text-lg'
            />
          )}
          <TransactButton
            onClickHandler={onAdd}
            disabled={pending}
            content={'ADD LIQUIDITY'}
            className='w-full mt-3 py-[13px] md:py-[14px] px-[19px] text-sm md:text-lg'
          />
        </div>
      </div>
    </Modal>
  )
}

export default GammaAddModal
