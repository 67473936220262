import React, { useState, useMemo, useContext } from 'react'
import { FusionsContext } from '../../../../context/FusionsContext'
import AddLiquidity from '../addLiquidity'
import RemoveLiquidity from '../removeLiquidity'
import V3AddLiquidity from './addLiquidity'
import CommonHollowModal from '../../../common/CommonHollowModal'
import Tab from '../../../common/Tab'
import DepositModal from '../depositModal'

const DepositLpModal = ({ isOpen, setIsOpen, pair, hasStaked, hasLp }) => {
  const [staking, setStaking] = useState(hasLp || hasStaked)
  const isV1 = pair && pair.strategist === 'V1'
  // V1 parameters TODO: enhance this pls
  const [isAdd, setIsAdd] = useState(true)
  const slippage = 0.5
  const deadline = 20
  const fusions = useContext(FusionsContext)
  const pairs = useMemo(() => {
    return fusions.filter((fusion) => !fusion.isGamma)
  }, [fusions])

  return staking ? (
    <DepositModal isOpen={staking} setIsOpen={setIsOpen} setStaking={setStaking} pair={pair} stake={!hasStaked} />
  ) : isV1 ? (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage liquidity`}>
      <div className='flex flex-col w-full mt-2'>
        <div
          className='flex items-center justify-start space-x-2 mt-2 cursor-pointer w-fit mb-4'
          onClick={() => {
            setStaking(true)
          }}
        >
          <button className='w-4'>
            <img alt='' src='/images/swap/back-arrow.svg' />
          </button>
          <p className='text-white'>Go to staking</p>
        </div>
        <Tab leftTitle='Add' rightTitle='Remove' isLeft={isAdd} setIsLeft={setIsAdd} />
        {isAdd ? (
          <AddLiquidity slippage={slippage} deadline={deadline} pairs={pairs} pairAddress={pair.address} lite />
        ) : (
          <RemoveLiquidity slippage={slippage} deadline={deadline} pairs={pairs} pairAddress={pair.address} lite />
        )}
      </div>
    </CommonHollowModal>
  ) : (
    <CommonHollowModal popup={isOpen} width={588} setPopup={setIsOpen} title={`Manage liquidity`}>
      <V3AddLiquidity
        firstCurrency={pair.token0.address}
        secondCurrency={pair.token1.address}
        strategy={pair.title}
        pair={pair}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setStaking={setStaking}
        lite
      />
    </CommonHollowModal>
  )
}

export default DepositLpModal
