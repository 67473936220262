import React from 'react'

const PoolWarning = ({ text, setFullRangeWarningShown, isFull = false }) => {
  return (
    <div className='px-3 py-2 flex space-x-3 max-w-fit mt-1 md:mt-3'>
      <img alt='' src='/images/svgs/warning.svg' />
      <div className={`w-px bg-[#EDB831]`} />
      <div className='flex items-center space-x-2'>
        <p className='f-f-fg text-white text-sm'>{text}</p>
        {isFull && (
          <button
            onClick={() => {
              setFullRangeWarningShown(false)
            }}
          >
            <img alt='' src='/images/svgs/close-button.svg' />
          </button>
        )}
      </div>
    </div>
  )
}

export default PoolWarning
