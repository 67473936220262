import React, { useContext, useMemo, useState } from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import VeTHEPopup from '../../common/VeTHEPopup'
import { veTHEsContext } from '../../../context/veTHEsConetext'
import { useMerge } from '../../../hooks/useLock'
import BigNumber from 'bignumber.js'
import Warning from '../../common/Warning'
import { TransactButton } from '../../common/Buttons/transactButton'

const MergeTab = ({ selected }) => {
  const [veTHE, setVeTHE] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { veTHEs } = useContext(veTHEsContext)
  const filtered = useMemo(() => {
    return veTHEs.filter((item) => item.id !== selected.id && item.voting_amount.gt(0))
  }, [veTHEs])

  const { onMerge, pending } = useMerge()

  const errorMsg = useMemo(() => {
    if (!veTHE) {
      return { btn: 'SELECT veLYNX' }
    }
    if (selected.lockEnd === 0 && veTHE.lockEnd !== 0) {
      return { btn: 'NOT ALLOWED', warn: 'You cannot merge a permanently locked veLYNX into a date locked veLYNX' }
    }
    return null
  }, [veTHE])

  const votingPower = useMemo(() => {
    if (veTHE) {
      const end = Math.max(selected.lockEnd, veTHE.lockEnd)
      const current = new Date().getTime() / 1000
      return selected.amount
        .plus(veTHE.amount)
        .times(end - current)
        .div(86400 * 730)
    }
    return new BigNumber(0)
  }, [selected, veTHE])

  return (
    <>
      <div className='mt-1 md:mt-5 flex flex-col items-center justify-center'>
        <div className='w-full'>
          <div className='flex items-center justify-between'>
            <p className='text-white text-sm md:text-base leading-10'>veLYNX ID</p>
            <p className='text-white text-sm md:text-base leading-10'>veLYNX Balance: {formatAmount(selected.amount)}</p>
          </div>
          <div className='mt-1.5 lg:mt-2.5 p-px w-full'>
            <div className='bg-white/5 px-2.5 h-12 rounded-md flex items-center justify-between'>
              <p className='text-white text-md md:text-base font-medium'>{`Token #${selected.id}`}</p>
            </div>
          </div>
        </div>
        <button className='focus:outline-none mt-2 z-[8]'>
          <img src='/images/lock/arrow-down.svg' />
        </button>
      </div>
      <div className={`flex flex-col w-full items-center justify-center`}>
        <div className={`w-full mb-5`}>
          <div className='flex items-center justify-between'>
            <p className='text-white text-sm md:text-base'>Merge To</p>
            <p className='text-white text-sm md:text-base'>veLYNX Balance: {veTHE ? formatAmount(veTHE.amount) : '-'}</p>
          </div>
          <div className={`mt-1.5 md:mt-2.5 p-px w-full relative`}>
            <div className='bg-white/5 h-12 rounded-md flex'>
              <div
                onClick={() => {
                  if (filtered && filtered.length > 0) {
                    setIsOpen(true)
                  }
                }}
                className={`flex items-center justify-between bg-transparent w-full h-full cursor-pointer flex relative z-10 px-2.5 lg:px-4`}
              >
                <p className={`text-white text-md md:text-base md:leading-10`}>{veTHE ? 'Token #' + veTHE.id : filtered.length > 0 ? 'Select' : 'Not Found'}</p>
                <img
                  className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
                  alt=''
                  src='/images/swap/dropdown-arrow.png'
                />
              </div>
            </div>
            <VeTHEPopup popup={isOpen} setPopup={setIsOpen} setSelectedVeTHE={setVeTHE} veTHEs={filtered} />
          </div>
        </div>
      </div>
      {veTHE && (
        <div className='text-sm lg:text-lg mb-[9px] flex items-center justify-between'>
          <span className='text-white font-light'>Token #{veTHE.id} veLYNX Balance:</span>
          <div className='flex sm:space-x-2 items-center flex-wrap'>
            <span className='text-white font-medium'>{formatAmount(selected?.amount.plus(veTHE?.amount))}</span>
            <span className='text-dimGray lg:text-sm font-light'>(+{formatAmount(votingPower.minus(veTHE.voting_amount))} voting power)</span>
          </div>
        </div>
      )}
      <TransactButton
        disabled={errorMsg || pending}
        pending={pending}
        onClickHandler={() => {
          onMerge(selected, veTHE)
        }}
        content={errorMsg?.btn || 'MERGE'}
        className='w-full text-base md:text-lg py-3 px-4 mt-4'
      />
      <Warning text={errorMsg?.warn || 'Merging/splitting will cause a loss of unclaimed and pending rewards, make sure to claim everything behorehand.'} />
    </>
  )
}

export default MergeTab
