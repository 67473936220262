import React, { useContext, useMemo, useState } from 'react'
import '../../dashboard.scss'
import { useWeb3Wagmi } from '../../../../../hooks/useWeb3'
import { FusionsContext } from '../../../../../context/FusionsContext'
import { useV3Positions } from '../../../../../hooks/v3/useV3Positions'
import Pool from './pool'
import Spinner from '../../../../common/Spinner'
import SortToggle from './sortToggle'

const Positions = () => {
  const { account } = useWeb3Wagmi()
  const [sortAuto, setSortAuto] = useState({
    label: 'Staked',
    value: 'stake',
  })
  const fusions = useContext(FusionsContext)
  const fusionsLoaded = fusions?.length > 0
  const automaticPositions = fusions.filter((pos) => pos.account.totalLp.gt(0))
  const manualPositions = useV3Positions(account)

  const sortedAutomatic = useMemo(() => {
    return automaticPositions.sort((a, b) => {
      let res
      switch (sortAuto.value) {
        case 'apr':
          res = a.gauge.apr.minus(b.gauge.apr).times(-1).toNumber()
          break
        case 'stake':
          res = a.account.stakedUsd.minus(b.account.stakedUsd).times(-1).toNumber()
          break
        default:
          break
      }
      return res
    })
  }, [automaticPositions])

  const fusionsPositions = sortedAutomatic.filter((pos) => pos.isFusion)
  const classicPositions = sortedAutomatic.filter((pos) => !pos.isFusion)
  const noAutomatic = fusionsLoaded && automaticPositions?.length === 0
  const noFusions = fusionsLoaded && fusionsPositions?.length === 0
  const noClassics = fusionsLoaded && classicPositions?.length === 0
  const noManual = fusionsLoaded && manualPositions?.length === 0

  return (
    <div className='section mt-2'>
      <div className='flex flex-col my-3'>
        <div className='flex w-full justify-between items-center mb-4'>
          <p className='title !mb-0'>Positions</p>
          <SortToggle sort={sortAuto} setSort={setSortAuto} />
        </div>
        {fusions.length === 0 ? (
          <div className='flex flex-col space-y-2 w-full justify-center items-center'>
            <Spinner size={32} />
            <p className='text-dimGray text-xs tracking-wider'>Loading...</p>
          </div>
        ) : (
          <>
            {noAutomatic ? (
              <p className='text-dimGray text-xs tracking-wider'>You don't have any automatic positions</p>
            ) : (
              <>
                <p className='subtitle mb-2'>Fusions</p>
                {noFusions ? (
                  <p className='text-dimGray text-xs tracking-wider'>You don't have any fusion positions</p>
                ) : (
                  <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
                    {fusionsPositions.map((pool, id) => {
                      return <Pool position={pool} auto key={id} />
                    })}
                  </div>
                )}
                <p className='subtitle mt-4 mb-2'>Classic</p>
                {noClassics ? (
                  <p className='text-dimGray text-xs tracking-wider'>You don't have any classic positions</p>
                ) : (
                  <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
                    {classicPositions.map((pool, id) => {
                      return <Pool position={pool} auto key={id} />
                    })}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <div className='flex flex-col my-3'>
        <p className='title'>Manual positions</p>
        {fusions.length === 0 ? (
          <div className='flex flex-col space-y-2 w-full justify-center items-center'>
            <Spinner size={32} />
            <p className='text-dimGray text-xs tracking-wider'>Loading...</p>
          </div>
        ) : (
          <>
            {noManual ? (
              <p className='text-dimGray text-xs tracking-wider'>You don't have any manual positions</p>
            ) : (
              <div className='grid grid-cols-1 md:grid-cols-4 auto-cols-max gap-4'>
                {manualPositions.map((pool, id) => {
                  return <Pool position={pool} manual key={id} />
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Positions
