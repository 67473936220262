import React from 'react'

import './swap.scss'

const PreSelection = ({ assets, fromAsset, toAsset, setSelectedAsset }) => {
  return (
    <div className='pre-selection-wrapper'>
      {assets &&
        assets
          .filter((a) => a.address !== fromAsset?.address && a.address !== toAsset?.address)
          .map((item, idx) => {
            return (
              <div
                key={idx}
                className='asset'
                onClick={() => {
                  setSelectedAsset(item)
                }}
              >
                <img alt='' src={item.logoURI} loading='lazy' />
              </div>
            )
          })}
    </div>
  )
}

export default PreSelection
