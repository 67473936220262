import { useCallback, useState, useEffect } from 'react'
import useWeb3, { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { getMerkleTreeKeyContract } from '../../../../utils/contractHelpers'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { completeTransaction, openTransaction } from '../../../../state/transactions/actions'
import { TransactionType } from '../../../../config/constants'
import { sendContract } from '../../../../utils/api'
import { BigNumber } from 'ethers'

export const useAirdropClaimKey = () => {
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)
  const [hasClaimed, setHasClaimed] = useState(false)
  const web3 = useWeb3()

  const checkClaimStatus = useCallback(async () => {
    if (!account) return
    const merkleTreeKeyContract = getMerkleTreeKeyContract(web3)
    try {
      const claimedStatus = await merkleTreeKeyContract.methods.hasClaimed(account).call()
      setHasClaimed(claimedStatus)
    } catch (err) {
      console.error('Error fetching claim status: ', err)
    }
  }, [account, web3])

  const handleClaim = useCallback(
    async (amount, keyTier, proof) => {
      if (hasClaimed) {
        console.log('Already claimed')
        return
      }

      const key = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim earnings`,
          transactions: {
            [harvestuuid]: {
              desc: `Claim your earnings`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const merkleTreeKeyContract = getMerkleTreeKeyContract(web3)
      setPending(true)
      try {
        const params = [account, BigNumber.from(amount.toString()), keyTier, proof]
        await sendContract(dispatch, key, harvestuuid, merkleTreeKeyContract, 'claim', params, account)
      } catch (err) {
        console.log('claim error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Airdrop Claimed',
        }),
      )
      setPending(false)
      checkClaimStatus() // Update claim status after successful claim
    },
    [account, web3, hasClaimed, checkClaimStatus],
  )

  // Fetch claim status on hook mount or account change
  useEffect(() => {
    checkClaimStatus()
  }, [checkClaimStatus])

  return { onClaim: handleClaim, pending, hasClaimed }
}
