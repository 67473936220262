import React, { useMemo } from 'react'
import { useEpochTimer /* useSupply */ } from '../../../../hooks/useGeneral'
import { useAnalyticsVersion } from '../../../../hooks/useGeneral'
import { useGlobalData } from '../../../../context/GlobalData'
import usePrices from '../../../../hooks/usePrices'
import { formatAmount } from '../../../../utils/formatNumber'
import './style.scss'

const Details = () => {
  const prices = usePrices()
  const { days, hours, mins, epoch } = useEpochTimer()
  const version = useAnalyticsVersion()
  const globalData = useGlobalData(version)
  // const { lockedSupply, circSupply } = useSupply()
  const tvl = useMemo(() => {
    return Object.keys(globalData).length > 0 ? globalData.totalLiquidityUSD : 0
  }, [globalData])
  const volume = useMemo(() => {
    return Object.keys(globalData).length > 0 ? globalData.totalVolume : 0
  }, [globalData])

  const details = useMemo(() => {
    return [
      {
        title: 'TOTAL VALUE LOCKED',
        value: globalData && tvl !== 0 ? `$ ${formatAmount(tvl, true)}` : '-',
      },
      {
        title: 'TOTAL Trading Volume',
        value: globalData && volume !== 0 ? `$ ${formatAmount(volume, true)}` : '-',
      },
      // {
      //   title: 'CIRCULATING MARKETCAP',
      //   value: prices ? `$${formatAmount(prices['LYNX'] * circSupply)}` : '-',
      // },
      // {
      //   title: 'TOTAL LOCKED LYNX',
      //   value: `${lockedSupply ? formatAmount(lockedSupply, true) : '-'}`,
      // },
      {
        title: `EPOCH ${epoch + 1} STARTS IN`,
        value: `${days}d ${hours}h ${mins}m`,
      },
    ]
  }, [prices, tvl, globalData, mins])

  return (
    <div className='details-container'>
      <div className='details-body container-4'>
        {details.map((item, index) => {
          return (
            <div className='details-item' key={`details-${index}`}>
              <div className='details-item-value'>{item.value}</div>
              <div className='details-item-title'>{item.title}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Details
