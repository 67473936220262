import React, { useMemo, useState } from 'react'
import PriceBox from '../../../../../common/PriceBox'
import useIsTickAtLimit from '../../../../../../hooks/v3/useIsTickAtLimit'
import { useInverter } from '../../../../../../hooks/v3/useInverter'
import { Bound } from '../../../../../../state/mintV3/actions'
import { formatTickPrice } from '../../../../../../v3lib/utils/formatTickPrice'
import { useGetPriceOrderingFromPositionForUI } from '../algebraPosition'
import { Position } from '../../../../../../v3lib/entities'
import { useToken } from '../../../../../../hooks/v3/Tokens'
import { formatAmount } from '../../../../../../utils/formatNumber'

const MinPrice = (value, symbol0, symbol1, inRange) => {
  return (
    <div className='mt-2 p-1'>
      <p className='text-sm leading-5 md:text-xl text-white font-medium'>{value > 1 ? formatAmount(value, true, 3) : Number(value)}</p>
      <p className='text-xs text-[#B8B6CB]'>
        {symbol0} per {symbol1}
      </p>
      {inRange && <p className='text-[#B8B6CB] max-w-[200px] w-full mt-1 font-light text-xs italic'>Your position will be 100% {symbol1} at this price.</p>}
    </div>
  )
}
const MaxPrice = (value, symbol0, symbol1, inRange) => {
  return (
    <div className='mt-2 p-1'>
      <p className='text-sm leading-5 md:text-xl text-white font-medium'>{value > 1 ? formatAmount(value, true, 3) : Number(value)}</p>
      <p className='text-xs text-[#B8B6CB]'>
        {symbol0} per {symbol1}
      </p>
      {inRange && <p className='text-[#B8B6CB] max-w-[200px] w-full mt-1 font-light text-xs italic'>Your position will be 100% {symbol0} at this price.</p>}
    </div>
  )
}
const CurrentPrice = (value, symbol0, symbol1) => {
  return (
    <div className='mt-2'>
      <p className='text-base md:text-2xl text-white font-medium'>{value > 1 ? formatAmount(value, false, 3) : value}</p>
      <p className='text-xs md:text-sm text-[#B8B6CB] mt-2'>
        {symbol0} per {symbol1}
      </p>
    </div>
  )
}

const SelectedRange = ({ pool, positionDetails, currency0, currency1 }) => {
  const [manuallyInverted, setManuallyInverted] = useState(false)
  const { token1: _token1Address, liquidity: _liquidity, tickLower: _tickLower, tickUpper: _tickUpper } = positionDetails

  const position = useMemo(() => {
    if (pool && _liquidity && typeof _tickLower === 'number' && typeof _tickUpper === 'number') {
      return new Position({
        pool: pool,
        liquidity: _liquidity.toString(),
        tickLower: _tickLower,
        tickUpper: _tickUpper,
      })
    }
    return undefined
  }, [_liquidity, pool, _tickLower, _tickUpper])

  const tickAtLimit = useIsTickAtLimit(_tickLower, _tickUpper, pool?.tickSpacing)

  const pricesFromPosition = useGetPriceOrderingFromPositionForUI(position)

  // handle manual inversion
  const { priceLower, priceUpper, base } = useInverter({
    priceLower: pricesFromPosition?.priceLower,
    priceUpper: pricesFromPosition?.priceUpper,
    quote: pricesFromPosition?.quote,
    base: pricesFromPosition?.base,
    invert: manuallyInverted,
  })

  const token1 = useToken(_token1Address)
  const isCurrencyReverted = pricesFromPosition?.base?.equals(token1)
  const inverted = token1 ? base?.equals(token1) : undefined
  const currencyQuote = inverted ? currency0 : currency1
  const currencyBase = inverted ? currency1 : currency0

  // check if price is within range
  const below = pool && typeof _tickLower === 'number' ? pool.tickCurrent < _tickLower : undefined
  const above = pool && typeof _tickUpper === 'number' ? pool.tickCurrent >= _tickUpper : undefined
  const inRange = typeof below === 'boolean' && typeof above === 'boolean' ? !below && !above : false

  return (
    <>
      <div className='flex items-center mt-[18px] justify-between space-x-3'>
        <span className='text-sm md:text-base leading-4 md:leading-5 w-1/2 md:w-auto font-medium text-[#B8B6CB]'>Selected Range</span>
        <div className='flex h-7 md:h-9 w-1/2 md:max-w-[160px] bg-white/5 rounded-xl relative'>
          <div className={`absolute w-1/2 ${manuallyInverted ? 'left-0 -ml-[2px]' : '-mr-[2px] right-0'} h-[29px] md:h-[37px] -mt-[2px]`} />
          <div
            onClick={() => {
              setManuallyInverted(true)
            }}
            className={`w-1/2 h-full flex text-[13px] md:text-base flex-col items-center justify-center cursor-pointer relative z-10 ${
              manuallyInverted ? 'font-semibold text-white' : 'text-[#A2A0B7]'
            } rounded-[5px]`}
          >
            {isCurrencyReverted ? currency0.symbol : currency1.symbol}
          </div>
          <div
            onClick={() => {
              setManuallyInverted(false)
            }}
            className={`w-1/2 h-full flex text-[13px] md:text-base flex-col items-center justify-center  cursor-pointer relative z-10 ${
              !manuallyInverted ? 'font-semibold text-white' : 'text-[#A2A0B7]'
            } rounded-[5px]`}
          >
            {isCurrencyReverted ? currency1.symbol : currency0.symbol}
          </div>
        </div>
      </div>
      <div className='flex items-center space-x-3 mt-3'>
        {priceLower && (
          <PriceBox
            content={MinPrice(formatTickPrice(priceLower, tickAtLimit, Bound.LOWER), currencyQuote?.symbol, currencyBase?.symbol, inRange)}
            range={'Min'}
            className='w-full'
          />
        )}
        {priceUpper && (
          <PriceBox
            content={MaxPrice(formatTickPrice(priceUpper, tickAtLimit, Bound.LOWER), currencyQuote?.symbol, currencyBase?.symbol, inRange)}
            range={'Max'}
            className='w-full'
          />
        )}
      </div>
      {pool && (
        <PriceBox
          content={CurrentPrice((inverted ? pool.token1Price : pool.token0Price).toSignificant(6), currencyQuote?.symbol, currencyBase?.symbol)}
          range={'Current'}
          className='w-full mt-3'
        />
      )}
    </>
  )
}

export default SelectedRange
