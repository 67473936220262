import React, { useState, useEffect } from 'react'
import useWalletModal from '../../../../hooks/useWalletModal'
import StyledButton from '../../../common/Buttons/styledButton'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { BIG_ZERO, formatAmount } from '../../../../utils/formatNumber'
import { getLynxClaimTokenAddress } from '../../../../utils/addressHelpers'
import { fetchImportedToken } from '../../../../utils/fetchImportToken'
import Skeleton from '../../../common/Spinner/skeleton'
import '../../claim/dist/dist.scss'
import { useLynxClaim } from '../hooks/useLynxClaim'

const Distribution = () => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const lynxClaimTokenAddress = getLynxClaimTokenAddress()
  const [claimBalance, setClaimBalance] = useState(BIG_ZERO)
  const [loading, setLoading] = useState(true)
  const { onClaim, pending } = useLynxClaim(account)
  const disable = claimBalance?.eq(BIG_ZERO) || pending

  useEffect(() => {
    if (account) getData()
  }, [account])

  const handleClaim = async () => {
    await onClaim(claimBalance)
    getData()
  }

  const getData = async () => {
    setLoading(true)
    const asset = await fetchImportedToken(lynxClaimTokenAddress, account)
    setClaimBalance(asset.balance)
    setLoading(false)
  }

  return (
    <div className='dist'>
      <p className='title'>Token distribution</p>
      {loading ? (
        <Skeleton />
      ) : claimBalance?.gt(0) ? (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className='row'>{formatAmount(claimBalance || 0)} CLAIM LYNX TOKENS INTO</p>
          <p className='row'>{formatAmount(claimBalance.div(2) || 0)} oLYNX</p>
          <p className='row'>{formatAmount(claimBalance.div(2) || 0)} veLYNX locked for 2 years </p>
        </div>
      ) : (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className='row'>No tokens to claim</p>{' '}
        </div>
      )}
      {account ? (
        <StyledButton
          disabled={disable}
          content={'CLAIM'}
          className='action-button'
          onClickHandler={() => {
            handleClaim()
          }}
        />
      ) : (
        <StyledButton
          onClickHandler={() => {
            openWalletModal()
          }}
          content={'CONNECT WALLET'}
          className='action-button'
        />
      )}
    </div>
  )
}

export default Distribution
