import React, { useEffect, useState } from 'react'
import OtokenInput from '../../common/Input/OtokenInput'
import StyledButton from '../../common/Buttons/styledButton'
import AddToMetamask from '../../common/Buttons/addToMetamask'
import useWalletModal from '../../../hooks/useWalletModal'
import { getbveLynxAddress } from '../../../utils/addressHelpers'
import { formatAmount } from '../../../utils/formatNumber'
import { useExcercisebveLynx } from '../../../hooks/useExercise'
// import { formatUnits } from 'ethers/lib/utils'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import Toggle from '../../common/Toggle'
import VeTHESelect from '../../common/VeTHESelect'
import './otoken.scss'
import '../swap/swap.scss'
import { TransactButton } from '../../common/Buttons/transactButton'

const bveLynx = ({ baseAssets }) => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const [redeemAmount, setRedeemAmount] = useState('')
  const [redeemAsset, setRedeemAsset] = useState(null)
  const [veTHE, setVeTHE] = useState(null)
  const [choseVeLynx, setChoseVeLynx] = useState(false)
  const { onExcercisebveLynx, handleExcerciseBveLynxAndMerge } = useExcercisebveLynx()
  const notAllowed = !account || !redeemAsset || redeemAsset?.balance === 0 || redeemAmount === '' || redeemAmount === '0'
  const date = new Date()
  const unlockAt = new Date(date.getFullYear() + 2, date.getMonth(), date.getDate())

  useEffect(() => {
    if (!baseAssets || baseAssets.length === 0) return
    const bveLynxToken = baseAssets.find((asset) => asset.address.toLowerCase() === getbveLynxAddress()?.toLowerCase())
    setRedeemAsset(bveLynxToken)
  }, [baseAssets])

  return (
    <div className='wrapper-otoken'>
      <p className='option-info'>Redeem bveLYNX (bribe veLYNX) for veLYNX</p>
      <div className='fromto'>
        <OtokenInput
          asset={redeemAsset}
          setAsset={setRedeemAsset}
          amount={redeemAmount}
          onInputChange={(val) => {
            setRedeemAmount(val)
          }}
          title='Use'
          noPrice
        />
      </div>
      <div className='fromto space-y-2'>
        <div className='line'>
          <p className='title'>Your voting power will be</p>
          <p className='value'>{formatAmount(redeemAmount, false, 2)} veLYNX</p>
        </div>
        <div className='line'>
          <p className='title'>Unlocking at</p>
          <p className='value'>{unlockAt.toLocaleDateString()}</p>
        </div>
        <div className='line'>
          <p className='title'>Chose target veLynx</p>
          <Toggle checked={choseVeLynx} onChange={() => setChoseVeLynx(!choseVeLynx)} toggleId='choseVeLynx' />
        </div>
        {choseVeLynx && (
          <div className='flex flex-col gap-2'>
            <VeTHESelect className={'w-full'} small isSelected={veTHE} setIsSelected={setVeTHE} />
            <p className='text-white bg-warn/40 text-sm italic p-2 rounded-md'>
              Merging into veLYNX will make the resulting unlock time: {unlockAt.toLocaleDateString()}
            </p>
          </div>
        )}
      </div>
      {account ? (
        <TransactButton
          content='REDEEM INTO veLYNX'
          disabled={notAllowed}
          onClickHandler={() => {
            if (choseVeLynx && veTHE?.id) {
              handleExcerciseBveLynxAndMerge(redeemAsset, redeemAmount, redeemAmount, veTHE.id)
            } else onExcercisebveLynx(redeemAsset, redeemAmount, redeemAmount)
          }}
          className='w-full text-base md:text-lg py-3 px-4'
        />
      ) : (
        <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='w-full text-base md:text-lg py-3 px-4' />
      )}
      {!choseVeLynx && (
        <p className='text-[14px] text-white italic'>
          Redeeeming into veLYNX will create you a new locked veNFT. It is possible to merge it into single veNFT on the Lock page after redemption.
        </p>
      )}
      {account && (
        <div className='flex'>
          <AddToMetamask
            text='Add bveLYNX to Metamask'
            tokenAddress={redeemAsset?.address}
            tokenSymbol={redeemAsset?.symbol}
            tokenDecimals={redeemAsset?.decimals}
            imgUrl={redeemAsset?.logoURI}
          />
        </div>
      )}
    </div>
  )
}

export default bveLynx
