import React, { useMemo, useState } from 'react'
import TransparentButton from '../../components/common/Buttons/transparentButton'
import { useNavigate } from 'react-router-dom'
import { PoolTypes } from '../../config/constants'
import FarmTable from '../../components/pages/liquidity/farmTable'
import { usePollFarmsData } from '../../hooks/v3/useFarms'
import { getTimePeriods, useTimestamp } from '../../utils/time'
import { useWeb3Wagmi } from '../../hooks/useWeb3'
import KeyProgressBar from '../../components/pages/farm/keyProgressBar/keyProgressBar'

const sortOptions = [
  {
    label: 'Rewards',
    value: 'rewardsPerSecond',
    isDesc: true,
  },
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Staked',
    value: 'tvl',
    isDesc: true,
  },
  {
    label: 'My Stake',
    value: 'stake',
    isDesc: true,
  },
  {
    label: 'Earnings',
    value: 'earnings',
    isDesc: true,
  },
]

const defaultSort = { label: 'APR', value: 'apr', isDesc: true }

const Index = () => {
  const [filter] = useState(PoolTypes.ALL)
  // const [mobileFilter, setMobileFilter] = useState(false)
  // const [isStaked, setIsStaked] = useState(false)
  // const [isInactive, setIsInactive] = useState(false)
  const [searchText] = useState('')
  const [sort, setSort] = useState(defaultSort)
  const [sortP2, setSortP2] = useState(defaultSort)
  const [sortIchi, setSortIchi] = useState(defaultSort)
  const { account } = useWeb3Wagmi()
  const { fetchedFarms, fetchedFarmsP2, fetchedFarmsIchi } = usePollFarmsData(account)

  const filteredPairs = useMemo(() => {
    const result = fetchedFarms
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.stakeToken.name.replace('/', ' ')
        const withComma = item.stakeToken.name.replace('/', ',')
        return (
          item.stakeToken.name.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [fetchedFarms, filter, searchText])

  const sortedPairs = useMemo(() => {
    return filteredPairs.sort((a, b) => {
      let res
      switch (sort.value) {
        case 'rewardsPerSecond':
          res = (a.rewardsPerSecond - b.rewardsPerSecond) * (sort.isDesc ? -1 : 1)
          break
        case 'tvl':
          res = (a.totalStakedUsd?.toNumber() - b.totalStakedUsd?.toNumber()) * (sort.isDesc ? -1 : 1)
          break
        case 'stake':
          res = (a.userStakedUsd?.toNumber() - b.userStakedUsd?.toNumber()) * (sort.isDesc ? -1 : 1)
          break
        case 'apr':
          res = (a.apr - b.apr) * (sort.isDesc ? -1 : 1)
          break
        case 'earnings':
          res = (a.userInfo?.earnings - b.userInfo?.earnings) * (sort.isDesc ? -1 : 1)
          break
        default:
          break
      }
      return res
    })
    // return filteredPairs
  }, [filteredPairs, sort])

  const filteredPairsP2 = useMemo(() => {
    const result = fetchedFarmsP2
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.stakeToken.name.replace('/', ' ')
        const withComma = item.stakeToken.name.replace('/', ',')
        return (
          item.stakeToken.name.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [fetchedFarms, filter, searchText])

  const sortedPairsP2 = useMemo(() => {
    return filteredPairsP2.sort((a, b) => {
      let res
      switch (sortP2.value) {
        case 'rewardsPerSecond':
          res = (a.rewardsPerSecond - b.rewardsPerSecond) * (sortP2.isDesc ? -1 : 1)
          break
        case 'tvl':
          res = (a.totalStakedUsd?.toNumber() - b.totalStakedUsd?.toNumber()) * (sortP2.isDesc ? -1 : 1)
          break
        case 'stake':
          res = (a.userStakedUsd?.toNumber() - b.userStakedUsd?.toNumber()) * (sortP2.isDesc ? -1 : 1)
          break
        case 'apr':
          res = (a.apr - b.apr) * (sortP2.isDesc ? -1 : 1)
          break
        case 'earnings':
          res = (a.userInfo?.earnings - b.userInfo?.earnings) * (sortP2.isDesc ? -1 : 1)
          break
        default:
          break
      }
      return res
    })
    // return filteredPairs
  }, [filteredPairsP2, sortP2])

  const filteredPairsIchi = useMemo(() => {
    const result = fetchedFarmsIchi
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.stakeToken.name.replace('/', ' ')
        const withComma = item.stakeToken.name.replace('/', ',')
        return (
          item.stakeToken.name.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [fetchedFarms, filter, searchText])

  const sortedPairsIchi = useMemo(() => {
    return filteredPairsIchi.sort((a, b) => {
      let res
      switch (sortIchi.value) {
        case 'rewardsPerSecond':
          res = (a.rewardsPerSecond - b.rewardsPerSecond) * (sortIchi.isDesc ? -1 : 1)
          break
        case 'tvl':
          res = (a.totalStakedUsd?.toNumber() - b.totalStakedUsd?.toNumber()) * (sortIchi.isDesc ? -1 : 1)
          break
        case 'stake':
          res = (a.userStakedUsd?.toNumber() - b.userStakedUsd?.toNumber()) * (sortIchi.isDesc ? -1 : 1)
          break
        case 'apr':
          res = (a.apr - b.apr) * (sortIchi.isDesc ? -1 : 1)
          break
        case 'earnings':
          res = (a.userInfo?.earnings - b.userInfo?.earnings) * (sortIchi.isDesc ? -1 : 1)
          break
        default:
          break
      }
      return res
    })
    // return filteredPairs
  }, [filteredPairsIchi, sortIchi])

  const navigate = useNavigate()

  const handlePopup = () => {
    navigate('/liquidity/fusion')
  }

  // const startTimestamp = fetchedFarms[0]?.startTime ?? 1692367200
  const currentTimestamp = useTimestamp()
  // const secondsUntilStart = Math.max(startTimestamp - currentTimestamp, 0)
  // const secondsRemaining = Math.max(fetchedFarms[0]?.endTime - currentTimestamp, 0)
  // const hasPoolStarted = secondsUntilStart === 0 && secondsRemaining > 0

  // const timer = !hasPoolStarted ? getTimePeriods(secondsUntilStart) : getTimePeriods(secondsRemaining)

  const startTimestampP2 = fetchedFarmsP2[0]?.startTime ?? 1694131200
  const secondsUntilStartP2 = Math.max(startTimestampP2 - currentTimestamp, 0)
  const secondsRemainingP2 = Math.max(fetchedFarmsP2[0]?.endTime - currentTimestamp, 0)
  const hasPoolStartedP2 = secondsUntilStartP2 === 0 && secondsRemainingP2 > 0

  const timerP2 = !hasPoolStartedP2 ? getTimePeriods(secondsUntilStartP2) : getTimePeriods(secondsRemainingP2)

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-20 pt-[160px] mx-auto'>
        <div className='lg:flex items-end justify-between'>
          <div className='w-full lg:w-[60%]'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white f-f-fg'>Pre Mining</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Pair your tokens to provide liquidity. Stake the LP tokens to earn fees and participate in early LYNX mining.{' '}
              <a href='https://lynex.gitbook.io/lynex-docs/launch-details#5347' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-3'>
              Show your commitment by staking from 40 to 60 consecutive days and earn Lynex Key NFTs for boosted oToken rewards and future benefits.{' '}
              <a href='https://medium.com/@lynexfi/advancing-into-phase-3-of-pre-mining-96377bcc16f6' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
            <h1 className='text-[26px] md:text-[32px] font-semibold text-white f-f-fg'>
              {hasPoolStartedP2 ? 'End' : 'Start'} in: {`${timerP2.days + timerP2.months * 30}d, ${timerP2.hours}h, ${timerP2.minutes}m`}
            </h1>
          </div>

          <div className='mt-3 lg:mt-0 w-full lg:w-auto'>
            <div className='flex flex-col items-center w-full lg:max-w-[290px] mx-auto'>
              <TransparentButton
                content={'ADD/REMOVE LIQUIDITY'}
                className='h-[42px] px-7 text-white w-full lg:max-w-[290px] text-sm lg:text-[14px] tracking-[1.04px] xl:tracking-[1.36px] flex items-center justify-center'
                onClickHandler={handlePopup}
              />
              <KeyProgressBar />
            </div>
          </div>
        </div>
        <FarmTable pairsData={sortedPairsP2} sort={sortP2} setSort={setSortP2} sortOptions={sortOptions} filter={filter} searchText={searchText} />
        <div className='lg:flex items-end justify-between'>
          <div className='w-full'>
            <h1 className='text-[26px] md:text-[32px] font-semibold text-white f-f-fg mt-6'>Single Token Vaults</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Deposit single sided liquidity. Stake the LP tokens to earn fees.{' '}
              <a href='https://docs.ichi.org/home/yield-iq/algorithm-design' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
            <p className='text-white bg-themeOrange text-[12px] leading-[22px] md:leading-6 mt-2 p-4 rounded'>
              ICHI vaults enable users to contribute a single token to a Liquidity Pool (LP). The primary goal of this strategy is to grow your initial deposit,
              maintaining its share within the range of [65% - 95%] to minimize exposure to the other token in the LP. Profit is achieved by strategically
              selling during upward market movements to augment the original deposit. Upon withdrawal, users may receive a portion of their funds as the other
              token in the LP.
            </p>
          </div>
        </div>
        <FarmTable pairsData={sortedPairsIchi} sort={sortIchi} setSort={setSortIchi} sortOptions={sortOptions} filter={filter} searchText={searchText} />
        <div className='lg:flex items-end justify-between'>
          <div className='max-w-[425px] w-full'>
            <h1 className='text-[26px] md:text-[32px] font-semibold text-white f-f-fg mt-6'>Phase 1 ended</h1>
          </div>
        </div>
        <FarmTable pairsData={sortedPairs} sort={sort} setSort={setSort} sortOptions={sortOptions} filter={filter} searchText={searchText} />
      </div>
    </>
  )
}

export default Index
