import React, { useState } from 'react'
import { PoolBadge } from './poolBadge'
import { getBadgesForPool } from '../../../config/constants/poolBadgesConfig'

export const BadgeContainer = ({ item }) => {
  const badges = getBadgesForPool(item.address)
  const [activeBadgeName, setActiveBadgeName] = useState(null)
  const [activeBadgeLink, setActiveBadgeLink] = useState(null)

  const handleBadgeClick = (name, link) => {
    if (activeBadgeName === name) {
      setActiveBadgeName(null)
      setActiveBadgeLink(null)
    } else {
      setActiveBadgeName(name)
      setActiveBadgeLink(link)
    }
  }

  return (
    badges.length > 0 && (
      <div className='flex flex-col space-y-2 mt-2'>
        <div className='flex space-x-1 items-center'>
          <span className='text-xs opacity-75'>Points:</span>

          {badges.map((badge, index) => (
            <PoolBadge
              key={`${item.address}-${index}`}
              index={index}
              name={badge.name}
              logo={badge.logo}
              link={badge.link}
              address={item.address}
              onBadgeClick={handleBadgeClick} // Pass the click handler to each badge
            />
          ))}
        </div>
        {activeBadgeName && (
          <div className='text-sm w-[200%] md:w-auto'>
            <span className=' opacity-75'> LPs in this pool also earn</span>
            <br />
            <span>{activeBadgeName} </span>
            <br />
            <a href={activeBadgeLink} target='_blank' rel='noopener noreferrer' className='text-themeOrange font-bold hover:text-themeOrangeLight'>
              Learn more
            </a>
          </div>
        )}
      </div>
    )
  )
}
