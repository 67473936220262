import React from 'react'

const CommonHollowModal = ({ popup, setPopup, title, width = 588, children }) => {
  return (
    <>
      <div
        onClick={() => {
          setPopup(false)
        }}
        className={`fixed ${
          popup ? 'visible z-[150] opacity-100' : 'z-0 invisible opacity-0'
        } top-0 left-0 w-[100vw] h-[100vh] transition-all duration-300 ease-in-out bg-black bg-opacity-[0.4] backdrop-blur-[6px]`}
      ></div>
      <div
        className={`${
          popup ? 'z-[151] visible opacity-100' : 'z-0 invisible opacity-0'
        } w-full inset-0 fixed transition-all duration-300 ease-in-out flex items-center min-h-screen justify-center flex-col paraent`}
      >
        <div
          className={`max-w-[92%] ${width === 588 ? ' sm:min-w-[500px] sm:max-w-[500px] md:min-w-[588px] md:max-w-[588px]' : ''} ${
            width === 540 ? ' md:min-w-[540px] max-w-[540px]' : ''
          } mx-auto w-full rounded-[20px] p-px`}
        >
          <div className='border border-[#ffffff33] bg-white/10 backdrop-blur-2xl rounded-[20px] px-4 py-4 md:px-6 md:py-5'>
            <div className='flex items-center justify-between'>
              <p className='text-[1.15rem] md:text-[27px] f-f-fg text-white font-medium'>{title}</p>
              <button onClick={() => setPopup(false)} className='focus:outline-none'>
                <img alt='' src='/images/common/close-button1.svg' />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default CommonHollowModal
