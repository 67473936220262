// CedeStoreWidget.js
import React, { useEffect } from 'react'
import { renderSendWidget } from '@cedelabs/widgets-universal'

const CedeStoreWidget = ({ userAddress }) => {
  useEffect(() => {
    const rootSelector = '#cede-widget'
    const widgetConfig = {
      network: 'linea',
      address: userAddress,
    }

    const widgetTheme = {
      primaryColor: '#202020',
      borderColor: '#434343',
      logoBorderColor: '#DF832F',
      accentColor: '#DF832F',
    }

    renderSendWidget(rootSelector, { config: widgetConfig, theme: widgetTheme })
  }, [])

  return <div id='cede-widget'></div>
}

export default CedeStoreWidget
