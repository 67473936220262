import React, { useContext, useMemo } from 'react'
import { useGlobalChartData, useGlobalData } from '../../../../context/GlobalData'
import { useAnalyticsVersion } from '../../../../hooks/useGeneral'
import { formatAmount } from '../../../../utils/formatNumber'
import { useAllPairData } from '../../../../context/PairData'
import { BaseAssetsConetext } from '../../../../context/BaseAssetsConetext'
import PairsTable from '../pairsTable'
import { useAllTokenData } from '../../../../context/TokenData'
import TokensTable from '../tokensTable'
import { useNavigate } from 'react-router-dom'
import PercentChange from '../../../common/PercentChange'
import { AnalyticVersions } from '../../../../config/constants'
import { getChartDates, getYAXISValuesAnalytics } from '../../../../utils/analyticsHelper'
import dayjs from 'dayjs'
import AreaChart from '../../../common/AreaChart'

const Index = () => {
  const version = useAnalyticsVersion()
  const globalData = useGlobalData(version)
  const globalChartData = useGlobalChartData(version)
  const tokens = useAllTokenData(version)
  const pairs = useAllPairData(version)
  const v1pairs = useAllPairData(AnalyticVersions.v1)
  const assets = useContext(BaseAssetsConetext)
  const navigate = useNavigate()
  const liquidityData = globalChartData ? globalChartData.daily.map((item) => item.totalLiquidityUSD) : null
  const volumeData = globalChartData ? globalChartData.daily.map((item) => item.dailyVolumeUSD) : null

  const tokensWithImg = useMemo(() => {
    if (Object.keys(tokens).length > 0)
      return Object.values(tokens)
        .map((token) => {
          const found = assets.find((ele) => ele.address.toLowerCase() === token.id)
          return {
            ...token,
            name: found ? found.name : token.name,
            symbol: found ? found.symbol : token.symbol,
            logoURI: found ? found.logoURI : '/images/tokens/UKNOWN.png',
          }
        })
        .slice(0, 10)
    return []
  }, [tokens])

  const pairsWithImg = useMemo(() => {
    if (Object.keys(pairs).length > 0)
      return Object.values(pairs)
        .map((pair) => {
          const found0 = assets.find((ele) => ele.address.toLowerCase() === pair.token0.id)
          const found1 = assets.find((ele) => ele.address.toLowerCase() === pair.token1.id)
          return {
            ...pair,
            token0: {
              ...pair.token0,
              name: found0 ? found0.name : pair.token0.name,
              symbol: found0 ? found0.symbol : pair.token0.symbol,
              logoURI: found0 ? found0.logoURI : '/images/tokens/UKNOWN.png',
            },
            token1: {
              ...pair.token1,
              name: found1 ? found1.name : pair.token1.name,
              symbol: found1 ? found1.symbol : pair.token1.symbol,
              logoURI: found1 ? found1.logoURI : '/images/tokens/UKNOWN.png',
            },
          }
        })
        .slice(0, 10)
    return []
  }, [pairs])

  const details = useMemo(() => {
    if (Object.keys(globalData).length > 0) {
      const { totalLiquidityUSD, liquidityChangeUSD, feesUSD, feesChange, prevFeesUSD, volumeUSD, volumeChange } = globalData
      let totalFeesUSD = feesUSD
      let prevTotalFeesUSD = prevFeesUSD
      let totalFeesChange = feesChange
      if (version !== AnalyticVersions.fusion) {
        totalFeesUSD += Object.values(v1pairs).reduce((acc, cur) => {
          return acc + cur.oneDayFeesUSD
        }, 0)
        prevTotalFeesUSD += Object.values(v1pairs).reduce((acc, cur) => {
          return acc + cur.prevFeesUSD
        }, 0)
        totalFeesChange = prevTotalFeesUSD > 0 ? ((totalFeesUSD - prevTotalFeesUSD) / prevTotalFeesUSD) * 100 : 0
      }
      return [
        {
          key: '24H Volume:',
          value: volumeUSD,
          percentage: volumeChange,
        },
        {
          key: '24h Fees:',
          value: totalFeesUSD,
          percentage: totalFeesChange,
        },
        {
          key: 'TVL:',
          value: totalLiquidityUSD,
          percentage: liquidityChangeUSD,
        },
      ]
    }
    return []
  }, [globalData, version, v1pairs])

  return (
    <>
      <div className='md:flex space-y-5 md:space-y-0 md:space-x-6 mt-6'>
        <div className='bg-white/10 border border-[#ffffff33] p-px rounded-[10px] w-full md:w-1/2'>
          <div className='px-6 py-[22px] h-full'>
            <div className=''>
              <div className='text-[#DEDBF2] f-f-fg text-sm lg:text-base'>LIQUIDITY</div>
              <div className='mt-1 flex items-center space-x-3'>
                <div className='text-white font-bold text-[20px] lg:text-[27px]'>${formatAmount(globalData?.totalLiquidityUSD)}</div>
                <PercentChange value={globalData?.liquidityChangeUSD} />
              </div>
              <div className='text-[#B8B6CB] text-[10px] lg:text-[15px]'>{dayjs().format('MMM DD, YYYY')}</div>
            </div>
            {liquidityData && (
              <AreaChart
                data={liquidityData}
                yAxisValues={getYAXISValuesAnalytics(liquidityData)}
                dates={globalChartData.daily.map((value) => value.date)}
                height={275}
                categories={getChartDates(globalChartData.daily)}
              />
            )}
          </div>
        </div>
        <div className='bg-white/10 border border-[#ffffff33] p-px rounded-[10px] w-full md:w-1/2'>
          <div className='px-6 py-[22px] h-full'>
            <div className=''>
              <div className='text-[#DEDBF2] f-f-fg text-sm lg:text-base'>VOLUME</div>
              <div className='mt-1 flex items-center space-x-3'>
                <div className='text-white font-bold text-[20px] lg:text-[27px]'>${formatAmount(globalData?.volumeUSD)}</div>
                <PercentChange value={globalData?.volumeChange} />
              </div>
              <div className='text-[#B8B6CB] text-[10px] lg:text-[15px]'>{dayjs().format('MMM DD, YYYY')}</div>
            </div>
            {volumeData && (
              <AreaChart
                data={volumeData}
                yAxisValues={getYAXISValuesAnalytics(volumeData)}
                dates={globalChartData.daily.map((value) => value.date)}
                height={275}
                categories={getChartDates(globalChartData.daily)}
              />
            )}
          </div>
        </div>
      </div>
      <div className='w-full mt-[30px] md:mt-10'>
        <div className='w-full bg-white/10 border border-[#ffffff33] px-4 py-4 lg:px-5 lg:py-[15px] rounded-[10px]'>
          <div className={`lg:flex items-center`}>
            {details.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`lg:w-1/3 ${idx === 1 ? 'py-3 lg:py-0 my-3 lg:my-0 border-y lg:border-y-0 border-[#757384]' : ''} ${
                    idx === 2 ? 'w-full' : 'lg:border-r border-[#757384] w-full'
                  } ${idx === 0 ? '' : 'lg:pl-5'}`}
                >
                  <p className='text-white leading-[19px] f-f-fg'>{item.key}</p>
                  <div className='flex items-end space-x-[5px]'>
                    <p className='text-[#E9E9F2] text-lg lg:text-[22px] leading-5 lg:leading-[26px]'>${formatAmount(item.value)}</p>
                    <PercentChange value={item.percentage} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/* top tokens */}
        <div className='w-full mt-10'>
          <div className='w-full flex items-center justify-between'>
            <p className='text-[22px]  md:text-[27px] leading-8 text-white f-f-fg font-medium'>Top Tokens</p>
            <div
              className='flex items-center  space-x-3.5 cursor-pointer group'
              onClick={() => {
                navigate(`/analytics/${version}/tokens`)
              }}
            >
              <span className='text-lg text-themeOrange'>See All</span>
              <img className={`group-hover:w-[40px] w-[30px] duration-300 ease-in-out`} src='/images/common/spear.svg' alt='' />
            </div>
          </div>
          <TokensTable tokensData={tokensWithImg} version={version} />
        </div>
        {/* Top Pairs */}
        <div className='w-full mt-[30px] md:mt-[50px]'>
          <div className='w-full flex items-center justify-between'>
            <p className='text-[22px]  md:text-[27px] leading-8 text-white f-f-fg font-medium'>Top Pairs</p>
            <div
              className='flex items-center  space-x-3.5 cursor-pointer group'
              onClick={() => {
                navigate(`/analytics/${version}/pairs`)
              }}
            >
              <span className='text-lg text-themeOrange'>See All</span>
              <img className={`group-hover:w-[40px] w-[30px] duration-300 ease-in-out`} src='/images/common/spear.svg' alt='' />
            </div>
          </div>
          <PairsTable pairsData={pairsWithImg} />
        </div>
      </div>
    </>
  )
}

export default Index
