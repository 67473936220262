import { useContext } from 'react'
import { Web3Context } from '../context/Web3Conetext'

const useWeb3 = () => {
  return useContext(Web3Context).web3
}

export const useWeb3Wagmi = () => {
  return useContext(Web3Context).web3Wagmi
}

export default useWeb3
