import React, { useState } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import ReactTooltip from 'react-tooltip'
import Sticky from 'react-stickynode'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount, fromWei } from '../../../utils/formatNumber'
// import { useNavigate } from 'react-router-dom'
import FarmStakeModal from './farmStakeModal'
import { useHarvest } from '../../../hooks/usePreFarm'
import { PresaleLynxPrice } from '../../../config/constants'
import { useTimestamp } from '../../../utils/time'
import StyledButton from '../../common/Buttons/styledButton'
import './style.scss'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'

const Item = ({ usd, content, account, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return account || type === 'tvl' ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`new-${type}-${idx}-${usd}`}
        onMouseEnter={() => {
          setArrowReverse(`new-${type}-${idx}-${usd}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[14px] lg:text-[15px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{Number.isNaN(usd) ? `~` : `$ ${formatAmount(usd, true)}`}</p>
        <button className={`${arrowReverse === `new-${type}-${idx}-${usd}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[180px] !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 !bg-[#252525] !border !border-[#ffffff33] !rounded-xl after:!bg-[#252525] after:!border-[#ffffff33]'
        id={`new-${type}-${idx}-${usd}`}
        place='right'
        effect='solid'
        delayShow={200}
      >
        {content}
      </ReactTooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[14px] lg:text-[15px]'>-</div>
  )
}

const TableRow = ({ item, idx }) => {
  // TODO: Open when position
  const [isOpen, setIsOpen] = useState(false)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  // eslint-disable-next-line no-unused-vars
  // const navigate = useNavigate()
  const { onHarvest, pending } = useHarvest()
  const [manage, setManage] = useState(false)

  const timestamp = useTimestamp()
  const finished = item.endTime < timestamp

  return (
    <div key={idx} className='table-row-wrapper'>
      <div className='w-full  lg:w-[20%]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-3 mr:0 lg:mr-2'>
            <div className='flex items-center -space-x-2'>
              {item.isLp && item.type !== 'Ichi' ? (
                <>
                  <img className='relative z-[5]' alt='' src={item.token0?.logoURI} width={30} height={30} />
                  <img className='relative z-[5]' alt='' src={item.token1?.logoURI} width={30} height={30} />
                </>
              ) : (
                <img className='relative z-10' alt='' src={item.principalToken?.logoURI} width={30} height={30} />
              )}
            </div>
            <div>
              {/* Splitting the lpSymbol into pair and strategy and displaying them */}
              <p className='tracking-[0.78px] text-[14px] leading-none'>
                <span>{item.lpSymbol.split(' ')[0]}</span> {/* Display the pair */}
                <br />
                <span>{item.lpSymbol.split(' ').slice(1).join(' ')}</span> {/* Display the entire strategy */}
              </p>
            </div>
          </div>
          <button
            className='lg:hidden'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <img alt='' className={`${isOpen ? 'rotate-180' : ''} transform`} src='/images/swap/dropdown-arrow.png' />
          </button>
        </div>
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Rewards</p>
        <div className='flex flex-col items-start justify-center text-base sm:text-[14px] lg:text-[15px]'>
          {' '}
          {finished ? 0 : formatAmount(fromWei(item.rewardsPerSecond), true)} <br /> LYNX / second
        </div>
      </div>

      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>APR</p>
        <div className='flex flex-col items-start justify-center text-base sm:text-[14px] lg:text-[15px]'>
          {finished ? '0%' : item.apr > 0 ? `${formatAmount(item.apr)}%` : `( ~ )`}
        </div>
      </div>
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[11%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Staked</p>
        <p className='text-base sm:text-[14px] lg:text-[15px] flex items-center cursor-pointer space-x-1.5'>
          {' '}
          {formatAmount(fromWei(item.totalStaked, item.stakeToken?.decimals))} LPs
          <br />
          {item.totalStakedUsd.gt(0) ? `($${formatAmount(item.totalStakedUsd)})` : `( ~ )`}
        </p>

        {/* <Item
          account={account}
          type={'tvl'}
          usd={fromWei(item.totalStaked, item.stakeToken?.decimals)}
          content={
            <>
              {formatAmount(fromWei(item.totalStaked, item.stakeToken?.decimals))} {item.stakeToken?.symbol}
              <br />
              {formatAmount(item.token1.tvl)} {item.token1.symbol}
            </>
          }
          idx={idx}
        /> */}
      </div>
      {/* second row */}
      <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Stake</p>
        <p className='text-base sm:text-[14px] lg:text-[15px] flex items-center cursor-pointer space-x-1.5'>
          {' '}
          {item.userInfo?.stakedBalance ? `${formatAmount(fromWei(item.userInfo.stakedBalance, item.stakeToken?.decimals))}` : `~`} {item.stakeToken?.symbol}
          <br />
          {item.userStakedUsd?.gt(0) ? `($${formatAmount(item.userStakedUsd)})` : `( ~ )`}
        </p>
        {/* <Item
          account={account}
          type={'stake'}
          usd={fromWei(item.userInfo.stakedBalance, item.stakeToken?.decimals)}
          content={
            <>
              {formatAmount(fromWei(item.userInfo.stakedBalance, item.stakeToken?.decimals))} {item.stakeToken?.symbol}
            </>
          }
          idx={idx}
        /> */}
      </div>
      <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Earnings</p>
        <Item
          account={account}
          type={'earnings'}
          usd={fromWei(item.userInfo?.earnings, item.token.decimals) * PresaleLynxPrice}
          content={<>{formatAmount(fromWei(item.userInfo?.earnings, item.token.decimals))} LYNX</>}
          idx={idx}
        />
      </div>
      {/* <div className={`${isOpen ? 'block' : 'hidden'} lg:block  w-1/2 lg:w-[8%] mt-2 lg:mt-0`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Earnings</p>
        <Item account={account} type={'earning'} usd={item.account.earnedUsd} content={<>{formatAmount(item.account.gaugeEarned)} THE</>} idx={idx} />
      </div> */}
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-full lg:w-[20%] mt-3.5 lg:mt-0`}>
        {account ? (
          <div className='space-x-5 xl:space-x-6 w-full flex items-center lg:justify-end'>
            <TransparentButton
              content={'Manage'}
              onClickHandler={() => {
                setManage(true)
                // navigate(`/liquidity/fusion?currency0=${item.token0.address}&currency1=${item.token1.address}`)
              }}
              fontWeight={'font-medium'}
              className='h-10 px-[26px] text-white flex  max-w-[96px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[14px] w-full'
            />
            <button
              disabled={!item.harvestEnable || pending || item.userInfo?.earnings == 0}
              className={`${!item.harvestEnable || pending || item.userInfo?.earnings == 0 ? 'opacity-[0.66] cursor-not-allowed ' : ''}text-base text-gray`}
              onClick={() => {
                onHarvest(item)
              }}
            >
              Claim Earnings
            </button>
          </div>
        ) : (
          <div className='flex items-center lg:justify-end'>
            <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='py-[10px] px-[16px]' />
          </div>
        )}
      </div>
      {manage && <FarmStakeModal isOpen={manage} setIsOpen={setManage} pair={item} />}
    </div>
  )
}

const FarmTable = ({ pairsData, sort, setSort, sortOptions }) => {
  return (
    <>
      {/* for desktop */}
      <div className='w-full mt-11'>
        <div className='w-full'>
          {pairsData.length > 0 && (
            <>
              <Sticky
                enabled={false}
                innerActiveClass={'bg-themeOrangeLight rounded-b-lg'}
                top={95}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={'sticky-table-header'}
                className={`z-[100]`}
              >
                <div className='w-[20%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${index === 4 ? 'w-[8%]' : index === 0 ? 'w-[14%]' : 'w-[11%]'} font-medium text-[14px] xl:text-[16px] text-white f-f-fg`}
                    key={`header-${index}`}
                  >
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                    >
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                      <p className='flex items-center'>{option.label}</p>
                    </div>
                  </div>
                ))}
                <div className='w-[25%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
              </Sticky>
              <div className='flex flex-col gap-3'>
                {pairsData.map((item, idx) => {
                  return <TableRow isLast={idx === pairsData.length - 1} idx={`${idx}-${item.address}`} item={item} key={`row-${idx}-${item.address}`} />
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default FarmTable
