import random from 'lodash/random'

// Array of available nodes to connect to
export const Nodes = {
  59140: ['https://rpc.goerli.linea.build'],
  59144: [
    'https://rpc.linea.build',
    // 'https://1rpc.io/linea',
    // 'https://linea.decubate.com',
    // 'https://linea.drpc.org',
    // 'https://linea.blockpi.network/v1/rpc/public',
  ],
  56: ['https://bsc-dataseed.binance.org', 'https://rpc.ankr.com/bsc', 'https://bscrpc.com'],
  97: ['https://bsc-testnet.blockpi.network/v1/rpc/public	', 'https://bsc-testnet.public.blastapi.io', 'https://bsc-testnet.publicnode.com'],
}

const getRpcUrl = () => {
  const nodes = Nodes[Number(process.env.REACT_APP_CHAIN_ID)]
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getRpcUrl
