export const menuLinks = {
  59144: [
    {
      name: 'SWAP',
      link: '/swap',
    },
    {
      name: 'SINGLE DEPOSITS',
      link: '/singlesided',
      class: 'special',
    },
    {
      name: 'POOLS',
      link: '/liquidity',
    },
    {
      name: 'MANAGE',
      // link: '/dashboard',
      dropdown: true,
      link: [
        {
          name: 'MY ASSETS',
          link: '/dashboard',
        },
        {
          name: 'LOCK',
          link: '/dashboard/lock',
        },
        {
          name: 'DELEGATION',
          link: '/dashboard/delegate',
        },
        {
          name: 'VOTING',
          link: '/dashboard/vote',
        },
        {
          name: 'REWARDS',
          link: '/dashboard/rewards',
        },
        {
          name: 'OPTIONS',
          link: '/dashboard/options',
        },
      ],
    },
    /*
    {
      name: 'EARN',
      dropdown: true,
      link: [
        {
          name: 'SINGLE DEPOSITS',
          link: '/singlesided',
        },
        {
          name: 'OPTIONS',
          link: '/otoken',
        },
      ],
    },
    {
      name: 'VOTE',
      dropdown: true,
      link: [
        {
          name: 'DELEGATE',
          link: '/delegate',
        },
        {
          name: 'LIQUIDITY',
          link: '/vote',
        },
      ],
    },
    */
    {
      name: 'MORE',
      dropdown: true,
      link: [
        {
          name: 'ANALYTICS',
          link: '/analytics',
        },
        // {
        //   name: 'PRE MINING',
        //   link: 'https://v1.lynex.fi/mining',
        //   external: true,
        // },
        {
          name: 'GAUGES',
          link: '/whitelist',
        },
        {
          name: 'BRIDGE',
          link: '/bridge',
        },
        {
          name: 'DOCS',
          link: 'https://lynex.gitbook.io/',
          external: true,
        },
      ],
    },
  ],
  59140: [
    {
      name: 'SWAP',
      link: '/swap',
    },
    {
      name: 'LIQUIDITY',
      link: '/liquidity',
    },
    // {
    //   name: 'PRE MINING',
    //   link: '/mining',
    //   class: 'special',
    // },
    {
      name: 'LOCK',
      link: '/lock',
    },
    {
      name: 'VOTE',
      // link: '/vote',
      dropdown: true,
      link: [
        {
          name: 'DELEGATE',
          link: '/delegate',
        },
        {
          name: 'LIQUIDITY',
          link: '/vote',
        },
      ],
    },
    {
      name: 'REWARDS',
      link: '/rewards',
    },
    {
      name: 'GAUGES',
      link: '/whitelist',
    },
    {
      name: 'OPTIONS',
      link: '/otoken',
    },
    {
      name: 'SINGLE SIDE',
      link: '/singlesided',
    },
    // {
    //   name: 'theNFT',
    //   link: '/theNFT',
    // },
    // {
    //   name: 'REFERRAL',
    //   link: '/referral',
    // },
    {
      name: 'BRIDGE',
      dropdown: true,
      link: [
        {
          name: 'Linea Bridge',
          link: 'https://bridge.linea.build/',
          external: true,
        },
        {
          name: 'Owlto',
          link: 'https://owlto.finance/bridge',
          external: true,
        },
        {
          name: 'Symbiosis',
          link: 'https://app.symbiosis.finance/swap?chainOut=Linea&tokenOut=ETH',
          external: true,
        },
        {
          name: 'Orbiter',
          link: 'https://www.orbiter.finance/?source=Ethereum&dest=Linea&token=ETH',
          external: true,
        },
        {
          name: 'rhino.fi',
          link: 'https://app.rhino.fi/bridge?token=ETH&chainOut=LINEA&chain=BASE',
          external: true,
        },
      ],
    },
    {
      name: 'MORE',
      dropdown: true,
      link: [
        {
          name: 'Airdrop',
          link: '/claim',
        },
        {
          name: 'LYNX Claim',
          link: '/lynxclaim',
        },
        {
          name: 'Lynex Keys',
          link: '/claim-key',
        },
        {
          name: 'Analytics',
          link: '/analytics',
        },
        // {
        //   name: 'Analytics',
        //   link: 'https://info.thena.fi/',
        //   external: true,
        // },
        // {
        //   name: 'V1',
        //   link: 'https://v1.thena.fi/',
        //   external: true,
        // },
        {
          name: 'Docs',
          link: 'https://lynex.gitbook.io/',
          external: true,
        },
      ],
    },
  ],
}
