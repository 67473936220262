import React from 'react'
import '../../claim/info/info.scss'

const Info = () => {
  return (
    <div className='info'>
      <div className='title'>
        <p>Pre-mining Reward Claim</p>
        <p className='text-2xl'>$oLYNX/$veLYNX</p>
      </div>
      <p className='text'>Redeem your $LYNX earned by the pre-mining campaing into oLYNX and veLYNX.</p>
      <div className='flex items-center space-x-2.5 cursor-pointer w-full sm:w-1/2 group'>
        <a className='learn-more' href='https://lynex.gitbook.io/lynex-docs/informational/pre-tge-campaigns/pre-mining' target='_blank' rel='noreferrer'>
          Learn more
        </a>
        <img className={`group-hover:w-[35px] w-[30px] duration-300 ease-in-out`} src='/images/common/spear.svg' alt='' />
      </div>
    </div>
  )
}

export default Info
