import React from 'react'
import { formatAmount } from '../../../utils/formatNumber'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import DualTokenImage from '../DualTokenImage'

const percentArray = [25, 50, 75, 100]

const BalanceInput = ({ title, inputAmount, setInputAmount, symbol = '', logoURIs, balance = null, noPercent = false, sm = false }) => {
  const { account } = useWeb3Wagmi()
  return (
    <div className='w-full'>
      <div className='flex items-center justify-between w-full'>
        {title && <p className='text-white text-sm md:text-base leading-8'>{title}</p>}
        {account && balance && !noPercent && (
          <div className={`flex items-center text-sm lg:text-base space-x-3 ${!title && 'justify-between w-full'}`}>
            <div className='flex items-center space-x-2.5'>
              {percentArray.map((percent, index) => {
                return (
                  <div
                    className={`${index === 0 || index === 2 ? 'hidden sm:flex' : 'flex'}                      
                      items-center justify-center bg-white/5 hover:bg-white/20 border border-[#ffffff33] rounded-md text-dimGray text-xs md:text-sm w-[40px] md:w-[52px] h-[22px] md:h-[28px] cursor-pointer`}
                    onClick={() => setInputAmount(balance.times(percent).div(100).toString(10) || 0)}
                    key={`percent-${index}`}
                  >
                    {index === percentArray.length - 1 ? 'MAX' : percent}
                    {index === percentArray.length - 1 ? '' : '%'}
                  </div>
                )
              })}
            </div>
            <p className={`text-white ${sm && 'text-xs'}`}>Balance: {formatAmount(balance)}</p>
          </div>
        )}
      </div>
      <div className='mt-2.5  p-px w-full rounded-md'>
        <div className='bg-white/5 px-3 rounded-md flex items-center justify-between'>
          <input
            className={`bg-transparent ${
              logoURIs.length > 1 ? 'w-[35%] md:w-[50%]' : 'w-[80%]'
            } py-[8px] lg:py-[15px] text-white text-xl lg:text-2xl leading-8 placeholder-dimGray`}
            value={inputAmount}
            onChange={(e) => {
              setInputAmount(Number(e.target.value) < 0 ? '' : e.target.value)
            }}
            placeholder='0.00'
            type={'number'}
            min={0}
          />
          {logoURIs.length > 0 && (
            <div className='flex items-center justify-between space-x-2'>
              {logoURIs.length > 1 ? (
                <DualTokenImage token1URI={logoURIs[0]} token2URI={logoURIs[1]} sm={sm} />
              ) : (
                <img alt='' width={sm ? 24 : 28} height={sm ? 24 : 28} src={logoURIs[0]} />
              )}
              {!sm && <p className='text-xs lg:text-base leading-6 text-white'>{symbol}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BalanceInput
