import React, { useState } from 'react'

const SwapCrossChain = () => {
  const [isLoading, setIsLoading] = useState(true)

  // Define the configuration object
  const config = {
    companyName: 'Lynex',
    integratorId: 'lynex-swap-widget',
    slippage: 1,
    instantExec: true,
    infiniteApproval: false,
    apiUrl: 'https://api.squidrouter.com',
    style: {
      neutralContent: '#7A7888',
      baseContent: '#FCFCFD',
      base100: '#333231',
      base200: '#1A1817',
      base300: '#161522',
      error: '#ED6A5E',
      warning: '#FFB155',
      success: '#62C555',
      primary: '#DE822F',
      secondary: '#37394C',
      secondaryContent: '#B2BCD3',
      neutral: '#333231',
      roundedBtn: '24px',
      roundedBox: '10px',
      roundedDropDown: '0px',
      displayDivider: false,
      advanced: {
        transparentWidget: false,
      },
    },
    initialFromChainId: 1,
    initialToChainId: 59144,
    defaultTokens: [
      {
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // ETH on Ethereum
        chainId: 1,
      },
      {
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // ETH on Linea
        chainId: 59144,
      },
    ],
  }

  // Convert the config object to a JSON string and encode it
  const encodedConfig = encodeURIComponent(JSON.stringify(config))

  // Construct the iframe src URL with the encoded config
  const src = `https://widget.squidrouter.com/iframe?config=${encodedConfig}`

  return (
    <div className='w-full max-w-[588px] mx-auto relative xl:pb-0 2xl:pb-[32px] flex justify-center'>
      <div className='rounded-[5px]' style={{ maxWidth: '420px', width: '100%' }}>
        {isLoading && (
          <div className='flex justify-center items-center' style={{ height: '300px' }}>
            <div className='text-white'>Loading Cross-Chain Swap...</div>
          </div>
        )}
        <iframe
          title='squid_widget'
          style={{ width: '100%', height: '700px', border: 'none', display: isLoading ? 'none' : 'block' }}
          src={src}
          onLoad={() => setIsLoading(false)}
        />
      </div>
    </div>
  )
}

export default SwapCrossChain
