import { useState, useEffect } from 'react'
import { injected } from '../utils/connectors'
import { useWeb3Wagmi } from './useWeb3'

// TODO: Revisit this file (Likely goes fully unused)
export function useEagerConnect() {
  const { activate, active } = useWeb3Wagmi()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, []) //eslint-disable-line
  //intentionally only running on mount(make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3Wagmi()

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}

export const registerToken = async (tokenAddress, tokenSymbol, tokenDecimals, imgUrl) => {
  const image = imgUrl || '/images/tokens/UKNOWN.png'
  try {
    const tokenAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image,
        },
      },
    })
    return tokenAdded
  } catch (err) {
    console.log('approve error :>> ', err)
    return
  }
}

export const getXpPoints = (account) => {
  const [points, setPoints] = useState(0)
  const url = 'https://kx58j6x5me.execute-api.us-east-1.amazonaws.com/linea/getUserPointsSearch?user='

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`${url}${account.toLowerCase()}`, {
        method: 'get',
      })
      const userData = await response.json()
      if (userData[0]) {
        setPoints(userData[0].xp)
      }
    }
    if (account) fetchData()
  }, [account])
  return points
}
