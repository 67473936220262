import React, { useState } from 'react'
import { getLPSymbol } from '../../../utils/formatNumber'
// import React, { useEffect, useState } from 'react'
import PoolPopup from '../PoolPopup'

const PoolSelect = ({ setPool, pool, pools }) => {
  const [isOpen, setIsOpen] = useState(false)

  // useEffect(() => {
  //   if (!pool) {
  //     setPool(pools[0])
  //   }
  // }, [pool, pools])

  return (
    <>
      <div className={`mt-1.5 md:mt-2.5 p-px w-full`}>
        <div className='bg-white/10 z-[90] rounded-[10px] flex items-center relative'>
          <div
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            className={`w-full h-full flex items-center justify-between cursor-pointer p-3`}
          >
            {pool ? (
              <div className={`flex items-center space-x-3`}>
                <div className='flex items-center  -space-x-2'>
                  <img className='relative z-10 w-[26px] h-[26px] md:w-[30px] md:h-[30px]' alt='' src={pool.token0.logoURI} />
                  <img className='relative z-[5] w-[26px] h-[26px] md:w-[30px] md:h-[30px]' alt='' src={pool.token1.logoURI} />
                </div>
                <div className='text-white'>
                  <p className='text-base md:text-[19px] font-medium leading-5 md:leading-[30px] f-f-fg'>{getLPSymbol(pool)}</p>
                  <p className='tracking-[0.66px] text-[11px] md:text-[13px] leading-none'>{pool.title}</p>
                </div>
              </div>
            ) : (
              <div className={`w-full h-full font-normal text-white text-lg md:text-2xl md:leading-10`}>Select</div>
            )}
            <img
              className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}
              alt=''
              src='/images/swap/dropdown-arrow.png'
            />
          </div>
        </div>
        <PoolPopup setSelectedPool={setPool} popup={isOpen} setPopup={setIsOpen} pools={pools} />
      </div>
    </>
  )
}

export default PoolSelect
