let gasPrices = {}
const endpoint = 'https://ethapi.openocean.finance/v2/59144/gas-price'

export const getGasPrices = async () => {
  const now = Math.round(new Date().getTime() / 1000)
  if (!gasPrices.lastTime || now - gasPrices.lastTime > 14) {
    const response = await fetch(endpoint, {
      method: 'GET',
    })
    gasPrices = await response.json()
    gasPrices.lastTime = now
  }
  return gasPrices
}
