import React from 'react'

const MultipleTokenImage = ({ tokens, sm = false, size = null, spacing = null }) => {
  if (tokens && tokens.length > 0) {
    return (
      <div className={`flex items-center ${spacing ?? '-space-x-1'}`}>
        {tokens.map((token) => {
          if (token)
            return (
              <img
                className='relative z-10'
                width={size ? size : sm ? 24 : 28}
                height={size ? size : sm ? 24 : 28}
                alt=''
                key={token.address}
                src={token.logoURI || '/images/tokens/UKNOWN.png'}
              />
            )
        })}
      </div>
    )
  } else return
}

export default MultipleTokenImage
