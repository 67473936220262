import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import TokenInput from '../../common/Input/TokenInput'
import useWalletModal from '../../../hooks/useWalletModal'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { useProceedSwap } from '../../../hooks/useSwap'
import StyledButton from '../../common/Buttons/styledButton'
// import SwapPopup from './popups/swapPopup'
import { getWBNBAddress } from '../../../utils/addressHelpers'
import { useCurrency } from '../../../hooks/v3/Tokens'
import { Field } from '../../../state/mintV3/actions'
import { tryParseAmount, unwrappedToken } from '../../../v3lib/utils/utils'
import { useBestV3TradeExactIn, useBestV3TradeExactOut } from '../../../hooks/v3/useBestV3Trade'
import { computeRealizedLPFeePercent } from '../../../v3lib/utils/computeRealizedLPFeePercent'
import { formatAmount } from '../../../utils/formatNumber'
import { Percent, TradeType } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { useSwapCallback } from '../../../hooks/v3/useSwapCallback'
import WarningModal from './WarningModal'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import { fetchImportedToken } from '../../../utils/fetchImportToken'
import { addImportedToken } from '../../../state/application/actions'

const SwapFusion = ({ slippage, deadline }) => {
  const [init, setInit] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [fromAsset, setFromAsset] = useState(null)
  const [toAsset, setToAsset] = useState(null)
  const [reverseTransiction, setReverseTransiction] = useState(false)
  const [independentField, setIndependentField] = useState(Field.CURRENCY_A)
  const [typedValue, setTypedValue] = useState('')
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const { onWrap, onUnwrap, swapPending: wrapPending } = useProceedSwap()
  const [searchParams] = useSearchParams()
  const baseAssets = useContext(BaseAssetsConetext)
  const inCurrency = useCurrency(fromAsset ? fromAsset.address : undefined)
  const outCurrency = useCurrency(toAsset ? toAsset.address : undefined)

  const isWrap = useMemo(() => {
    if (fromAsset && toAsset && fromAsset.address === 'ETH' && toAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const isUnwrap = useMemo(() => {
    if (fromAsset && toAsset && toAsset.address === 'ETH' && fromAsset.address.toLowerCase() === getWBNBAddress().toLowerCase()) {
      return true
    }
    return false
  }, [fromAsset, toAsset])

  const showWrap = useMemo(() => {
    return isWrap || isUnwrap
  }, [isWrap, isUnwrap])

  const isExactIn = independentField === Field.CURRENCY_A
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inCurrency : outCurrency) ?? undefined)
  const bestV3TradeExactIn = useBestV3TradeExactIn(isExactIn ? parsedAmount : undefined, outCurrency ?? undefined)
  const bestV3TradeExactOut = useBestV3TradeExactOut(inCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)
  const v3Trade = (isExactIn ? bestV3TradeExactIn : bestV3TradeExactOut) ?? undefined
  const bestTrade = v3Trade.trade ?? undefined

  const allowedSlippage = new Percent(JSBI.BigInt(slippage * 100), JSBI.BigInt(10000))

  const { error, callback: swapCallback, pending: swapPending } = useSwapCallback(bestTrade, allowedSlippage, deadline)
  if (error) console.log('error :>> ', error)

  const dynamicFee = useMemo(() => {
    if (!bestTrade) return
    return bestTrade.swaps[0]?.route?.pools[0].fee
  }, [bestTrade])

  const { realizedLPFee, priceImpact } = useMemo(() => {
    if (!bestTrade) return { realizedLPFee: undefined, priceImpact: undefined }

    const realizedLpFeePercent = computeRealizedLPFeePercent(bestTrade)
    const realizedLPFee = bestTrade.inputAmount.multiply(realizedLpFeePercent)
    const priceImpact = bestTrade.priceImpact.subtract(realizedLpFeePercent)
    return { priceImpact, realizedLPFee }
  }, [bestTrade])

  const priceImpactInNumber = useMemo(() => {
    return priceImpact ? Number(priceImpact.toSignificant()) : 0
  }, [priceImpact])

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.CURRENCY_A]: parsedAmount,
            [Field.CURRENCY_B]: parsedAmount,
          }
        : {
            [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? parsedAmount : bestTrade?.inputAmount,
            [Field.CURRENCY_B]: independentField === Field.CURRENCY_B ? parsedAmount : bestTrade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, bestTrade],
  )

  const formattedAmounts = useMemo(() => {
    const dependentField = isExactIn ? Field.CURRENCY_B : Field.CURRENCY_A
    return {
      [independentField]: typedValue,
      [dependentField]: showWrap ? parsedAmounts[independentField]?.toExact() ?? '' : parsedAmounts[dependentField]?.toExact() ?? '',
    }
  }, [isExactIn, showWrap, parsedAmounts, independentField, typedValue])

  const btnMsg = useMemo(() => {
    if (!account) {
      return {
        isError: true,
        label: 'CONNECT WALLET',
      }
    }

    if (!fromAsset || !toAsset) {
      return {
        isError: true,
        label: 'SELECT A TOKEN',
      }
    }

    if (!parsedAmount) {
      return {
        isError: true,
        label: 'ENTER AN AMOUNT',
      }
    }

    if (fromAsset.balance && fromAsset.balance.lt(parsedAmounts[Field.CURRENCY_A]?.toExact())) {
      return {
        isError: true,
        label: 'INSUFFICIENT ' + fromAsset.symbol + ' BALANCE',
      }
    }

    if (isWrap) {
      return {
        isError: false,
        label: 'WRAP',
      }
    }

    if (isUnwrap) {
      return {
        isError: false,
        label: 'UNWRAP',
      }
    }

    if (!bestTrade) {
      return {
        isError: true,
        label: 'INSUFFICIENT LIQUIDITY FOR THIS TRADE',
      }
    }

    return {
      isError: false,
      label: 'SWAP',
    }
  }, [account, fromAsset, toAsset, parsedAmount, parsedAmounts, isWrap, isUnwrap])

  const firstRun = useRef(true)
  useEffect(() => {
    if (init || baseAssets.length === 0 || !firstRun.current) return
    setInputAndOutput()
    async function fetchToken(address) {
      let token = address ? baseAssets.find((asset) => asset.address.toLowerCase() === address.toLowerCase()) : null
      if (!token && address) {
        token = await fetchImportedToken(address, account)
        if (token) addImportedToken(token)
      }
      return token
    }
    async function setInputAndOutput() {
      firstRun.current = false
      const inputCurrency = searchParams.get('inputCurrency')
      const outputCurrency = searchParams.get('outputCurrency')
      const from = await fetchToken(inputCurrency)
      const to = await fetchToken(outputCurrency)
      if (!from) {
        setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
      } else {
        setFromAsset(from)
      }
      if (!to) {
        setToAsset(baseAssets.find((asset) => asset.symbol === 'LYNX'))
      } else {
        setToAsset(to)
      }
      setInit(true)
    }
  }, [baseAssets, searchParams])

  useEffect(() => {
    // NOTE: Refreshes balances
    if (baseAssets.length === 0) return
    const inputCurrency = fromAsset
    const outputCurrency = toAsset
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.address.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.address.toLowerCase()) : null
    if (!from) {
      setFromAsset(baseAssets.find((asset) => asset.symbol === 'ETH'))
    } else {
      setFromAsset(from)
    }
    if (!to) {
      setToAsset(baseAssets.find((asset) => asset.symbol === 'LYNX'))
    } else {
      setToAsset(to)
    }
  }, [baseAssets])

  const onInputFieldChange = (val) => {
    setIndependentField(Field.CURRENCY_A)
    setTypedValue(val)
  }

  const onOutputFieldChange = (val) => {
    setIndependentField(Field.CURRENCY_B)
    setTypedValue(val)
  }

  return (
    <div className='swap-wrapper'>
      <div className='fromto'>
        <TokenInput
          title='From'
          asset={fromAsset}
          isDollar
          setAsset={setFromAsset}
          otherAsset={toAsset}
          setOtherAsset={setToAsset}
          amount={formattedAmounts[Field.CURRENCY_A]}
          onInputChange={(val) => {
            onInputFieldChange(val)
          }}
        />
      </div>
      <div className='flex justify-center -my-4'>
        <button
          onClick={() => {
            const tempAsset = fromAsset
            setFromAsset(toAsset)
            setToAsset(tempAsset)
          }}
          className='flex justify-center items-center w-[42px] h-[42px] bg-black/70 border border-[#ffffff33] rounded-full transition duration-200 ease-in-out hover:rotate-180 z-20'
        >
          <img className='w-[20px] h-[20px]' src='/images/swap/reverse-icon.svg' />
        </button>
      </div>
      <div className='fromto'>
        <TokenInput
          title='To'
          asset={toAsset}
          isDollar
          setAsset={setToAsset}
          otherAsset={fromAsset}
          setOtherAsset={setFromAsset}
          amount={formattedAmounts[Field.CURRENCY_B]}
          onInputChange={(val) => {
            onOutputFieldChange(val)
          }}
          disabled
        />
      </div>

      {account ? (
        <StyledButton
          disabled={btnMsg.isError || wrapPending || swapPending}
          pending={wrapPending || swapPending}
          onClickHandler={() => {
            if (isWrap) {
              onWrap(parsedAmount?.toExact())
            } else if (isUnwrap) {
              onUnwrap(parsedAmount?.toExact())
            } else {
              if (priceImpactInNumber > 5) {
                setIsWarning(true)
              } else {
                swapCallback()
              }
            }
          }}
          content={btnMsg.label}
          className='w-full text-base md:text-lg py-3 px-4 mt-4'
        />
      ) : (
        <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='w-full text-base md:text-lg py-3 px-4 mt-4' />
      )}

      {bestTrade && !showWrap && (
        <div className='fromto mt-4 text-gray-400'>
          {priceImpactInNumber > 0 && (
            <div
              className={`flex space-x-2 p-1 items-center justify-center border border-[#ffffff33] text-white rounded-xl ${
                priceImpactInNumber < 1 ? 'bg-success/15' : priceImpactInNumber < 2 ? 'bg-white/10' : priceImpactInNumber < 5 ? 'bg-warn/40' : 'bg-error/40'
              }`}
            >
              <p className='text-sm md:text-md leading-5'>Price Impact</p>
              <p className='text-sm md:text-md leading-5 font-semibold'>{formatAmount(priceImpactInNumber)}%</p>
            </div>
          )}
          <div className='flex items-center justify-between mt-3'>
            <p className='text-white text-sm md:text-base leading-5'>Price</p>
            <div className='flex items-center space-x-1.5'>
              <p className='text-white text-sm md:text-base leading-5'>
                {reverseTransiction
                  ? `${bestTrade.executionPrice.toSignificant(4)} ${toAsset.symbol} per ${fromAsset.symbol}`
                  : `${Number(bestTrade.executionPrice.toSignificant()) === 0 ? 0 : bestTrade.executionPrice.invert().toSignificant(4)} ${
                      fromAsset.symbol
                    } per ${toAsset.symbol}`}
              </p>
              <button onClick={() => setReverseTransiction(!reverseTransiction)}>
                <img alt='' src='/images/swap/reverse-small-icon.png' />
              </button>
            </div>
          </div>
          <div className='mt-[0.3rem]'>
            <div className='flex items-center justify-between'>
              <p className='text-white text-sm md:text-base leading-5'>Fee</p>
              <div className='flex gap-x-2 items-center'>
                <p className='text-white text-sm md:text-base leading-5'>
                  {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${realizedLPFee.currency.symbol}` : '-'}
                </p>
                <p className='text-slate-400 text-xs md:text-sm leading-5'>{`(${dynamicFee / 10000}%)`}</p>
              </div>
            </div>
          </div>
          <div className='mt-[0.3rem]'>
            <div className='flex items-center justify-between'>
              <p className='text-white text-sm md:text-base leading-5'>{bestTrade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum Sold'}</p>
              <p className='text-white text-sm md:text-base leading-5'>
                {bestTrade.tradeType === TradeType.EXACT_INPUT
                  ? `${bestTrade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${bestTrade.outputAmount.currency.symbol}`
                  : `${bestTrade.maximumAmountIn(allowedSlippage).toSignificant(6)} ${bestTrade.inputAmount.currency.symbol}`}
              </p>
            </div>
          </div>
          <div className='mt-[0.3rem]'>
            <div className='flex items-center justify-between'>
              <p className='text-white text-sm md:text-base leading-5'>Slippage Tolerance</p>
              <p className='text-white text-sm md:text-base leading-5'>{slippage}%</p>
            </div>
          </div>
          <div className='mt-[0.3rem]'>
            <div className='flex items-center justify-between'>
              <p className='text-white text-sm md:text-base leading-5'>Route</p>
              <p className='text-white text-sm md:text-base leading-5 flex items-center'>
                {bestTrade.route.tokenPath.map((token, i, path) => {
                  const isLastItem = path.length - 1 === i
                  const currency = unwrappedToken(token)
                  return (
                    <Fragment key={i}>
                      <span>{currency.symbol}</span>
                      {!isLastItem && <span className='mx-1'>{`>`}</span>}
                    </Fragment>
                  )
                })}
              </p>
            </div>
          </div>
        </div>
      )}

      {isWarning && <WarningModal isOpen={isWarning} setIsOpen={setIsWarning} onClickHandler={swapCallback} priceImpact={priceImpactInNumber} />}
    </div>
  )
}

export default SwapFusion
