import React, { useEffect } from 'react'
import Info from '../../components/pages/claim-foxy/info/info'
import Distribution from '../../components/pages/claim-foxy/dist/dist'
import './claim.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Portal from '../../components/pages/claim-foxy/info/portal'

const TYPES = {
  community: 'community',
  portal: 'portal',
  strategic: 'strategic',
}

const Index = () => {
  const { type } = useParams()
  const navigate = useNavigate()
  const currentType = type ? TYPES[type] : 'portal'

  useEffect(() => {
    if (!currentType) {
      navigate('/404')
    }
  }, [currentType])

  return (
    <div className='w-full pt-36 sm:pt-40 px-5 pb-28 xl:pb-0 xl:px-0 2xl:pb-[32px]'>
      <div className='relative z-20 top-[50px] left-[-40px] sm:left-[-60px] max-w-[900px] mx-auto'>
        <img className='token1' src='/images/claim/FOXY.png' alt='FOXY Token' />
      </div>

      <div className='relative max-w-[900px] mx-auto w-full bg-[#FFFFFF26] rounded-md z-10 backdrop-blur-md'>
        <div className='flex flex-col sm:flex-row w-full sm:px-8 sm:py-8 sm:divide-x divide-[#A9A9A94D]'>
          {currentType === 'community' ? <Info /> : <Portal />}
          <Distribution type={currentType} />
        </div>
      </div>
      <div className='relative flex z-20 justify-end top-[-50px] sm:right-[-50px] max-w-[900px] mx-auto'>
        <img className='token2' src='/images/claim/FOXY.png' alt='FOXY Token' />
      </div>
    </div>
  )
}

export default Index
