import { defaultChainId } from '../constants'
import dextokenlist from './dextokenlist.json'
import tokenlist from '@lynex.fi/lynex-lists/tokens/main.json'
import bluechips from './bluechips.json'

const thisChainTokens = tokenlist.tokens.filter((token) => token.chainId === defaultChainId)
const tokenAddresses = thisChainTokens.map((token) => token.address.toLowerCase())
const dexTokens = thisChainTokens.filter((token) => dextokenlist.tokens.find((t) => t.address.toLowerCase() === token.address.toLowerCase()))
const blueChipAddresses = bluechips.tokens.map((token) => token.address.toLowerCase())

export { tokenlist, tokenAddresses, thisChainTokens, dexTokens, blueChipAddresses }
