import React, { useCallback, useMemo } from 'react'
import JSBI from 'jsbi'
import { Field } from '../../../../../../state/mintV3/actions'
import { useProceedSwap } from '../../../../../../hooks/useSwap'
import { WETH_EXTENDED, v3LiquidityRangeType } from '../../../../../../v3lib/entities/constants'
import { useCurrencyBalance } from '../../../../../../hooks/v3/useCurrencyBalances'
import { customNotify } from '../../../../../../utils/notify'
import { useGammaAdd } from '../../../../../../hooks/v3/useGamma'
import { useAlgebraAdd } from '../../../../../../hooks/v3/useAlgebra'
import { useActivePreset } from '../../../../../../state/mintV3/hooks'
import { ZERO_ADDRESS } from '../../../../../../utils/formatNumber'
import { defaultChainId } from '../../../../../../config/constants'
import { useIchiAdd } from '../../../../../../hooks/v3/useIchi'
import { useSteerAdd } from '../../../../../../hooks/v3/useSteer'
import WarningTag from '../../../../../common/WarningTag'
import { TransactButton } from '../../../../../common/Buttons/transactButton'
import TransparentButton from '../../../../../common/Buttons/transparentButton'
import { useDefiedgeAdd } from '../../../../../../hooks/v3/useDefiedge'
import { useClipFinanceAdd } from '../../../../../../hooks/v3/useClipFinance'

const chainId = defaultChainId

export const AddLiquidityButton = ({ baseCurrency, quoteCurrency, mintInfo, gammaPairs, setIsOpen, slippage, deadline, lite = false }) => {
  const { liquidityRangeType, errorMessage } = mintInfo
  const { onWrap, swapPending: wrappingETH } = useProceedSwap()
  const { onGammaAdd, onGammaAddAndStake, pending: gammaPending } = useGammaAdd()
  const { onAlgebraAdd, pending: algebraPending } = useAlgebraAdd()
  const amountA = mintInfo.parsedAmounts[Field.CURRENCY_A]
  const amountB = mintInfo.parsedAmounts[Field.CURRENCY_B]
  const wethBalance = useCurrencyBalance(WETH_EXTENDED[chainId])
  const preset = useActivePreset()
  const { onIchiAdd, onIchiAddAndStake } = useIchiAdd()
  const { onSteerAdd, onSteerAddAndStake } = useSteerAdd()
  const { handleDefiedgeAdd: onDefiedgeAdd, handleDefiedgeAddAndStake: onDefiedgeAddAndStake } = useDefiedgeAdd()
  const { onClipAdd, onClipAddAndStake } = useClipFinanceAdd()

  const gammaPair = useMemo(() => {
    return gammaPairs && gammaPairs.find((pair) => pair.title === preset?.title)
  }, [gammaPairs, preset])
  const tvl = useMemo(() => {
    return gammaPair?.lpPrice.times(gammaPair.totalSupply)
  }, [gammaPair])
  const lowTvl = tvl?.lt(10000) ?? false

  const amountToWrap = useMemo(() => {
    if (!baseCurrency || !quoteCurrency || !amountA || !amountB || liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE) return
    if (baseCurrency.isNative || baseCurrency.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()) {
      if (wethBalance && JSBI.greaterThan(amountA.numerator, wethBalance.numerator)) {
        return JSBI.subtract(amountA.numerator, wethBalance.numerator)
      }
      return
    } else if (quoteCurrency.isNative || quoteCurrency.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()) {
      if (wethBalance && JSBI.greaterThan(amountB.numerator, wethBalance.numerator)) {
        return JSBI.subtract(amountB.numerator, wethBalance.numerator)
      }
      return
    }
    return
  }, [amountA, amountB, baseCurrency, quoteCurrency, liquidityRangeType, wethBalance])

  const onAddLiquidity = useCallback(() => {
    if (errorMessage) {
      customNotify(errorMessage, 'warn')
      return
    }
    if (preset.type === 'YieldIQ') {
      onIchiAdd(amountA, amountB, amountToWrap, gammaPair)
    } else if (preset.title.toLowerCase().includes('clip')) {
      onClipAdd(amountA, amountB, amountToWrap, gammaPair)
    } else if (preset.title.toLowerCase().includes('steer')) {
      onSteerAdd(amountA, amountB, amountToWrap, gammaPair)
    } else if (preset.title.toLowerCase().includes('defiedge')) {
      onDefiedgeAdd(amountA, amountB, amountToWrap, gammaPair)
    } else if (liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE) {
      onGammaAdd(amountA, amountB, amountToWrap, gammaPair)
    } else {
      onAlgebraAdd(amountA, amountB, baseCurrency, quoteCurrency, mintInfo, slippage, deadline)
    }
  }, [errorMessage, amountToWrap, onWrap, onGammaAdd, baseCurrency, quoteCurrency, liquidityRangeType, amountA, amountB, mintInfo, slippage, deadline])

  const onAddLiquidityAndStake = useCallback(() => {
    if (errorMessage) {
      customNotify(errorMessage, 'warn')
      return
    }
    if (preset.type === 'YieldIQ') {
      onIchiAddAndStake(amountA, amountB, amountToWrap, gammaPair)
    } else if (preset.title.toLowerCase().includes('clip')) {
      onClipAddAndStake(amountA, amountB, amountToWrap, gammaPair)
    } else if (preset.title.toLowerCase().includes('steer')) {
      onSteerAddAndStake(amountA, amountB, amountToWrap, gammaPair)
    } else if (preset.title.toLowerCase().includes('defiedge')) {
      onDefiedgeAddAndStake(amountA, amountB, amountToWrap, gammaPair)
    } else onGammaAddAndStake(amountA, amountB, amountToWrap, gammaPair)
  }, [errorMessage, amountToWrap, onGammaAddAndStake, amountA, amountB])

  return (
    <>
      <div className='flex flex-nowrap space-x-3 mt-3'>
        {gammaPair && gammaPair.gauge && gammaPair.gauge.address !== ZERO_ADDRESS && (
          <TransactButton
            disabled={gammaPending || algebraPending || !preset}
            onClickHandler={onAddLiquidityAndStake}
            content={'ADD LIQUIDITY AND STAKE'}
            className='w-full py-3 px-1 md:px-2 text-xs md:text-sm tracking-wider'
          />
        )}
        {lite ? (
          <TransparentButton
            onClickHandler={() => setIsOpen(false)}
            content={'CANCEL'}
            className='flex justify-center items-center w-full py-3 px-1 md:px-2 text-xs md:text-sm tracking-wider hover:bg-themeOrangeLight/30'
          />
        ) : (
          <TransactButton
            disabled={gammaPending || algebraPending || !preset || wrappingETH}
            onClickHandler={onAddLiquidity}
            content={'ADD LIQUIDITY'}
            className='w-full py-3 px-1 md:px-2 text-xs md:text-sm tracking-wider'
          />
        )}
      </div>
      <div className='flex flex-col space-y-2 w-full mt-3'>
        {lowTvl && <WarningTag text={'This pool has low TVL. Adding liquidity could result in loss of funds. Proceed with caution.'} />}
        {!preset && <WarningTag text={'You need to select a strategy and/or range'} />}
      </div>
    </>
  )
}
