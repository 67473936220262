import React, { useEffect, useState } from 'react'
import SwapV1 from '../../components/pages/swap/SwapV1'
import SwapFusion from '../../components/pages/swap/SwapFusion'
import { useSwapConfig } from '../../state/application/hooks'
import { useSearchParams } from 'react-router-dom'
import { Limit, TWAP } from '../../components/pages/swap/TWAP'
import { defaultChainId } from '../../config/constants'
import SwapBest from '../../components/pages/swap/SwapBest'
import SwapCrossChain from '../../components/pages/swap/SwapCrossChain'
import DropDown from '../../components/common/Dropdown'
import Settings from '../../components/common/Settings'

import './swap.scss'

const swaps =
  defaultChainId === 59140
    ? [
        { type: 'CLASSIC', Component: SwapV1 },
        { type: 'FUSION', Component: SwapFusion },
      ]
    : [
        { type: 'SWAP', Component: SwapBest },
        { type: 'CLASSIC', Component: SwapV1 },
        { type: 'FUSION', Component: SwapFusion },
        { type: 'LIMIT', Component: Limit },
        { type: 'TWAP', Component: TWAP },
        { type: 'CROSS', Component: SwapCrossChain },
      ]

const types = {
  CLASSIC: SwapV1,
  FUSION: SwapFusion,
  SWAP: SwapBest,
}

const dropDownOptions =
  defaultChainId === 59140 ? [{ type: 'CLASSIC' }, { type: 'FUSION' }] : [{ type: 'SWAP' }, { type: 'LIMIT' }, { type: 'TWAP' }, { type: 'CROSS' }]

const Index = () => {
  const [searchParams] = useSearchParams()
  const { selectedSwap: preferredSwapType, updateSwapType } = useSwapConfig()

  useEffect(() => {
    const type = types[searchParams.get('type')] ? searchParams.get('type') : preferredSwapType || 'SWAP'
    updateSwapType(type)
  }, [])

  const [setting, setSetting] = useState(false)
  const [slippage, setSlippage] = useState(2.25)
  const [multihops, setMultihops] = useState(true)
  const [deadline, setDeadline] = useState(20)

  const showSettings = ['SWAP', 'CLASSIC', 'FUSION'].includes(preferredSwapType)

  return (
    <div className='w-full pt-[120px] pb-28 xl:pb-0 2xl:pb-[32px] px-5 xl:px-0 '>
      <div className='relative flex justify-end top-[50px] sm:right-[60px] max-w-[900px] h-[86px] mx-auto'>
        <img className='token2 z-[-1]' src='/images/swap/back-token-2.svg' alt='Lynx logo 2' />
      </div>
      <div className='max-w-[1104px] mx-auto w-full'>
        <div className='flex max-w-[550px] m-auto justify-between mb-2'>
          <DropDown options={dropDownOptions} selected={preferredSwapType} setSelected={(typeSelected) => updateSwapType(typeSelected)} capitalized />
          {showSettings && (
            <div
              onClick={() => {
                setSetting(!setting)
              }}
              className='flex justify-center items-center bg-white/10 hover:bg-white/20 backdrop-blur-[2px] border border-[#ffffff33] p-4 rounded-full hover:cursor-pointer z-10'
            >
              <img alt='' src='/images/swap/bar.svg' />
            </div>
          )}
        </div>
        {swaps.map((swap) => {
          if (swap.type === preferredSwapType) {
            return <swap.Component key={swap.type} slippage={slippage} multihops={multihops} deadline={deadline} />
          }
          return null
        })}
      </div>

      {setting && showSettings && (
        <Settings
          slippage={slippage}
          setSlippage={setSlippage}
          deadline={deadline}
          setDeadline={setDeadline}
          multihops={multihops}
          setMultihops={setMultihops}
          setSetting={setSetting}
          updateSwapType={updateSwapType}
          swapType={preferredSwapType}
          isSwap
        />
      )}
      <div className='relative top-[-50px] sm:left-[-50px] max-w-[900px] h-[148px] mx-auto z-[-1]'>
        <img className='token1' src='/images/swap/back-token-1.svg' alt='Lynx logo 1' />
      </div>
    </div>
  )
}

export default Index
