import React, { useContext, useState } from 'react'
import GammaPosition from './gammaPosition'
import { FusionsContext } from '../../../../../context/FusionsContext'

const MyGammaPools = () => {
  const [autoLp, setAutoLp] = useState(-1)
  const fusions = useContext(FusionsContext)
  const gammaPositions = fusions.filter((pos) => pos.account.totalLp.gt(0) && pos.isGamma)

  return (
    <div className={`max-h-[600px] overflow-auto w-full rounded-2xl py-4 px-4 lg:px-6 relative border border-[#ffffff33] bg-white/5`}>
      <p className='text-lg lg:text-[22px] leading-[22px] lg:leading-[27px] f-f-fg font-semibold text-white'>Your Automatic LP</p>
      {gammaPositions.length > 0 ? (
        <div className='flex flex-col space-y-4 mt-3'>
          {gammaPositions.map((position, id) => {
            return <GammaPosition autoLp={autoLp} setAutoLp={setAutoLp} id={id} key={id} position={position} />
          })}
        </div>
      ) : (
        <p className='mt-4 text-lg f-f-fg leading-[22px] text-[#DEDBF2] font-light text-center'>You do not have any liquidity positions.</p>
      )}
    </div>
  )
}

export default MyGammaPools
