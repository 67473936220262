import React, { useContext, useState, useMemo } from 'react'
import { BaseAssetsConetext } from '../../../context/BaseAssetsConetext'
import { formatAmount } from '../../../utils/formatNumber'
import SearchTokenPopup from '../SearchTokenPopup'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
import PreSelection from '../../pages/swap/PreSelection'
import { defaultChainId } from '../../../config/constants'

const percentArray = [25, 50, 75, 100]
const CommonAssets = {
  59144: [
    '0x1a51b19ce03dbe0cb44c1528e34a7edd7771e9af',
    '0x4ea77a86d6e70ffe8bb947fc86d68a7f086f198a',
    '0xa219439258ca9da29e9cc4ce5596924745e12b93',
    '0x176211869ca2b568f2a7d4ee941e073a821ee1ff',
  ],
  59140: ['0xf56dc6695cf1f5c364edebc7dc7077ac9b586068'],
}

const TokenInput = ({
  title,
  asset,
  setAsset,
  otherAsset,
  setOtherAsset,
  amount,
  onInputChange = () => {},
  disabled = false,
  isDollar = false,
  isLiquidity = false,
  lite = false,
}) => {
  const [tokenPopup, setTokenPopup] = useState(false)
  const baseAssets = useContext(BaseAssetsConetext)
  const commonAssets = useMemo(() => {
    return baseAssets.filter((asset) => asset.address === 'ETH' || CommonAssets[defaultChainId].includes(asset.address.toLowerCase()))
  }, [baseAssets])
  const { account } = useWeb3Wagmi()

  const display = useMemo(() => {
    if (isLiquidity || title === 'From') {
      return 'justify-between'
    } else if (title === 'To') {
      return 'justify-start'
    }
    return 'justify-between'
  }, [isLiquidity])

  return (
    <>
      <div className={`flex items-center ${display} flex-wrap mb-1`}>
        <div className='flex space-x-2 items-center'>
          {title && <p className='text-white text-sm md:text-base'>{title}</p>}
          {!lite && <PreSelection assets={commonAssets} fromAsset={otherAsset} toAsset={asset} setSelectedAsset={setAsset} />}
        </div>
        {account && (
          <div className={`flex items-center justify-between space-x-2 flex-wrap`}>
            <div className={`flex items-center text-sm md:text-base space-x-3`}>
              {!disabled && (
                <div className='flex items-center space-x-2.5'>
                  {percentArray.map((percent, index) => {
                    return (
                      <div
                        className={`${
                          index === 0 || index === 2 ? 'hidden sm:flex' : 'flex'
                        } items-center justify-center bg-white/5 hover:bg-white/20 border border-[#ffffff33] rounded-md text-dimGray text-xs md:text-sm w-[40px] md:w-[52px] h-[22px] md:h-[28px] cursor-pointer`}
                        onClick={() => {
                          asset && onInputChange(asset?.balance.times(percent).div(100).dp(asset?.decimals).toString(10) || '')
                        }}
                        key={`percent-${index}`}
                      >
                        {index === percentArray.length - 1 ? 'MAX' : percent}
                        {index === percentArray.length - 1 ? '' : '%'}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='p-px w-full rounded-md'>
        <div className='flex rounded-md items-center justify-between'>
          {asset && (
            <div
              onClick={() => {
                !lite && setTokenPopup(true)
              }}
              className={`flex items-center md:min-w-[140px] justify-between space-x-2 p-1 border border-transparent rounded-md ${
                !lite && 'cursor-pointer hover:bg-white/5 hover:border-[#ffffff33]'
              }`}
            >
              <div className='flex items-center space-x-[3.5px] md:space-x-2 '>
                <img className='w-[20px] h-[20px] md:w-[36px] md:h-[36px]' alt='token image' src={asset.logoURI || '/images/tokens/UKNOWN.png'} />
                <p className='font-medium text-base md:text-xl leading-6 text-white'>{asset.symbol}</p>
              </div>
              <img alt='dropdown arrow' className='hidden w-3 md:inline-block' src='/images/swap/dropdown-arrow.png' />
            </div>
          )}

          <div className={`flex flex-col items-end justify-end w-[70%] text-dimGray`}>
            <input
              value={amount}
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  onInputChange('')
                } else {
                  onInputChange(e.target.value)
                }
              }}
              className='bg-transparent w-full !border-0 text-xl md:text-3xl placeholder-white text-white'
              placeholder='0'
              type={'number'}
              disabled={disabled}
              min={0}
              style={{ textAlign: 'right' }}
            />
          </div>
        </div>
        <div className='flex w-full justify-between items-center text-gray-400'>
          <p className={`${account ? 'visible' : 'invisible'} text-xs md:text-sm`}>Balance: {!asset ? '-' : formatAmount(asset.balance)}</p>
          {isDollar && <div className='text-xs md:text-sm'>${formatAmount((asset?.price || 0) * (Number(amount) || 0))}</div>}
        </div>
      </div>
      {tokenPopup && (
        <SearchTokenPopup
          popup={tokenPopup}
          setPopup={setTokenPopup}
          selectedAsset={asset}
          setSelectedAsset={setAsset}
          otherAsset={otherAsset}
          setOtherAsset={setOtherAsset}
          baseAssets={baseAssets}
        />
      )}
    </>
  )
}

export default TokenInput
