// a list of tokens by chain
import { Token } from '@uniswap/sdk-core'
import { ChainId } from './misc'
import { WETH_EXTENDED } from '../../../v3lib/entities/constants'
import { defaultChainId } from '..'

export const toToken = (t) => {
  return new Token(t.chainId, t.address, t.decimals, t.symbol, t.name)
}

const routeAssets = {
  [ChainId.LINEA_MAINNET]: {
    ETH: {
      name: 'Wrapped ETH',
      symbol: 'WETH',
      address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
    },
    USDC: {
      name: 'USDC',
      symbol: 'USDC',
      address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 6,
    },
    BUSD: {
      name: 'BUSD',
      symbol: 'BUSD',
      address: '0x7d43AABC515C356145049227CeE54B608342c0ad',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
    },
    USDT: {
      name: 'USDT',
      symbol: 'USDT',
      address: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 6,
    },
    LYNX: {
      name: 'Lynex Token',
      symbol: 'LYNX',
      address: '0x1a51b19CE03dbE0Cb44C1528E34a7EDD7771E9Af',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
    },
    wstETH: {
      name: 'Wrapped Staked ETH',
      symbol: 'wstETH',
      address: '0xB5beDd42000b71FddE22D3eE8a79Bd49A568fC8F',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
    },
    ankrETH: {
      name: 'Ankr ETH',
      symbol: 'ankrETH',
      address: '0x11d8680c7f8f82f623e840130eb06c33d9f90c89',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
    },
  },
  [ChainId.LINEA_TESTNET]: {
    ETH: {
      name: 'Wrapped ETH',
      symbol: 'WETH',
      address: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
      chainId: ChainId.LINEA_TESTNET,
      decimals: 18,
    },
    USDCm: {
      name: 'USDC',
      symbol: 'USDC',
      address: '0xC0f8eE736cEC8c1a12e9c25272e6f417510165FF',
      chainId: ChainId.LINEA_TESTNET,
      decimals: 8,
    },
    USDC: {
      name: 'USDC Faucet',
      symbol: 'USDC.f',
      address: '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068',
      chainId: ChainId.LINEA_TESTNET,
      decimals: 6,
    },
    BUSD: {
      name: 'BUSD',
      symbol: 'BUSD',
      address: '0x7d43AABC515C356145049227CeE54B608342c0ad',
      chainId: ChainId.LINEA_TESTNET,
      decimals: 18,
    },
    USDT: {
      name: 'USDT',
      symbol: 'USDT',
      address: '0x0DFc6bb7eEE496CD789B60255F7B2982F8069415',
      chainId: ChainId.LINEA_TESTNET,
      decimals: 18,
    },
    LYNX: {
      name: 'Lynex Token',
      symbol: 'LYNX',
      address: '0xCa1B6EB5B97b61813B3444DE45Be5897d8Cd6d9E',
      chainId: ChainId.LINEA_TESTNET,
      decimals: 18,
    },
  },
  [ChainId.BSC_TESTNET]: {
    ETH: {
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      address: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      chainId: ChainId.BSC_TESTNET,
      decimals: 18,
    },
    USDC: {
      name: 'USDC',
      symbol: 'USDC',
      address: '0x910F3d5088cc7C6aA72Cf4Faa2d4A5608E76Bf2B',
      chainId: ChainId.BSC_TESTNET,
      decimals: 8,
    },
    BUSD: {
      name: 'BUSD',
      symbol: 'BUSD',
      address: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
      chainId: ChainId.BSC_TESTNET,
      decimals: 18,
    },
    USDT: {
      name: 'USDT',
      symbol: 'USDT',
      address: '0x2F1502664c6e89723160b39BF4136e58b9ecF3e9',
      chainId: ChainId.BSC_TESTNET,
      decimals: 18,
    },
  },
}

export const USDC = toToken(routeAssets[defaultChainId].USDC)
export const USDT = toToken(routeAssets[defaultChainId].USDT)
export const BUSD = toToken(routeAssets[defaultChainId].BUSD)
export const LYNX = toToken(routeAssets[defaultChainId].LYNX)
export const ankrETH = toToken(routeAssets[defaultChainId].wstETH)
export const wstETH = toToken(routeAssets[defaultChainId].ankrETH)
// export const FRAX = toToken(routeAssets.FRAX)
// export const BNBx = toToken(routeAssets.BNBx)
// export const ankrBNB = toToken(routeAssets.ankrBNB)
// export const stkBNB = toToken(routeAssets.stkBNB)
export const ETH = toToken(routeAssets[defaultChainId].ETH)
// export const USDPlus = toToken(routeAssets['USD+'])
// const THE = toToken(routeAssets.THE)

const WETH_ONLY = Object.fromEntries(Object.entries(WETH_EXTENDED).map(([key, value]) => [key, [value]]))

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WETH_ONLY,
  [ChainId.LINEA_TESTNET]: [...WETH_ONLY[ChainId.LINEA_TESTNET], USDC],
  [ChainId.LINEA_MAINNET]: [...WETH_ONLY[ChainId.LINEA_MAINNET], USDC, USDT, LYNX, wstETH, ankrETH],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], USDC, USDT],
}

export const ADDITIONAL_BASES = {}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES = {}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  ...WETH_ONLY,
  [ChainId.LINEA_TESTNET]: [...WETH_ONLY[ChainId.LINEA_TESTNET], USDC],
  [ChainId.LINEA_MAINNET]: [...WETH_ONLY[ChainId.LINEA_MAINNET], USDC, USDT, LYNX],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], USDC, USDT],
}
export const PINNED_PAIRS = {
  [ChainId.LINEA_TESTNET]: [[USDC, WETH_EXTENDED[ChainId.LINEA_TESTNET]]],
  [ChainId.LINEA_MAINNET]: [...WETH_ONLY[ChainId.LINEA_MAINNET], USDC],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], USDC, USDT],
}
