import React, { useEffect, useState } from 'react'
import useWalletModal from '../../../../hooks/useWalletModal'
import StyledButton from '../../../common/Buttons/styledButton'
import './dist.scss'
import { useWeb3Wagmi } from '../../../../hooks/useWeb3'
import { useAirdropClaimKey } from '../hooks/useAirdropClaimKey'
import { formatAmount, BIG_ZERO, fromWei } from '../../../../utils/formatNumber'
import BigNumber from 'bignumber.js'
import Skeleton from '../../../common/Spinner/skeleton'
import { getAirdropClaimKey } from '../../../../utils/api'

const Distribution = () => {
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const { onClaim, pending, hasClaimed } = useAirdropClaimKey()

  const [loading, setLoading] = useState(true)
  const [amount, setAmount] = useState(BIG_ZERO)
  const [keyTierName, setKeyTierName] = useState('') // For display
  const [keyTierNumeric, setKeyTierNumeric] = useState('') // For transactions
  const [proof, setProof] = useState([])

  const keyTierNames = {
    1: 'Bronze',
    2: 'Silver',
    3: 'Gold',
    4: 'Platinum',
    5: 'Legendary',
  }

  useEffect(() => {
    const fetchAirdropClaimKey = async () => {
      if (!account) {
        setLoading(false)
        return
      }

      const res = await getAirdropClaimKey(account)
      if (res) {
        setAmount(new BigNumber(res.amount))
        setKeyTierName(keyTierNames[res.keyTier]) // Set the display name
        setKeyTierNumeric(res.keyTier) // Preserve the numeric value for the transaction
        setProof(res.proof)
      }
      setLoading(false)
    }

    fetchAirdropClaimKey()
  }, [account])

  const disable = !account || pending || !amount.gt(0) || hasClaimed

  return (
    <div className='dist'>
      <p className='title'>Your Lynex Key & oLYNX eligibility:</p>
      {loading ? (
        <Skeleton />
      ) : amount.gt(0) ? (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className={`row ${hasClaimed && 'line-through'}`}>Key Tier: {keyTierName}</p>
          <p className={`row ${hasClaimed && 'line-through'}`}>{formatAmount(fromWei(amount))} oLYNX tokens</p>
        </div>
      ) : (
        <div className='tokens divide-y divide-[#A9A9A94D]'>
          <p className='row'>No tokens or keys to claim</p>
        </div>
      )}
      {account ? (
        <StyledButton
          disabled={disable}
          content={hasClaimed ? 'ALREADY CLAIMED' : 'CLAIM'}
          className='action-button'
          onClickHandler={() => onClaim(amount, keyTierNumeric, proof)} // Use the numeric keyTier for the transaction
        />
      ) : (
        <StyledButton onClickHandler={openWalletModal} content={'CONNECT WALLET'} className='action-button' />
      )}
    </div>
  )
}

export default Distribution
