/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import VeTHESelect from '../../components/common/VeTHESelect'
import Table from '../../components/pages/rewards'
import { veTHEsContext } from '../../context/veTHEsConetext'
import { useGetFees, useGetVeRewards } from '../../hooks/useRewards'
import { formatAmount } from '../../utils/formatNumber'
import usePrices from '../../hooks/usePrices'
import Skeleton from '../../components/common/Spinner/skeleton'
import Spinner from '../../components/common/Spinner'

const Index = () => {
  const [veTHE, setVeTHE] = useState(null)
  const { veTHEs } = useContext(veTHEsContext)
  const { rewards: veRewards, setInit, loadedId } = useGetVeRewards(veTHE)
  const fees = useGetFees()
  const prices = usePrices()

  const rewards = useMemo(() => {
    return veTHE && veTHE.rebase_amount.gt(0) ? [...veRewards, ...fees, veTHE] : [...veRewards, ...fees]
  }, [veTHE, fees, veRewards])

  useEffect(() => {
    if (veTHE) {
      setVeTHE(veTHEs.find((item) => item.id === veTHE.id))
    }
  }, [veTHEs, veTHE])

  const totalUsd = useMemo(() => {
    let total = [...veRewards, ...fees].reduce((sum, current) => {
      if (current.totalUsd.gt(0)) return sum.plus(current.totalUsd.toNumber())
      else return sum
    }, new BigNumber(0))
    if (veTHE) {
      total = total.plus(veTHE.rebase_amount.times(prices['LYNX']))
    }
    return total
  }, [veRewards, fees, veTHE, prices])

  return (
    <>
      <div className='max-w-[1200px] min-h-[450px] mt-10 mx-auto'>
        <div className='md:flex items-center justify-between w-full my-3 md:space-x-5 lg:space-x-[60px] relative '>
          {/* for desktop */}
          <VeTHESelect className={'lg:w-[320px] w-full'} isSelected={veTHE} setIsSelected={setVeTHE} />
          <div className='flex items-center justify-between my-3 md:my-0 md:justify-start space-x-1 xl:space-x-3'>
            <p className='text-[16px] lg:text-[22px] text-white f-f-fg font-light'>Total Claimable Rewards:</p>
            <p className='text-[22px] lg:text-[27px] font-medium text-white'>${formatAmount(totalUsd)}</p>
          </div>
        </div>
        {veTHEs.length === 0 ? (
          <div className='flex flex-col w-full items-center p-3 mt-10'>
            <div className='text-white text-sm mt-2'>
              <p>No veLYNX positions found.</p>
            </div>
          </div>
        ) : loadedId != veTHE?.id ? (
          <div className='flex flex-col w-full items-center p-3 mt-10'>
            <Spinner />
            <div className='text-white text-sm mt-2'>
              <p>Loading Rewards..</p>
            </div>
          </div>
        ) : (
          <Table rewards={rewards} veTHE={veTHE} totalUsd={totalUsd} veRewards={veRewards} refreshRewards={setInit} />
        )}
      </div>
    </>
  )
}

export default Index
