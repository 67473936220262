import React, { useContext, useMemo, useState } from 'react'
import AnalytcisHeader from '../../components/pages/analytics/analyticsHeader'
import { useAnalyticsVersion } from '../../hooks/useGeneral'
import { useAllPairData } from '../../context/PairData'
import PairsTable from '../../components/pages/analytics/pairsTable'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'

const AnalyticsPairs = () => {
  const version = useAnalyticsVersion()
  const pairs = useAllPairData(version)
  const assets = useContext(BaseAssetsConetext)
  const [searchText, setSearchText] = useState('')
  const pairsWithImg = useMemo(() => {
    if (Object.keys(pairs).length > 0)
      return Object.values(pairs).map((pair) => {
        const found0 = assets.find((ele) => ele.address.toLowerCase() === pair.token0.id)
        const found1 = assets.find((ele) => ele.address.toLowerCase() === pair.token1.id)
        return {
          ...pair,
          token0: {
            ...pair.token0,
            name: found0 ? found0.name : pair.token0.name,
            symbol: found0 ? found0.symbol : pair.token0.symbol,
            logoURI: found0 ? found0.logoURI : '/images/tokens/UKNOWN.png',
          },
          token1: {
            ...pair.token1,
            name: found1 ? found1.name : pair.token1.name,
            symbol: found1 ? found1.symbol : pair.token1.symbol,
            logoURI: found1 ? found1.logoURI : '/images/tokens/UKNOWN.png',
          },
        }
      })
    return []
  }, [pairs, assets])

  return (
    <>
      <div className='w-full pt-[160px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
        <div className='max-w-[1104px] mx-auto w-full'>
          <AnalytcisHeader />
          <div className='flex justify-between items-center mt-8'>
            <p className='text-lg text-base leading-8 text-white f-f-fg font-medium'>All Pairs</p>
            <div className='border border-themeOrange w-75% max-w-md md:max-w-xs rounded flex items-center relative'>
              <img className='pointer-events-none absolute left-3 w-5 h-5' alt='' src='/images/svgs/search.svg' />
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search Pairs'
                className='bg-white/80 placeholder-[#757384] h-10 md:h-8 w-full text-themeBlack text-sm pl-10 pr-4 rounded'
              />
            </div>
          </div>

          <PairsTable pairsData={pairsWithImg} searchText={searchText} />
        </div>
      </div>
    </>
  )
}

export default AnalyticsPairs
